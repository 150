import React from 'react'
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import map_3d from "./images/services/map_3d.png";


import about_header_new_1 from './images/about/about_header_new_1.webp'
import about_header_new_2 from './images/about/about_header_new_2.webp'
import about_header_new_3 from './images/about/about_header_new_3.webp'

import about_details_1 from './images/about/about_details_1.jpg'
import about_details_2 from './images/about/about_details_2.jpg'
import about_details_3 from './images/about/about_details_3.jpg'
import { Link } from 'react-router-dom';

export default function Sales() {
  return <>
  <section>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={about_header_new_1} alt="" />
            </div>
            <div class="carousel-item">
<img src={about_header_new_2} alt="" />        
            </div>
            <div class="carousel-item">
              <img src={about_header_new_3} alt="" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span class="carousel-control-next-icon" ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
  </section>

{/* <section className='car-dot-img'>
  <div className="container">
    <div className="row mt-3">
      <div className="col-lg-6 col-sm-6">
        <h3 className='heading-font-style text-center' style={{color:'rgb(0,35,102)'}}>PASSENGER VEHICLE</h3>

        <div>
          <ul className='text-center'>
            <li><Link className='text-decoration-none' to="/nexon"><b>Tata Nexon</b> </Link></li>
            <li><Link className='text-decoration-none' to="/tiago"><b>Tata Tiago</b> </Link></li>
            <li><Link className='text-decoration-none' to="/tigor"><b> Tata Tigor</b></Link></li>
            <li><Link className='text-decoration-none' to="/harrier"><b>Tata Harrier</b> </Link></li>
            <li><Link className='text-decoration-none' to="/altroz"><b>Tata Altroz</b> </Link></li>
            <li><Link className='text-decoration-none' to="/punch"><b>Tata Punch</b> </Link></li>
            <li><Link className='text-decoration-none' to="/safari"><b> Tata Safari</b></Link></li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6 col-sm-6">
      <h3 className='heading-font-style text-center' style={{color:'#00adba'}}>ELECTRIC VEHICLE</h3>
<ul  className='text-center'>
  <li><Link className='text-decoration-none' to="/ev/tiago"><b>Tiago EV</b> </Link></li>
  <li><Link className='text-decoration-none' to="/ev/tigor"><b> Tigor EV</b></Link></li>
  <li><Link className='text-decoration-none' to="/ev/nexon"><b>Nexon EV</b> </Link></li>
</ul>
      </div>
    </div>
  </div>
</section> */}

<section style={{backgroundColor:'#efefef'}} className="p-3">
  <div className="container">
    {/* <div className="row p-3 ">
      <div className="col-lg-6 col-sm-6">
        <div className="row" style={{backgroundColor:'#fff'}}>
          <div className="col-lg-7 col-sm-6 box">
          <span class="arrow-right"></span>
            <img className='gridImg' src={about_details_1} alt="" />
          </div>
<div className="col-lg-5 col-sm-6" >
  <h5 className='mt-1'><b>PERFORMANCE</b>  </h5>
  <p>New is about ensuring your free spirit is unshaken with ESP and multi-drive modes. This journey of creating and evoking joy, will be forever. Because the spirit that loves to drive, is exactly that

</p>
</div>
        </div>
      </div>
      <div className="col-lg-6 col-sm-6 p-3">
          <img className='gridImg' src={about_details_2} alt=""  />
          <div className="grid_content design-content">
          <h4 className='text-light'><b>DESIGN</b></h4>
          <p className='text-light'>It’s true. Design shouldn’t just look great, it should do great things. But while being new, design needs to evoke a love that’s eternal. Design shouldn’t just be new. It needs to Drive New Forever.

</p></div>
      </div>

    </div> */}

    <div className="row" >
    <div className="col-lg-6 col-sm-6">
        <div className="row" style={{backgroundColor:'#fff'}}>
          <div className="col-lg-7 col-sm-6 box">
          <span class="arrow-right"></span>
            <img className='gridImg' src='https://cars.tatamotors.com/images/altroz/ira-tech.jpg' alt="" />
          </div>
<div className="col-lg-5 col-sm-6 p-5" >
  <Link to="/nexon" className='text-decoration-none'><h4 className='mt-1' ><b>Passenger Vehicle</b>  </h4></Link>
 
</div>
        </div>
      </div>
      <div className="col-lg-6 col-sm-6 ">
        <div className="row" style={{backgroundColor:'#fff'}}>
          <div className="col-lg-7 col-sm-6">
        <img className='gridImg' src='https://ev.tatamotors.com/wp-content/themes/tata-ev/images/evolve/evolve-technology-desk.png' alt=""  />
        {/* <div className="grid_content design-content">
        
        </div> */}
        </div>

        <div className="col-lg-5 col-sm-6 p-5">

        <Link to="/ev/nexon" className='text-decoration-none'><h4  style={{color:'#00adba'}}><b>Electric Vehicle</b></h4></Link>
        </div>
        </div>
      </div>
    </div>

    {/* performance */}
    <div className="row p-5 ">
      <div className="col-lg-6 col-sm-6">
        <div className="row" style={{backgroundColor:'#fff'}}>
          <div className="col-lg-6 col-sm-6 box">
          <span class="arrow-right"></span>
            <img className='gridImg' src={about_details_1} alt="" />
          </div>
<div className="col-lg-5 col-sm-6" >
  <h5 className='mt-1'><b>PERFORMANCE</b>  </h5>
  <p>New is about ensuring your free spirit is unshaken with ESP and multi-drive modes. This journey of creating and evoking joy, will be forever. Because the spirit that loves to drive, is exactly that

</p>
</div>
        </div>
      </div>
      <div className="col-lg-6 col-sm-6 p-3">
          <img className='gridImg' src={about_details_2} alt=""  />
          <div className="grid_content design-content">
          <h4 className='text-light'><b>DESIGN</b></h4>
          <p className='text-light'>It’s true. Design shouldn’t just look great, it should do great things. But while being new, design needs to evoke a love that’s eternal. Design shouldn’t just be new. It needs to Drive New Forever.

</p></div>
      </div>

    </div>

    <div className="row" style={{backgroundColor:'#fff'}}>
<div className="col-lg-6 col-sm-3" >
<span class="arrow-right"></span>

  <img src={about_details_3} alt="" />
</div>
<div className="col-lg-6 col-sm-6 p-5">
<h4><b>SAFETY</b></h4>
<p>With architecture itself that’s built for safety, we made your oldest need, new. Dangers become new too, Safety can’t just be new once. It has to be New Forever. We Drive New Forever.

</p>
</div>
    </div>

  </div>
</section>

{/* our brands */}
<div className='car-dot-img'>

<section>
<div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-dark text-center'><b>CARS</b> </h1>
    <hr className='bg-white' />

    <br />
     
   
<div className="row text-center">
    <TabList>
      <Tab>     
            
      <img src='https://cars.tatamotors.com/images/tigor-thumb-new-02.jpg' alt=""height="300px" width="300px" />
      
      <Link to="/tigor"><button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>Tata Tigor</button></Link>
</Tab>
      <Tab>     

         <img src='https://cars.tatamotors.com/images/tiago-thumb-homepg-new.jpg' alt="" height="300px" width="300px" />
         <Link to="/tiago"><button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>Tata Tiago</button></Link>

</Tab>
     
      <Tab>     

         <img src='https://cars.tatamotors.com/images/dark/home-altroz-thumbnail.jpg' alt=""height="300px" width="300px" />
         <Link to="/altroz"><button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>Tata Altroz</button></Link>

</Tab>
     
    </TabList>
    </div>
  
  </div>

</section>

<section>
<div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-dark text-center'><b>SUVs</b> </h1>
    {/* <hr className='bg-white' /> */}
    <br />
  
<div className="row text-center">
    <TabList>
      <Tab>     
            
      <img src='https://cars.tatamotors.com/images/punch/punch-suv-home-thumbnail.png' alt=""height="220px" width="220px" />
      
      <Link to="/punch"><button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>TATA PUNCH</button></Link>
</Tab>
      <Tab>     

         <img src='https://cars.tatamotors.com/images/dark/home-nexon-thumbnail.jpg' alt="" height="220px" width="220px" />
        <Link to="/nexon"> <button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>TATA NEXON </button></Link>

</Tab>
     
      <Tab>     

         <img src='https://cars.tatamotors.com/images/dark/home-harrier-thumbnail.jpg' alt=""height="220px" width="220px" />
        <Link to="/harrier"> <button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>TATA HARRIER </button></Link>

</Tab>
      <Tab>     

         <img src='https://cars.tatamotors.com/images/safari/safari-thumb-banner.jpg' alt=""height="220px" width="220px" />
         <Link to="/safari"><button type="button" class="btn   w-100" style={{backgroundColor:'#0a0a5f',color:'#fff'}}>TATA SAFARI</button></Link>

</Tab>
     
    </TabList>
    </div>




 
  
  </div>

</section>

  {/* area we find us */}
  <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-7120e8b elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="7120e8b"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c89f18f"
                        data-id="c89f18f"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-655abbb elementor-widget elementor-widget-karde_findus_area"
                              data-id="655abbb"
                              data-element_type="widget"
                              data-widget_type="karde_findus_area.default"
                            >
                              <div className="elementor-widget-container">
                                <section className="find_us_area p_100">
                                  <div className="container">
                                    <div className="row find_us_inner">
                                      <div className="col-lg-6">
                                        <div className="find_us_text">
                                          <h2>
                                            Areas You Can <span>Find Us</span>
                                          </h2>
                                          <p>
                                            Mutha Motors are available
                                            <br /> in below regions also
                                          </p>
                                          <div className="list_finding">
                                            <ul className="nav flex-column">
                                              <li className="new_york_city">
                                                <a href="javascript:void(0)">
                                                  Aurangabad
                                                </a>
                                              </li>

                                              <li className="san_diego">
                                                <a href="javascript:void(0)">
                                                  Buldhana
                                                </a>
                                              </li>

                                              <li className="los_angeles">
                                                <a href="javascript:void(0)">
                                                  Jalna
                                                </a>
                                              </li>

                                              <li className="boston">
                                                <a href="javascript:void(0)">
                                                  Khamgaon
                                                </a>
                                              </li>
                                              <li className="chicago">
                                                <a href="javascript:void(0)">
                                                  Malkapur
                                                </a>
                                              </li>
                                            </ul>
                                            {/* <ul className="nav flex-column">
                                              <li className="chicago">
                                                <a href="javascript:void(0)">
                                                  Chicago
                                                </a>
                                              </li>

                                              <li className="san_francisco">
                                                <a href="javascript:void(0)">
                                                  San Francisco
                                                </a>
                                              </li>

                                              <li className="baltimore">
                                                <a href="javascript:void(0)">
                                                  Baltimore
                                                </a>
                                              </li>

                                              <li className="houston">
                                                <a href="javascript:void(0)">
                                                  Houston
                                                </a>
                                              </li>

                                              <li className="phoenix">
                                                <a href="javascript:void(0)">
                                                  Phoenix
                                                </a>
                                              </li>

                                              <li className="philadelphia">
                                                <a href="javascript:void(0)">
                                                  Philadelphia
                                                </a>
                                              </li>
                                            </ul> */}
                                            {/* <ul className="nav flex-column">
                                              <li className="detroit">
                                                <a href="javascript:void(0)">
                                                  Detroit
                                                </a>
                                              </li>

                                              <li className="miami">
                                                <a href="javascript:void(0)">
                                                  Miami
                                                </a>
                                              </li>

                                              <li className="seattle">
                                                <a href="javascript:void(0)">
                                                  Seattle
                                                </a>
                                              </li>
                                            </ul> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="find_us_img">
                                          <img
                                            className="img-fluid wow animated fadeIn"
                                            data-wow-delay="0.2s"
                                            // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/find-map-2.png"
                                            // src={map1}
                                            src={map_3d}
                                            // src={safari_slider_car}
                                            alt="Alt"
                                          />
                                          <div className="marker_icon">
                                            <ul className="nav elementor-repeater-item-c59fbd0">
                                              <li
                                                style={{
                                                  top: "35%",
                                                  left: "35%",
                                                }}
                                                className="new_york_city"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir/19.8748734,75.3761947/R8XW%2B9G8+MUTHA+MOTORS,+Gopi+Nath+Pura,+Satara+Deolai+Parisar,+Aurangabad,+Maharashtra+431005/@19.8620045,75.3434205,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bdba3974a4356c3:0xfcfba4eb70fc7cb2!2m2!1d75.3463192!2d19.8484111"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Chhatrapati Sambhajinagar"
                                                  data-original-title="Chhatrapati Sambhajinagar"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Chhatrapati Sambhajinagar"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "31%",
                                                  left: "40%",
                                                }}
                                                className="atlanta"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+buldhana+Khetan+Square+Shegaon+Buldhana+-+443001.+Landmark.+Opposite+Shegaon+Urban+Bank./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bda1e077e700edb:0x14e04802a6c02141?sa=X&ved=2ahUKEwjBsoDnytP9AhUwIbcAHTa3DHEQ9Rd6BAhREAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Buldhana"
                                                  data-original-title="Buldhana"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Buldhana"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "22%",
                                                  left: "40%",
                                                }}
                                                className="san_diego"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Khamgaon"
                                                  data-original-title="Khamgaon"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Khamgaon"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "38%",
                                                  // right: "30%",
                                                  // bottom: "30%",
                                                  left: "40%",
                                                }}
                                                className="los_angeles"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir/19.874865,75.377049/TATA+MOTORS+LIMITED+MUTHA+MOTORS+JALNA,+Aditya+nagar,+Chandanzira,+Jalna,+Maharashtra+431203/@19.8870144,75.1443048,9.5z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bda51dd332f304b:0xa7ae2289377481a1!2m2!1d75.8563004!2d19.8589784"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Jalna"
                                                  data-original-title="Jalna"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Jalna"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "25%",
                                                  left: "55%",
                                                }}
                                                className="boston"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Malkapur"
                                                  data-original-title="Malkapur"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Malkapur"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              {/* <li
                                                style={{
                                                  top: "49%",
                                                  left: "33%",
                                                }}
                                                className="st._louis"
                                              >
                                                <a
                                                  href="#"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="St. Louis"
                                                  data-original-title="St. Louis"
                                                >
                                                  /
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="St. Louis"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "43%",
                                                  left: "49%",
                                                }}
                                                className="chicago"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Chicago"
                                                  data-original-title="Chicago"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Chicago"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "14%",
                                                  left: "54%",
                                                }}
                                                className="san_francisco"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="San Francisco"
                                                  data-original-title="San Francisco"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="San Francisco"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "60%",
                                                  left: "60%",
                                                }}
                                                className="baltimore"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Baltimore"
                                                  data-original-title="Baltimore"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Baltimore"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "44%",
                                                  left: "70%",
                                                }}
                                                className="houston"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Houston"
                                                  data-original-title="Houston"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Houston"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "auto",
                                                  bottom: "3%",
                                                  left: "55%",
                                                }}
                                                className="phoenix"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Phoenix"
                                                  data-original-title="Phoenix"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Phoenix"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "48%",
                                                  right: "23%",
                                                  left: "auto",
                                                }}
                                                className="philadelphia"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Philadelphia"
                                                  data-original-title="Philadelphia"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Philadelphia"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "auto",
                                                  right: "13%",
                                                  bottom: "24%",
                                                  left: "auto",
                                                }}
                                                className="detroit"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Detroit"
                                                  data-original-title="Detroit"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Detroit"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "39%",
                                                  right: "4%",
                                                  left: "auto",
                                                }}
                                                className="miami"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Miami"
                                                  data-original-title="Miami"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Miami"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "13%",
                                                  right: "4%",
                                                  left: "auto",
                                                }}
                                                className="seattle"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Seattle"
                                                  data-original-title="Seattle"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Seattle"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

</div>
  </>
}
