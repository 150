import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';
import whatsup from '../pages/images/social_media_images/whatsup'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function WhatsAppPopup(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <>
  <div >
      <Button variant="transparent" onClick={handleClickOpen}>
       <img src={whatsup} alt="" height={70} width={70} className="whatsapp-popup "/>
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" style={{backgroundColor:'#6fbf73',borderRadius:'10px'}} onClose={handleClose}>
            <b>
          Mutha Motors
            </b>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{color:'#000', borderRadius:'20px'}}>
           Hi just saw your website, 
           <br />
           I wanted to know more about Your services
          </Typography>
         
        
        </DialogContent>
        <DialogActions>
            {/* <a href='https://api.whatsapp.com/send/?phone=919158111116&text&type=phone_number&app_absent=0'> */}
            <a href='#'>
            <Button autoFocus onClick={handleClose} style={{backgroundColor:'#6fbf73', color:'#fff', borderRadius:'20px'}}>
          <WhatsAppIcon/> 
          <span>  Open Chat</span>
         
           </Button>
            </a>
          
        </DialogActions>
      </BootstrapDialog>
    </div>
  </>
}
