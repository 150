  import React from 'react'
  import darkRed_header_1 from './images/darkRed_images/darkRed_header_1'
  import abouts_banner_1 from './images/about/abouts_banner_1'
  import tata_nexon from './images/tata_nexon'
  import altroz_features_2 from './images/altroz_images/altroz_features_2'
  import { Link } from 'react-router-dom'

  import transparentImg from './images/transparent.png'
  import muthaLogo from './images/mutha.png'
  import white_bg from './images/white_bg.png'

  export default function Home() {
    return <>
    
    <section className="page-wrapper"
    //  style={{backgroundColor:'rgb(0,0,0)'}}
    >

  
  

  <div className="car-dot-img"  >
  <section
      className="company-information-section padding-top-bottom bg-setting"
      data-parallax="scroll"
      // data-image-src="images/information-banner-1.jpg"
      id="company-information-section"
    >
      <div className="container  margin-style">
        <div className="row ">
          <div className="col-lg-12 col-md-12">
            <div className="company-information-inner">
              
              <div className="row  centered-img">
                <div className="col-lg-12 col-md-12 col-sm-12" style={{height:"350px", width:"350px"}}>
                <img src={muthaLogo} alt="" />

                </div>
              </div>
<br />
<h5 className='text-danger text-jeader-manage text-center font-style'>We Believe in Humanity</h5>
              <h1
                className="mt-5 text-color-dark fontface-one padding-bottom-16 wow fadeInLeft text-center"
                data-wow-delay="350ms"
              >
                MUTHA <span className="font-weight-700 "> MOTORS</span>
              </h1>
              <p
                className="text-color-dark text-center fontface-two paragraph-23 padding-bottom-45 wow fadeInLeft"
                data-wow-delay="400ms"
              >
              Dedicated to giving our <br /> customers an at ease and pleasant car-buying experience.
              

              </p>
             
            </div>
          </div>
         
        </div>
      </div>
    </section>
    </div>

    
  </section>


  {/* <section>
  <div className='car-dot-img'>
  <div className="container">

  <div className="row">
    <div className="">
  <img src={muthaLogo} alt="" style={{height:'300px' , width:'300px'}} className='margin-logo'/>

  <h1 className="text-jeader-manage  text-light centered mt-5" 
  >
    <strong className='text-dark'> MUTHA MOTORS</strong>
      </h1>
  <h5 className='text-danger text-jeader-manage text-center font-style' >We Believe in Humanity</h5>
  <p className='text-dark text-small text-center'>
                Dedicated to giving our <br /> customers an at ease and pleasant car-buying experience.
                  </p>
                  </div>
  </div>
  </div>
  </div>
  </section> */}


    <section className="page-wrapper"
    //  style={{backgroundColor:'rgb(0,0,0)'}}
    >

  <>
  <div className="" style={{ backgroundColor: "#000000", color: "#ffffff" }}>
  <section  className="about-company-section background-dark padding-top-bottom" id="about-company-section">
  <div className="container">
      <div className="about-company-slider-section">
  <div className="about-company-slider swiper-container">
      <div className="swiper-wrapper">
          
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button className='swiper-pagination-bullet-active' type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
                  <button className='swiper-pagination-bullet ' type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button className='swiper-pagination-bullet' type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button className='swiper-pagination-bullet' type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                  <div className="about-company-slide">
                  <div className="row">
                    <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2">
                      <div className="about-slider-image-section clearfix position-relative">
                        <div className="about-slider-images-section-inner">
                          <img
                            src="https://ev.tatamotors.com/Harrierev/images/harrier-desktop.jpg"
                            alt="about-slider-image"
                            data-slide="animated"
                            data-animate="zoomIn"
                          />
                        </div>
                        <div
                          className="about-img-gradient-box gradient-bg-green-blue"
                          data-slide="animated"
                          data-animate="zoomIn"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 vertical-align-about-caption">
                      <div
                        className="about-slider-img-detail"
                        data-slide="animated"
                        data-animate="fadeInRight"
                      >
                        {/* <p className="text-color-secondary paragraph-14 fontface-two padding-bottom-25">
                          Information about Mutha Motors
                        </p> */}
                        <h2 className="fontface-one text-color-white padding-bottom-35" style={{gap:'2px'}}>
                          Why  <span></span>
                          <span className="font-weight-700">We’re Different</span>
                        </h2>
                        <p className="text-color-white paragraph-15 padding-bottom-35 text-color-white">
                          Mutha Motors is dedicated to giving our customers with
                          an at ease and pleasant car-buying experience. Our
                          customer-centric approach, alongside our loyalty to
                          Customer Satisfaction, Transparency, and Honesty,
                          differentiates us from other dealerships in the
                          vicinity.
                        </p>
                        {/* <p className="text-color-white paragraph-15 padding-bottom-45 text-color-white">
                          Our Dealership Network has a strong presence in places
                          such as Jalna, Aurangabad, Buldhana, and Khamgaon in the
                          state of Maharashtra.
                        </p> */}
                        {/* <Link
                          to="/about"
                          className="button-setting background-primary text-color-white button-setting-secondary"
                        >
                          Learn More
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                  
                  </div>
                  <div class="carousel-item">
                  <div className="about-company-slide">
                  <div className="row">
                    <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2">
                      <div className="about-slider-image-section clearfix position-relative">
                        <div className="about-slider-images-section-inner">
                          <img
                            src="https://cars.tatamotors.com/images/safari/colors/royale-blue-thumbnail.jpg"
                            alt="about-slider-image"
                            data-slide="animated"
                            data-animate="zoomIn"
                          />
                        </div>
                        <div
                          className="about-img-gradient-box gradient-bg-pink-purple"
                          data-slide="animated"
                          data-animate="zoomIn"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 vertical-align-about-caption">
                      <div
                        className="about-slider-img-detail"
                        data-slide="animated"
                        data-animate="fadeInRight"
                      >
                        {/* <p className="text-color-secondary paragraph-14 fontface-two padding-bottom-25">
                          Information about Mutha Motors
                        </p> */}
                        <h2 className="fontface-one text-color-white padding-bottom-35">
                          Why <span></span>
                          <span className="font-weight-700">We’re Special</span>
                        </h2>
                        {/* <p className="text-color-white paragraph-15 padding-bottom-35 text-color-white">
                          Mutha Motors understands that owning a car entails not
                          only purchasing one, but also maintaining it correctly
                          it. That is why we provide superior vehicle servicing to
                          ensure that your vehicle functions smoothly and
                          efficiently at all times. Our highly qualified
                          technicians use modern technology and tools to identify
                          and fix any problems with your vehicle.
                        </p> */}
                        <p className="text-color-white paragraph-15 padding-bottom-45 text-color-white">
                          Mutha Motors takes pleasure in developing lasting
                          relationships with our customers. Our commitment to
                          delivering outstanding services has earned us a
                          reputation as one of the region's most reliable and
                          trustworthy dealerships.
                        </p>
                        {/* <Link
                          to="/about"
                          className="button-setting background-secondary text-color-white button-setting-primary"
                        >
                          Learn More
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                  <div class="carousel-item">
                  <div className="about-company-slide">
                  <div className="row">
                    <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2">
                      <div className="about-slider-image-section clearfix position-relative">
                        <div className="about-slider-images-section-inner">
                          <img
                            src="https://cars.tatamotors.com/images/punch/punch-suv-home.jpg"
                            alt="about-slider-image"
                            data-slide="animated"
                            data-animate="zoomIn"
                          />
                        </div>
                        <div
                          className="about-img-gradient-box gradient-bg-green-blue"
                          data-slide="animated"
                          data-animate="zoomIn"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 vertical-align-about-caption">
                      <div
                        className="about-slider-img-detail"
                        data-slide="animated"
                        data-animate="fadeInRight"
                      >
                        {/* <p className="text-color-secondary paragraph-14 fontface-two padding-bottom-25">
                          Information about Mutha Motors
                        </p> */}
                        <h2 className="fontface-one text-color-white padding-bottom-35">
                          Why <span></span>
                          <span className="font-weight-700">We’re Ordinary</span>
                        </h2>
                        <p className="text-color-white paragraph-15 padding-bottom-35 text-color-white">
                          We are a customer-centric organisation dedicated to
                          providing exceptional service and support to our
                          customers. Transparency, honesty, and integrity are
                          basic values that pervade everything we do. .
                        </p>
                        {/* <p className="text-color-white paragraph-15 padding-bottom-45 text-color-white">
                          We feel that being right away about our prices,
                          services, and processes helps to establish assurance
                          with our clients. We think that by prioritising the
                          needs of our customers, we can make a significant and
                          long-lasting impact in the communities we serve
                        </p> */}
                        {/* <Link
                          to="/about"
                          className="button-setting background-primary text-color-white button-setting-secondary"
                        >
                          Learn More
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
                  </div>

  <div className="carousel-item">
  <div className="about-company-slide">
                  <div className="row">
                    <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2">
                      <div className="about-slider-image-section clearfix position-relative">
                        <div className="about-slider-images-section-inner">
                          <img
                            src="https://cars.tatamotors.com/images/dark/nexon-home.jpg"
                            alt="about-slider-image"
                            data-slide="animated"
                            data-animate="zoomIn"
                          />
                        </div>
                        <div
                          className="about-img-gradient-box gradient-bg-pink-purple"
                          data-slide="animated"
                          data-animate="zoomIn"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 vertical-align-about-caption">
                      <div
                        className="about-slider-img-detail"
                        data-slide="animated"
                        data-animate="fadeInRight"
                      >
                        {/* <p className="text-color-secondary paragraph-14 fontface-two padding-bottom-25">
                          Information about Mutha Motors
                        </p> */}
                        <h2 className="fontface-one text-color-white padding-bottom-35">
                          Why <span></span>
                          <span className="font-weight-700">We’re Different</span>
                        </h2>
                        <p className="text-color-white paragraph-15 padding-bottom-35 text-color-white">
                          Our competent and knowledgeable Customer Advisors are
                          always available to guide you in selecting the ideal
                          TATA Motors product that suits your budget and
                          requirements.
                        </p>
                        <p className="text-color-white paragraph-15 padding-bottom-45 text-color-white">
                          We invite you to visit to any of our Maharashtra
                          locations and experience the Mutha Motors prestige for
                          yourself.
                        </p>
                        {/* <Link
                          to="/about"
                          className="button-setting background-secondary text-color-white button-setting-primary"
                        >
                          Learn More
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
  </div>

                </div>
              
              </div>
      </div>
  </div>
      </div>
  </div>
              </section>
  </div>
  </>
  

  <div className="" style={{backgroundColor:"rgb(0,35,102)"}}>
  <section
      className="company-information-section padding-top-bottom bg-setting"
      data-parallax="scroll"
      data-image-src="images/information-banner-1.jpg"
      id="company-information-section"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-8">
            <div className="company-information-inner">
              <p
                className="paragraph-18 fontface-two font-weight-300 text-color-white padding-bottom-25 wow fadeInLeft"
                data-wow-delay="300ms"
              >
              Mutha Motors
              </p>
              <h2
                className="text-color-white fontface-one padding-bottom-35 wow fadeInLeft"
                data-wow-delay="350ms"
              >
                Cultivate <span className="font-weight-700 ">&amp; Inspired</span>
              </h2>
              <p
                className="text-color-white fontface-two paragraph-23 padding-bottom-45 wow fadeInLeft"
                data-wow-delay="400ms"
              >
                We think that by prioritising the needs of our customers, we can make a significant and long-lasting impact in the communities we serve.

              </p>
              <Link
                to="/service"
                className="button-setting background-white button-setting-primary text-color-dark wow fadeInLeft"
                data-wow-delay="400ms"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="col-lg-5 col-md-4">
              <img src={tata_nexon} alt="" />
          </div>
        </div>
      </div>
    </section>
    </div>



  <>
    {/* Company Stats Information Start */}
    <section
      className="company-stats-section background-grey padding-top-bottom car-dot-img"
      id="stats-section"
      style={{color:'#0a0a5f'}}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 order-md-12 order-sm-12 order-12 order-lg-1">
            <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
                <div
                  className="stats-block-inner text-center wow fadeInUp"
                  data-wow-delay="350ms"
                >
                  <i className="ti-bar-chart text-blue" />
                  <div className="stats-number-inner text-blue fontface-one font-weight-700 padding-top-20">
                    <span>11000</span>+
                  </div>
                  <p className="paragraph-16 font-weight-400 fontface-two text-blue padding-top-5">
                    Completed Consultations
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div
                  className="stats-block-inner text-center wow fadeInUp"
                  data-wow-delay="300ms"
                  
                >
                  <i className="ti-hand-point-right text-blue"  />
                  <div className="stats-number-inner text-blue fontface-one font-weight-700 padding-top-20">
                    <span>1000</span>+
                  </div>
                  <p className="paragraph-16 font-weight-400 fontface-two text-blue padding-top-5">
                    Satisied Sales Customers
                  </p>
                </div>
              </div>
             
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div
                  className="stats-block-inner text-center wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <i className="ti-blackboard text-blue" />
                  <div className="stats-number-inner text-blue fontface-one font-weight-700 padding-top-20">
                    <span>9000</span>+
                  </div>
                  <p className="paragraph-16 font-weight-400 fontface-two text-blue padding-top-5">
                   Satisfied Service Customers
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 order-md-1 order-sm-1 order-1 order-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="stats-heading text-right padding-bottom-30 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <h2 className="text-blue fontface-one text-design">
                    Important facts
                  </h2>
                  <h2 className="text-blue fontface-one text-design">
                    about{" "}
                    <span className="text-color-secondary">Mutha Motors</span>
                  </h2>
                </div>
                <div
                  className="stats-heading-detail text-right wow fadeInUp"
                  data-wow-delay="350ms"
                >
                  <p className="paragraph-14 text-blue fontface-two">
                    In a nutshell Mutha Motors is more than a dealership; we are a
                    customer-centric organisation dedicated to providing
                    exceptional service and support to our customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Company Stats Information End */}
  </>

  <>
    {/* Company Portfolio Start */}
    {/* <section
      className="company-portfolio-section padding-top-bottom"
      id="company-portfolio-section"
    >
      <div className="container">
        <div className="section-heading">
          <div className="row">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="300ms">
              <p className="fontface-two text-color-secondary paragraph-14 padding-bottom-25 ">
                Our Creative Brands
              </p>
              <h1 className="fontface-one text-color-white padding-bottom-35 text-design">
                Amazing
                <span className="text-color-white font-weight-700 text-design">Brands</span>
              </h1>
            </div>
            <div className="col-md-12">
              <div
                id="js-filters-mosaic"
                className="cbp-l-filters-button wow fadeInUp"
                data-wow-delay="350ms"
              >
                <div
                  data-filter="*"
                  className="cbp-filter-item-active cbp-filter-item"
                >
                  All
                </div>
                <div className="cbp-divider">|</div>
                <div
                  data-filter=".print"
                  className="cbp-filter-item text-color-white"
                >
                  Nexon
                </div>
                <div className="cbp-divider">|</div>
                <div data-filter=".web-design" className="cbp-filter-item">
                  Altroz
                </div>
                <div className="cbp-divider">|</div>
                <div data-filter=".motion" className="cbp-filter-item">
                  Safari
                </div>
                <div className="cbp-divider">|</div>
                <div data-filter=".graphic" className="cbp-filter-item">
                  Tiago
                </div>
              </div>
              <div id="js-grid-mosaic" className="cbp cbp-l-grid-mosaic">
                <div className="cbp-item graphic">
                  <a
                    href="https://cars.tatamotors.com/images/nexon/color/Armour-green-Thumbnail_01-new.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/nexon/color/Armour-green-Thumbnail_01-new.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Nexon
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text">
                  </p>
                </div>
                <div className="cbp-item motion">
                  <a
                    href="https://cars.tatamotors.com/images/altroz/ira-tech.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/altroz/ira-tech.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Altroz
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text"></p>
                </div>
                <div className="cbp-item print web-design">
                  <a
                    href="https://cars.tatamotors.com/images/nexon/color/Lava-red-Thumbnail_01-new.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/nexon/color/Lava-red-Thumbnail_01-new.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Tata Nexon
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text">
                  </p>
                </div>
                <div className="cbp-item web-design">
                  <a
                    href="https://cars.tatamotors.com/images/punch/colors/altomic-orange-thumb.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/punch/colors/altomic-orange-thumb.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Tata Punch
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text">
                  </p>
                </div>
                <div className="cbp-item print">
                  <a
                    href="https://cars.tatamotors.com/images/nexon/color/atlas-black-m-color-thumb.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/nexon/color/atlas-black-m-color-thumb.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Tata Nexon
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text">
                  </p>
                </div>
                <div className="cbp-item graphic">
                  <a
                    href="https://cars.tatamotors.com/images/harrier/color/harrier-tropical-mist-thumb.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/harrier/color/harrier-tropical-mist-thumb.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Tata Harrier
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text">
                  </p>
                </div>
                <div className="cbp-item motion">
                  <a
                    href="https://cars.tatamotors.com/images/tiago/color/tiago-plum-wine-thumb.jpg"
                    className="cbp-caption cbp-whitebox"
                    data-title=""
                  >
                    <div className="cbp-caption-defaultWrap">
                      <img
                        src="https://cars.tatamotors.com/images/tiago/color/tiago-plum-wine-thumb.jpg"
                        alt=""
                      />
                    </div>
                    <div className="cbp-caption-activeWrap portfolio-hover-effect">
                      <i className="ti-zoom-in" />
                    </div>
                  </a>
                  <h3 className="fontface-one font-weight-500 portfolio-heading-text padding-top-30 padding-bottom-15">
                    Tata Tiago
                  </h3>
                  <p className="fontface-two paragraph-18 font-weight-500 portfolio-detail-text">
                  </p>
                </div>
              </div>
              <div
                id="js-loadMore-whitebox-gallery"
                className="cbp-l-loadMore-button wow fadeInUp"
                data-wow-delay="650ms"
              >
                <a
                  href="portfolio-loadfile/index.html "
                  className="cbp-l-loadMore-link button-setting button-setting-primary background-secondary text-color-white"
                  rel="nofollow"
                >
                  <span className="cbp-l-loadMore-defaultText">Load More</span>
                  <span className="cbp-l-loadMore-loadingText">Loading...</span>
                  <span className="cbp-l-loadMore-noMoreLoading">
                    No More Works
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/* Company Portfolio End */}
  </>

  <>
    {/* Contact Form Section Start */}
    {/* <section
      className="contact-form-section padding-top-bottom  car-dot-img" style={{backgroundColor:"#0a0a5f"}}
      id="contact-form-section"
      
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div
              className="company-contact-detail wow fadeInLeft"
              data-wow-delay="300ms"
            >
              <div className="contact-heading padding-bottom-35">
                <h2 className="fontface-one text-color-dark">Connect</h2>
                <span></span>
                <h2 className="fontface-one text-color-dark">
                  with
                  <span className="font-weight-700">
                    Mutha Motors<span className="text-color-primary">.</span>
                  </span>
                </h2>
              </div>
              <p className="paragraph-14 text-color-darkgrey fontface-two">
                We invite you to visit to any of our Maharashtra locations and
                experience the Mutha Motors prestige for yourself.
              </p>
              <div className="contact-detail-list">
                <ul>
                  <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                   
                  </li>
                  <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                   
                  </li>
                  <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                   
                  </li>
                  <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                  
                  </li>
                </ul>
              </div>
              <div className="contact-company-icons padding-top-35">
                <ul className="company-social-icons">
                  <li className="company-social-icons-list">
                    <a href="#." className="facebook-icon">
                      <i className="bi bi-facebook" />
                    </a>
                  </li>
                
                  <li className="company-social-icons-list">
                    <a href="#." className="instagram-icon">
                      <i className="bi bi-instagram" />
                    </a>
                  </li>
                  <li className="company-social-icons-list">
                    <a href="#." className="googleplus-icon">
                      <i className="bi bi-google" />
                    </a>
                  </li>
                  <li className="company-social-icons-list">
                    <a href="#." className="dribble-icon">
                      <i className="bi bi-whatsup" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div
              className="company-contact-form wow fadeInRight"
              data-wow-delay="300ms"
            >
              <h3 className="text-light-blue font-weight-600 fontface-one padding-bottom-45 ">
                Write Us
              </h3>
              <form className="contact-form-outer">
                <div className="row">
                  <div className="col-md-6">
                    <div className="contact-form-textfield padding-bottom-30">
                      <input
                        type="text"
                        placeholder="Your Name"
                        className="form-control paragraph-16"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-form-textfield padding-bottom-30">
                      <input
                        type="email"
                        placeholder="Your Email"
                        className="form-control paragraph-16"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact-form-textfield padding-bottom-45">
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control paragraph-16"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact-form-textfield padding-bottom-40">
                      <textarea
                        placeholder="Your Message"
                        className="form-control paragraph-16"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <a
                      className="button-setting button-setting-primary background-secondary text-color-white width-100 text-center"
                      href="#."
                    >
                      Send Message
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/* Contact Form Section End */}
  </>

    
  </section>
    </>
  }
