import React from 'react'
import NexonEv_Navigation from '../../Components/NexonEv_Navigation'
import nexon_max_header_1 from '../ev/ev_images/nexon_max_images/nexon_max_header_1'
import nexon_max_video_1 from '../ev/ev_video/nexon_max_video_1'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function NexonEv_Max() {
  const options = {
    items: 3,
    autoplay: true,
    loop: true,
    nav: true,
    dots: false,
    margin:'10px',
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  }

  return <>
  <NexonEv_Navigation/>
  <div style={{backgroundColor:'#001218', color:'#00adba'}}>
    <div className="container-fluid">
      <img src={nexon_max_header_1} alt="" />
      <div className="row" >
        
        <video src={nexon_max_video_1} controls autoPlay ></video>
      </div>
    </div>

{/* <section style={{backgroundColor:'#001218', color
    :'#17E3F2'}}>
  <div className="container" >
<div className="row">
  <div className="col-lg-6 p-5">
    <h3 style={{color
    :'#17E3F2'}}>Drive what drove India electric!</h3>
    <p className='text-white'>Loved & recommended by 40,000+ customers. Driven over 800M+ km. Go.ev with India's #1 EV.
</p>
  </div>
  <div className="col-lg-6">
  <div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/range-max/RangeMax-Battery.jpg" class="d-block w-100" alt="..."/>
    <h2 >Large 40.5kWh battery pack </h2>
    <p className='text-white'>Enjoy an uninterrupted drive with the NEXON EV’s larger 40.5kWh battery pack
                                            that’s IP76-rated and high density as well.
                                        </p>

    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/range-max/RangeMax-Range.jpg" class="d-block w-100" alt="..."/>
   
      <h2 >453 <sup>#</sup> km driving range </h2>
    <p className='text-white'>Now access a longer driving range on a single charge. The powerful Nexon EV
                                            MAX is built to go the distance with you.
                                        
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/range-max/RangeMax-Warranty.jpg" class="d-block w-100" alt="..."/>
    
      <h2 >8-year/1.6 lakh km warranty  </h2>
    <p className='text-white'>Our state-of-the-art high voltage ZIPTRON EV Architecture inspires confidence
                                            and provides a sense of reliability, even on the toughest of roads.
                                        
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/range-max/RangeMax-Motor.jpg" class="d-block w-100" alt="..."/>
    
      <h2 >Powerful 143 PS electric motor </h2>
    <p className='text-white'>Experience electrifying performance with Nexon EV MAX. Savour the thrill of
                                            electric, coupled with an indulgently smooth driving experience.
                                        
                                        </p>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  </div>
</div>

  </div>

  <div className="row p-5">
    <h2>Safety MAX
</h2>
<p className='text-white'>Safety is at the centre of what we do. That’s why the Nexon EV MAX comes loaded with a range of active and passive safety features. These intuitive and innovative additions are what make the Nexon EV MAX excel in the safety domain.

</p>
  </div>

  <div className="row p-5">
<div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
   
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/ESP-WITH.png" class="d-block w-100" alt="..."/>
    <h2 >ESP® with i-VBAC </h2>
    <p className='text-white'>As you drive off to new destinations, the Nexon EV MAX’s ESP® (Electronic Stability
                                Program) helps keep you on course. It works with the innovative i-VBAC (Intelligent
                                Vacuum-less Boost & Active Control) system to reduce stopping distances by improving
                                brake dynamics. 
                                        </p>

    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/All-Four-Disk.png" class="d-block w-100" alt="..."/>
   
      <h2 >All Four Disc Brakes </h2>
    <p className='text-white'>Get MAX control with front and rear wheel disc brakes which increase braking efficiency,
                                giving you more control. 
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/Fortified.png" class="d-block w-100" alt="..."/>
    
      <h2 >Fortified Cabin</h2>
    <p className='text-white'>The Nexon EV MAX’s high-strength reinforced steel structure absorbs impact energy,
                                providing comprehensive safety to its occupants.
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/ABS-with-ABD.png" class="d-block w-100" alt="..."/>
    
      <h2 >ABS with EBD and BA</h2>
    <p className='text-white'>Take charge of the road with all the control that comes with the Anti-lock Braking System
                                with Electronic Brake-force Distribution and Brake Assist.
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/IP67.png" class="d-block w-100" alt="..."/>
    
      <h2 >IP67 Rated Battery Pack and Motor </h2>
    <p className='text-white'>Powered by state-of-the-art Ziptron technology, the reliable IP67 rated battery pack
                                comes with high standards of ingress protection, making it shock, water, and dust
                                resistant. 
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/Hill-Hold.png" class="d-block w-100" alt="..."/>
    
      <h2 >Hill Hold Assist</h2>
    <p className='text-white'>The Nexon EV MAX’s intuitive Hill Hold Assist senses gradation and holds for 2 seconds
                                when you stop on an incline, offering you more time to control your SUV.
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/Hill-Descent.png" class="d-block w-100" alt="..."/>
    
      <h2 >Hill Descent Control (HDC)</h2>
    <p className='text-white'>The HDC system helps control the Nexon EV MAX’s speed on slope declines, leaving you to
                                focus on steering your vehicle. 
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/Auto-vehicle--hold.png" class="d-block w-100" alt="..."/>
    
      <h2 >Auto Vehicle Hold (AVH)</h2>
    <p className='text-white'>AVH comes into play during the stop-and-go conditions of city roads, as well as on
                                inclines. It maintains brake pressure even after your foot is off the brake thereby
                                reducing driver's fatigue and providing convenience and assurance at the same time.
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max//safety-max/PanicBreakimg.png" class="d-block w-100" alt="..."/>
    
      <h2> Panic Brake Alert</h2>
    <p className='text-white'>Any time you brake hard in an emergency, the system triggers the Hazard lamps, alerting
                                fellow motorists to improve their response time. 
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/ISO-FIX.png" class="d-block w-100" alt="..."/>
    
      <h2>ISOFIX Anchorage</h2>
    <p className='text-white'>The Nexon EV MAX comes with a pre-installed ISOFIX anchorage for child seats. So, you can
                                enjoy peace of mind as you drive. 
                                        </p>
    </div>
    <div class="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/safety-max/Dual-AirBag.png" class="d-block w-100" alt="..."/>
    
      <h2>Dual front airbags </h2>
    <p className='text-white'>The Nexon EV MAX comes with dual frontal airbags as standard across all variants,
                                providing added safety.
                                        </p>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    
  </div>

</section> */}

<section className='mt-5'>
  <div className="text-center">
  <h1 style={{ color:'#00adba'}}>Convenience Max
</h1></div>
<p className='text-white text-center'>The Nexon EV Max comes with a range of features that add tons of convenience to an already amazing driving experience. Witness convenience pushed to the max.
</p>
<OwlCarousel options={options} className="mt-5">
      <div className="item">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/convenience-max/6_TATA-EV_CM_Cruise-Control.jpg" alt="slide1" className='img-border' />
      <h3>Cruise Control</h3>
      <p className='text-white'>The Nexon EV MAX comes with a multi-utility cooled storage unit that keeps things
                                    fresh and cool. It’s a great place to store cool drinks on a hot day. </p>
      </div>
      <div className="item">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/convenience-max/3_TATA-EV_CM_Boot-Space.jpg" alt="slide2" className='img-border'/>
     
        <h3>Ergonomic Boot Space </h3>
      <p className='text-white'>The Nexon EV MAX’s 350 litres boot space (expandable up to 690 litres) is large
                                    enough to carry everything you need and then some, especially for your longer trips.
                                 </p>
      </div>
      <div className="item">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/convenience-max/4_TATA-EV_CM_Cooled-Glove-Box.jpg" alt="slide3" className='img-border' />
        <h3>Cooled Glove Box</h3>

        <p className='text-white'>The Nexon EV MAX comes with a multi-utility cooled storage unit that keeps things
                                    fresh and cool. It’s a great place to store cool drinks on a hot day. 
                                 </p>
      </div>
      <div className="item">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/convenience-max/5_TATA-EV_CM_Itpms.jpg" alt="slide4" className='img-border'/>
      <h3>i-TPMS</h3>
      <p className='text-white'>Keep a check on your tire pressure at all times with the i-TPMS (Indirect Tire
                                    Pressure Monitoring System). Get alerts when your car is low on tire pressure
                                    through an easy display indicator on the digital instrument cluster. </p>
      </div>
     
    </OwlCarousel>

</section>

<section>
  <div className="container">
    <div className="row mt-5">
<h1 style={{ color:'#00adba'}}>Ziptron EV Architecture
</h1>
<p className='text-white'>Experience technology that moves you to the MAX. Our state-of-the-art high voltage ZIPTRON EV Architecture inspires confidence and provides a sense of reliability, even on the toughest of roads.
</p>


<div className="col-lg-10 mt-5">
<Tabs>
   
    <TabPanel>
     <div className="row">
      <div className="col-lg-6">
        <h3>Battery</h3>
        <p className='text-white'>Access an enhanced range and more efficiency with the higher battery capacity of the high energy density 40.5 kWh li-ion battery with IP67 rating.

</p>
      </div>
      <div className="col-lg-6">
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/Architecture-Battery.png" alt="" />
      </div>
     </div>
    </TabPanel>

    <TabPanel>
      <div className="row">
      <div className="col-lg-6">
        <h3>Motor</h3>
        <p className='text-white'>Tap into a zippy and electrifying driving experience powered by the Permanent Magnet Synchronous Motor with a peak output of 143 PS & torque of 250Nm.
</p>
      </div>
      <div className="col-lg-6">
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/Architecture-Motor.png" alt="" />
      </div>
     </div>
    </TabPanel>

    <TabPanel>
      <div className="row">
      <div className="col-lg-6">
        <h3>Power Electronics</h3>
        <p className='text-white'>Witness the sheer brilliance of ZIPTRON technology’s consistent performance and durability across a wide range of operating temperatures.
</p>
      </div>
      <div className="col-lg-6">
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/img-motor-max.png" alt="" />
      </div>
     </div>
    </TabPanel>

    <TabList>
      <div className="row">
        <div className="col-lg-3">
      <Tab key="tab1" title="Tab 1">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/2_TATA-EV_Ziptron_Battery-thumb.png" alt="" />
      </Tab>
        </div>

        <div className="col-lg-3">

      <Tab key="tab2" title="Tab 2">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/1_TATA-EV_Motor-thumb.png" alt="" />
      </Tab>
        </div>
        <div className="col-lg-3">
      <Tab key="tab3" title="Tab 3">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/1_TATA-EV_Ziptron_Power-electronics-thumb.png" alt="" />
      </Tab>

        </div>

        </div>
    </TabList>

  </Tabs>
</div>
    </div>

    <div className="row mt-5">
     <div className="col-lg-6">
      <h2>Annual Savings Calculator
</h2>
<p  className='text-white'>HOW MUCH WILL YOU SAVE ON FUEL?
</p>
<br />
<p  className='text-white'>Ever-rising fuel prices becoming a huge concern? Switch to electric and see how driving an electric vehicle can be easy on your pocket and the planet.

</p>
<br />
<p className='text-white'>Estimated Savings (Over 5 years*)
</p>
<div className="row">
  <div className="col-lg-1 mx-3">
    <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/pointer-icon-sample-ev.png" alt="" height="40px" width="30px" />
  </div>
  <div className="col-lg-4">
    <h3><b> ₹ 167 654</b></h3>
    <p className='text-white'> Fuel Cost Savings

</p>
  </div>
</div>
<div className="row">
  <div className="col-lg-1 mx-3">
    <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/pointer-icon-sample-ev.png" alt="" height="40px" width="30px" />
  </div>
  <div className="col-lg-6">
    <h4><b>5.29 MILLION GRAMS
</b></h4>
    <p className='text-white'> Tailpipe CO2 Reduction
</p>
  </div>
</div>
<div className="row">
  <div className="col-lg-1 mx-3">
    <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/pointer-icon-sample-ev.png" alt="" height="40px" width="30px" />
  </div>
  <div className="col-lg-6">
    <h4><b>48 FULL-GROWN TREES

</b></h4>
    <p className='text-white'> Equivalent to CO2 reduction


</p>
  </div>
</div>
     </div>
     <div className="col-lg-6">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/nexon-max/max-car-front.png" alt="" /> 

      </div>
    </div>
  </div>
</section>
  </div>
  </>
}
