import React from 'react'

import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import safari_header_2 from '../images/safari_images/safari_header_2'
import safari_header_1 from '../images/safari_images/safari_header_1'
import safari_video from '../video/safari_video'
import safari_video_new from '../video/safari_video_new'


import safari_features_7 from '../images/safari_images/safari_features_7'
import safari_features_6 from '../images/safari_images/safari_features_6'
import safari_features_5 from '../images/safari_images/safari_features_5'
import safari_features_4 from '../images/safari_images/safari_features_4'
import safari_features_3 from '../images/safari_images/safari_features_3'
import safari_features_2 from '../images/safari_images/safari_features_2'
import safari_features_1 from '../images/safari_images/safari_features_1'
import safari_exteriors_1 from '../images/safari_images/safari_exteriors_1'
import safari_exteriors_2 from '../images/safari_images/safari_exteriors_2'
import safari_exteriors_3 from '../images/safari_images/safari_exteriors_3'
import safari_exteriors_4 from '../images/safari_images/safari_exteriors_4'
import safari_exteriors_5 from '../images/safari_images/safari_exteriors_5'
import safari_exteriors_6 from '../images/safari_images/safari_exteriors_6'
import safari_exteriors_7 from '../images/safari_images/safari_exteriors_7'
import safari_exteriors_8 from '../images/safari_images/safari_exteriors_8'
import safari_exteriors_9 from '../images/safari_images/safari_exteriors_9'
import safari_interiors_1 from '../images/safari_images/safari_interiors_1'
import safari_interiors_2 from '../images/safari_images/safari_interiors_2'
import safari_interiors_3 from '../images/safari_images/safari_interiors_3'
import safari_interiors_4 from '../images/safari_images/safari_interiors_4'
import safari_interiors_5 from '../images/safari_images/safari_interiors_5'
import safari_interiors_6 from '../images/safari_images/safari_interiors_6'
import safari_interiors_7 from '../images/safari_images/safari_interiors_7'
import safari_interiors_8 from '../images/safari_images/safari_interiors_8'



import safari_sky from '../images/safari_images/safari_sky'
import safari_white from '../images/safari_images/safari_white'
import safari_dark from '../images/safari_images/safari_dark'
import safari_grey from '../images/safari_images/safari_grey'
import safari_blue from '../images/safari_images/safari_blue'

export default function Safari() {
  return <>
  <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
  <div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={safari_header_1}
       class="d-block w-100" alt="tata_punch_1"/>
    </div>
    <div class="carousel-item">
      <img src={safari_header_2} class="d-block w-100" alt="tiago_header_1"/>
    </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

<section>
  <div className="row mt-4">
    <div className="col-lg-12 col-sm-6 col-xs-6">
<h3 className='text-highlights' style={{color
:"#fff"}}><b>Introducing The All- New Tata Safari, Get Ready To Reclaim Your Life.
</b></h3>
<hr className='bg-light' />
<p>The All-New Safari, in its new avatar, is a potent combination of power and elegant sophistication. It has evolved to satisfy the new-age SUV customers, who demand arresting design, plush and comfortable interiors, unparalleled versatility, outstanding performance, and connected car technology for a modern, multifaceted lifestyle.

</p>
</div>
  </div>
</section>
{/* video section */}

{/* <div className="row mt-4">
 <h1 className='text-light text-style' ><strong>Tata Safari Key Features
</strong> </h1>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={safari_video}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div> */}
{/* highlights */}
<section>
       <div className="row mt-5">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
    <img src={safari_features_1} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>ADAS</strong> </h1>
    <p className='hiddenMobile'> Advanced Driver Assistance Systems (ADAS) are Advanced technology Solutions intended to enhance the road safety by preventing or reducing the impact of potential accidents. ADAS systems activate safety measures by alerting the driver and initiating possible safeguard measures in the vehicle. ADAS by Tata Motors is an intuitive driving technology which is built to take on any surprises while driving, so that you can experince a safe journey with your loved ones.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong> MAJESTIC DESIGN
</strong> </h1>
<p className='hiddenMobile'>The Safari’s unique and domineering stance coupled with imposing proportions, lend it a tremendous road presence powered with a graceful agility and an all-purpose nature. The smart and expressive surface treatment brings in a surreal dynamism. The interiors of the new Safari too have been crafted with exquisite deft to convey a classy, comfortable feel and to ensure that you can soak themselves in luxury during your journeys with the loved ones.

 
             </p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={safari_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
    <img src={safari_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3">
    <h1 className='text-style' style={{color:'#fff'}}> <strong> GRAND LIFESTYLE
</strong> </h1>
    <p className='hiddenMobile'>The New Safari is crafted with some of the finest workmanship on display, enriched with indulgent & luxurious interiors, made from high-quality materials, making it a truly unforgettable experience every time you take the wheel. Immaculate attention has been given to the finest of details in the new Safari. It comes loaded with a host of other features to complement your indulgent and active lifestyle.


              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>ELEVATED PERFORMANCE
</strong> </h1>
<p className='hiddenMobile'>With the New Safari, no matter where it’s taken, the refined ride & handling and a thrilling drive powers the journey. With the 170 PS Kryotec Engine, 6 Speed AT, ESP Terrain response modes and Multi Drive Modes, Safari delivers a new benchmark of drivability, and driving pleasure.

</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={safari_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={safari_features_5} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>PEDIGREE OF OMEGARC
</strong> </h1>
    <p className='hiddenMobile'>The new Safari further builds on the proven capability of OMEGARC, an architecture derived from the renowned D8 platform from Land Rover, which in itself is the gold standard of SUVs worldwide. Being the 2nd vehicle on OMEGARC, it carries forward this legendary pedigree.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>IN-VOGUE INFOTAINMENT & CONNECTIVITY

</strong> </h1>
<p className='hiddenMobile'>The New Safari has evolved to be in sync with the technologically savvy SUV customer of today. The Floating Island Infotainment system is designed not just to offer the premium audio experience that customers expect, it also comes loaded with iRA, our connected car technology to take the connectivity to the next level.



</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={safari_features_6} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={safari_features_7} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>COCOONED SAFETY

</strong> </h1>
    <p className='hiddenMobile'>Nothing is more important than safety, and the new Safari ensures that all its occupants are cocooned in an impenetrable bubble of safety once inside the vehicle. The new Safari not only possesses the inherent strengths of OMEGARC, it also comes loaded with top-of-the-line safety features to ensure active and passive safety.



</p>
    </div> 
</div>
 </div>
 </div>
 </section>


 {/* colors */}
 <div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-white'><b>COLORS</b> </h1>
    <hr className='bg-white' />

    <br />
     
      <Tabs>
   
    <TabPanel>
      <div className='col-lg-12'><img src={safari_blue} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={safari_grey} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={safari_white} alt="" /></div>
    </TabPanel>
  
    <TabPanel>
    <div className='col-lg-12'>         <img src={safari_dark} alt="" />
</div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={safari_sky} alt="" />
</div>
    </TabPanel>

<div className="row">
    <TabList>
      <Tab>     
            
      <img src={safari_blue} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={safari_grey} alt="" height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={safari_white} alt="" height="180px" width="180px"/>
</Tab>
      
      <Tab>     

         <img src={safari_dark} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={safari_sky} alt=""height="180px" width="180px" />
</Tab>
    </TabList>
    </div>
  </Tabs>




 
  
  </div>

<div className="container hiddenDesktop">
  <h1 className='text-white'>Colors</h1>
  <hr />
<div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
     
      <img src={safari_blue}
       class="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div class="carousel-item">
      <img src={safari_dark} class="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div class="carousel-item">
      <img src={safari_grey} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={safari_sky} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={safari_white} class="d-block w-100" alt="..."/>
    </div>
   
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
  {/* colors end */}

  {/* safari Accessories */}
<section>
  <h4 className='p-2'><b>Safari Accessories
</b></h4>
<hr  style={{color:'#fff'}}/>
  <Tabs className="mt-5">
    <TabList>
      <Tab><button type="button" class="btn btn-primary mx-3" >EXTERIORS</button>
</Tab>
      <Tab><button type="button" class="btn btn-danger">INTERIORS</button>
</Tab>
    </TabList>

    <TabPanel>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_exteriors_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong> BONNET MASCOT
</strong> </h1>
    <p className='hiddenMobile'> Inscribed with the word ‘SAFARI’ to make heads turn.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong> BONNET SCOOPS
</strong> </h1>
<p className='hiddenMobile'>Sport the bold yet rugged look.
</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={safari_exteriors_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_exteriors_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong> SIDE STEPS
</strong> </h1>
    <p className='hiddenMobile'>Comfort right at the doorstep.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>FRONT PARKING SENSORS
</strong> </h1>
<p className='hiddenMobile'> Get accurate and easy parking guidance.

</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={safari_exteriors_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_exteriors_5} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>ROOF RACK
</strong> </h1>
    <p className='hiddenMobile'> Aerodynamically designed rack with ample space to pack outdoor gears.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>WHEEL STEP

</strong> </h1>
<p className='hiddenMobile'>Load and offload easily.


</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={safari_exteriors_6} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_exteriors_7} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>CYCLE CARRIER

</strong> </h1>
    <p className='hiddenMobile'>Explore a little more on just two wheels.


</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>DOOR VISOR WITH CHROME WINDOW FRAME


</strong> </h1>
<p className='hiddenMobile'>Sleek premium design with a touch of chrome.




</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={safari_exteriors_8} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_exteriors_7} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>DUMMY EXHAUST CHROME


</strong> </h1>
    <p className='hiddenMobile'>A road presence impossible to ignore.




</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>DUMMY EXHAUST CHROME
</strong> </h1>
<p className='hiddenMobile'>A road presence impossible to ignore.
</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={safari_exteriors_9} alt="" />

      </div>
    </div>
  </div>
 </div>
    </TabPanel>

    <TabPanel>
    <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_interiors_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>REAR SEAT ENTERTAINMENT
</strong> </h1>
    <p className='hiddenMobile'>Enjoy the luxury of a ride with touchscreen entertainment.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
  <h3 className='text-style' style={{color:'#fff'}}> <strong>WIRELESS CHARGER
</strong> </h3>
    <p className='hiddenMobile'>Never lose connectivity on the go.

</p>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3">
  <img src={safari_interiors_2} alt="" />
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_interiors_3} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>PUDDLE LAMPS WITH T LOGO
</strong> </h1>
    <p className='hiddenMobile'> Step out safely and in style.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
  <h3 className='text-style' style={{color:'#fff'}}> <strong>SCUFF PLATES
</strong> </h3>
    <p  className='hiddenMobile'> Metallic designer plates for premium interiors.

</p>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3">
  <img src={safari_interiors_4} alt="" />
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_interiors_5} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>DASH CAMS
</strong> </h1>
    <p className='hiddenMobile'> Don’t forget to capture the adventure.

</p>
    </div> 
</div>
 </div>
 </div>
 
 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
  <h3 className='text-style' style={{color:'#fff'}}> <strong>COAT HANGER

</strong> </h3>
    <p  className='hiddenMobile'> Travel light, let the hanger carry the weight.



</p>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3">
  <img src={safari_interiors_6} alt="" />
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={safari_interiors_7} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>SUN SHADES

</strong> </h1>
    <p className='hiddenMobile'> Shade yourself from the harsh sun.



</p>
    </div> 
</div>
 </div>
 </div>
    </TabPanel>
  </Tabs>
  </section>

  {/* <section>
    <div className="container">
    <h1 className='text-style text-white'><b>What's New ?</b></h1>
    <hr className='bg-white'/>
    <div className="row">
      <div className="col-lg-12 col-sm-6 col-xs-6">
        <video src={safari_video_new} style={{border:'2px solid white'}} controls allowFullScreen></video>
      </div>
    </div>
    </div>
  </section> */}
</div>
  </>
}
