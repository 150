import React, { useEffect, useState } from 'react'
import Home from './pages/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navigation from './Components/Navigation'
import Sales from './pages/Sales'
import Careers from './pages/Careers'
import Service from './pages/Service'
import Testimonial from './pages/Testimonial'
import About from './pages/About'
import Contact from './pages/Contact'
import Footer from './Components/Footer'
import Tiago from './pages/brands/Tiago'
import Tigor from './pages/brands/Tigor'
import Punch from './pages/brands/Punch'
import Harrier from './pages/brands/Harrier'
import Nexon from './pages/brands/Nexon'
import Safari from './pages/brands/Safari'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css';
import Altroz from './pages/brands/Altroz'

import Tiago_ev from './pages/ev/Tiago_ev'
import Icng_Tiago from './pages/brands/Icng_Tiago'
import Nexon_ev from './pages/ev/Nexon_ev'
import Tigor_ev from './pages/ev/Tigor_ev'
import Kaziranga from './pages/brands/Kaziranga'
import Nexon_dark from './pages/brands/Nexon_dark'

import loader_gif from './pages/images/loader_gif'
import Preloader from './Components/Preloader'
import Icng_Tigor from './pages/brands/Icng_Tigor'
import DarkRed_Nexon from './pages/brands/DarkRed_Nexon'
import WhatsAppPopup from './Components/WhatsAppPopup'
import NexonEv_Max from './pages/brands/NexonEv_Max'
import NexonEv_DarkEdition from './pages/brands/NexonEv_DarkEdition'
import Contact_New from './pages/Contact_New'
import Home2 from './pages/Home2'
import FooterNew from './Components/FooterNew'


export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return <>
  {
    loading ? <Preloader loading={loading} /> 
 : <BrowserRouter>
<Navigation/>
  <Routes> 
  <Route path='/' element={<Home/>}/>
  {/* <Route path='/home2' element={<Home2/>}/> */}
  <Route path='/about' element={<About/>}/>

  <Route path="/sales" element={<Sales/>}></Route>
    <Route path="/careers" element={<Careers/>}></Route>
    <Route path="/tiago" element={<Tiago/>}></Route>
    <Route path="/tigor" element={<Tigor/>}></Route>
    <Route path="/punch" element={<Punch/>}></Route>
    <Route path="/harrier" element={<Harrier/>}></Route>
    <Route path="/safari" element={<Safari/>}></Route>
    <Route path="/nexon" element={<Nexon/>}></Route>
    <Route path="/altroz" element={<Altroz/>}></Route>
    {/* <Route path="/contact" element={<Contact/>}></Route> */}
    <Route path="/contact" element={<Contact_New/>}></Route>
    <Route path="/service" element={<Service/>}></Route>
  <Route path="/ev/tiago" element={<Tiago_ev/>}></Route>
    <Route path="/ev/icng" element={<Icng_Tiago/>}></Route>
    <Route path="/ev/nexon" element={<Nexon_ev/>}></Route>
    <Route path="/brands/kaziranga" element={<Kaziranga/>}></Route>
    <Route path="/brands/nexon" element={<Nexon_dark/>}></Route>
    <Route path="/ev/tigor" element={<Tigor_ev/>}></Route>
    <Route path="/ev/icng_tigor" element={<Icng_Tigor/>}></Route>
    <Route path="/nexon/darkred" element={<DarkRed_Nexon/>}></Route>
    <Route path="/ev/nexonmax" element={<NexonEv_Max/>}></Route>
    <Route path="/ev/nexondarkedition" element={<NexonEv_DarkEdition/>}></Route>
  </Routes>
  {/* <WhatsAppPopup/> */}
  <Footer/>
  {/* <FooterNew/> */}
  </BrowserRouter>
}
  </>
}
