import React, { useState } from 'react'

import tiago_ev_header_1 from '../ev/ev_images/ev_tiago/tiago_ev_header_1'
import { Carousel, Card } from 'react-bootstrap';

import nexon_ev_prime_header_1 from  './ev_images/nexon_ev/nexon_ev_prime_header_1'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NexonEv_Navigation from '../../Components/NexonEv_Navigation';


export default function Nexon_ev() {
  const [key, setKey] = useState('dual');
  const cards = [
    { image: 'https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/home-banners/prime-1920-X-985-desk3.jpg'},
    { image: 'https://ev.tatamotors.com/wp-content/themes/tata-ev/images/evolve/evolve-technology-desk.png'},
    
  ];

  const cards2 = [
    {
      image: 'https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/interior-features/ev-multi-mode-regen.png',
      title: 'Multi-mode Regen',
      description: 'Choose between three pre-set levels of regenerative braking with Multi-mode Regen.This enables you to maximise efficiency on all your drives.',
    },
    {
      image: 'https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/interior-features/ev-cruise-control.png',
      title: 'Cruise Control',
      description: 'Drive effortlessly with the Nexon EV PRIME’s intuitive Cruise Control feature.',
    },
    {
      image: 'https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/interior-features/ev-i-tpms.png',
      title: 'i-TPMS',
      description: 'Monitor your tire pressure at all times with a convenient display indicator on the digital panel.',
    },
    {
      image: 'https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/interior-features/ev-smart-watch-integration.png',
      title: 'Smart watch integration',
      description: 'Enjoy ease of accessibility by staying in touch with your car, locking/unlocking it, as well as doing much more, all with just a tap on your smart watch. ',
    },
  ];
  
  
  
  return <>
{/* <Navigation_ev_nexon/> */}
{/* <Navigation_ev_nexon/> */}
<NexonEv_Navigation/>
  <div style={{backgroundColor:'#EFF1F3'}}>

  <Carousel>
  {cards.map((card, index) => (
    <Carousel.Item key={index}>
      <Card>
        <Card.Img variant="top" src={card.image} />
        <Card.Body>
          <Card.Title variant='ev-prime-heading'>{card.title}</Card.Title>
        </Card.Body>
      </Card>
    </Carousel.Item>
  ))}
</Carousel>



{/* slider 2 */}
<div className="row mx-5 mt-3">
  <h3 className="text-center">NEW INTELLIGENT FEATURES</h3>
  <h2 className="text-center" style={{color:'#00adba'}}><b>#BeNewForever
</b></h2>
</div>
<OwlCarousel
  className="owl-theme mt-5"
  items={3}
  loop
  margin={10}
  nav
  dots={false}
  autoplay
>
  {cards2.map((card, index) => (
    <Card key={index} className="">
      <Card.Img variant="top" src={card.image} />
      <Card.Body>
        <Card.Title >{card.title}</Card.Title>
        <Card.Text>{card.description}</Card.Text>
      </Card.Body>
    </Card>
  ))}
</OwlCarousel>


<div className="mx-5">
<h6 className='text-center'>INSTANT TORQUE
</h6>
<h2 style={{color:'#00adba'}} className='text-center'> <b>Go Places</b> 
</h2>
<p className='text-center'>True power to power through. With a state-of-the-art 30.2 kW battery, experience a thrilling 0 - 100 km/h pickup within 9.9^ s.

</p>
</div>
<section className="row ">
  <div className="col-lg-12 col-md-10 col-sm-11">
    <div className="rangeBigcar i-shadow">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-range-go-anywhere.jpg" alt="" loading='lazy' />

      <div className="rangeData aos-init aos-animate" data-aos="fade-up" data-aos-delay="20">
        <ul className='text-center text-light centered'>
          <li>
            <h5 className='text-light'><b>0-100 km/h</b> </h5>
            <p>in 9.9^ s</p>
          </li>
          <li>
            <h5><b>15A</b> </h5>
            <p>Regular <br />Charging</p>
          </li>

        </ul>

        </div> 
      </div> 
  </div>
</section>

<section className='charginStationSection'>
<div className="row">
  <div className="col-lg-8 col-md-10 col-sm-11">
    <div className="row">
      <div className="col-lg-4 col-md-4">
<div className="titleWrap aos-init aos-animate p-2" data-aos='fade-up' data-aos-delay='50'>
  <h2 className='text-light mx-5 m-5'> 
    <h6 className='text-light'>Charging Options</h6>
    <b className='text-light mx-5 '>
      <br />
    Easy charging options for Nexon EV PRIME</b>
  </h2>

</div>


      </div>

      <div className="col-lg-7 col-md-4 mt-5">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-fast-charging_new.png" alt="" />

          </div>
          <div className="col-lg-6  col-sm-6">
            <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-overnight-charging.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

<div className="container">
  <div className="row">
<h6>Best value Propositon</h6>
<h2 style={{color:'#00adba'}}> <b> Drive home a new experience</b>
</h2>
  </div>

  <div className="row">
    <div className="col-lg-6 col-sm-6 col-xs-6 card">
      <div className="row box-shadow bg-white">
        <div className="col-lg-4">
          <div className=" bf-icoWrap mt-5" >
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/ico-bf-1.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 mt-3">
          <h4 style={{color:'#00adba'}}><b>Best-In-className Performance</b></h4>
          <ul>
            <li>1. 0-100 km/h in 9.9^ s
</li>
<li>2. 0-60 km/h in 4.6^ s
</li>
<li>3.  Instant availability of 245 Nm torque
</li>
<li>4.  60% increase in peak torque in Sport Mode
</li>
          </ul>

        </div>
      </div>
    </div>
    <br />
    <div className="col-lg-6 col-sm-6 col-xs-6 card">
    <div className="row box-shadow bg-white">
        <div className="col-lg-4">
          <div className=" bf-icoWrap mt-5" >
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/ico-bf-2.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 mt-3">
          <h4 style={{color:'#00adba'}}><b>Safest Commute
</b></h4>
          <ul>
            <li>1. Built on India's first 5-star rated platform - Nexon
</li>
<li>2. IP67 certified battery & motor
</li>
<li>3. AIS 048 (Nail Penetration), Crush Test, Overcharge protection, Shock protection, Short protection
</li>

          </ul>

        </div>
      </div>
    </div>
  </div>

  <div className="row mt-3">
    <div className="col-lg-6 col-sm-6 col-xs-6 card ">
      <div className="row box-shadow bg-white">
        <div className="col-lg-4">
          <div className=" bf-icoWrap mt-5" >
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/ico-bf-1.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 mt-3">
          <h4 style={{color:'#00adba'}}><b>Easy Ownership
</b></h4>
          <ul>
            <li>1.Largest EV service network across India

</li>
<li>2. 8 years / 1.6 Lakh km* warranty on battery & motor

</li>
<li>3.  24x7 emergency & breakdown assistance
</li>
<li>4.  Doorstep service
</li>
          </ul>

        </div>
      </div>
    </div>
    <br />
    <div className="col-lg-6 col-sm-6 col-xs-6 card">
    <div className="row box-shadow bg-white">
        <div className="col-lg-4">
          <div className=" bf-icoWrap mt-5" >
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/ico-bf-2.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 mt-3">
          <h4 style={{color:'#00adba'}}><b>Top-Notch Connectivity

</b></h4>
          <ul>
            <li>1. Tata Motors ZConnect app offering 35 connected car features

</li>
<li>2. Key features include remote commands, vehicle health monitoring, safety & security, driving & trip analytics, and location based services

</li>


          </ul>

        </div>
      </div>
    </div>
  </div>

  <div className="row mt-3">
    <div className="col-lg-6 col-sm-6 col-xs-6 card ">
      <div className="row box-shadow bg-white">
        <div className="col-lg-4">
          <div className=" bf-icoWrap mt-5" >
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/ico-bf-1.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 mt-3">
          <h4 style={{color:'#00adba'}}><b>Seamless Charging Experience
</b></h4>
          <ul>
            <li>1.Free home charging installation*


</li>
<li>2. Largest public charging network in India


</li>
<li>3.  24x7 emergency charging support (in 5 cities)

</li>
<li>4.  Doorstep service
</li>
          </ul>

        </div>
      </div>
    </div>
    <br />
    <div className="col-lg-6 col-sm-6 col-xs-6 card">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/bf-car-img.png" alt="" />
    {/* <div classNameName="row box-shadow bg-white">
        <div classNameName="col-lg-4">
          <div classNameName=" bf-icoWrap mt-5" >
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/best-feature/ico-bf-2.png" alt="" />
          </div>
        </div>
        <div classNameName="col-lg-6 mt-3">
          <h4 style={{color:'#00adba'}}><b>Top-Notch Connectivity

</b></h4>
          <ul>
            <li>1. Tata Motors ZConnect app offering 35 connected car features

</li>
<li>2. Key features include remote commands, vehicle health monitoring, safety & security, driving & trip analytics, and location based services

</li>


          </ul>

        </div>
      </div> */}
    </div>
  </div>

<section className='technologySection mt-5'>

  <div className="row">
    <div className="col-lg-12 col-sm-4 col-xs-4 mt-5">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-ziptron-logo.png" alt="" />
    </div>
    <div className="row">
      <div className="col-lg-10">
{/* tabs */}
<Tabs className='mt-5 text-white'>
    <TabList className='text-center'>
      <Tab>Battary</Tab>
      <Tab>Motor</Tab>
      <Tab>Power Electronincs</Tab>
    </TabList>

    <TabPanel>
     <div className="row">
      <div className="col-lg-8 col-sm-6 col-xs-6 img-margin">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-battery.png" alt="" />
      </div>
     </div>
     <div className="row">
      <div className="col-lg-12 col-sm-6 col-xs-6 img-margin">
        <p>Experience the unrivalled performance of 30.2 kW li-ion battery, rigorously tested over 1 Million km, designed to operate under extreme ambient conditions. Power your Tata Motors Nexon electric SUV with the futureproof IP67 certified battery for a delightful journey ahead.

</p>
      </div>
     </div>
    </TabPanel>
    <TabPanel>
    <div className="row">
      <div className="col-lg-8 col-sm-6 col-xs-6 img-margin">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-motor.png" alt="" />
      </div>
     </div>
     <div className="row">
      <div className="col-lg-12 col-sm-6 col-xs-6 img-margin">
        <p>Unleash the power of Tata Ziptron at the push of a pedal. Enjoy a pure, unadulterated electric performance powered by the 3 Phase Permanent Magnet Synchronous Motor with peak output of 129 PS and torque of 245 Nm.


</p>
      </div>
     </div>
    </TabPanel>

    <TabPanel>
    <div className="row">
      <div className="col-lg-8 col-sm-6 col-xs-6 img-margin">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-power-electronics.png" alt="" />
      </div>
     </div>
     <div className="row">
      <div className="col-lg-12 col-sm-6 col-xs-6 img-margin">
        <p>Witness the sheer brilliance of ZIPTRON technology through the integrated motor controller. Its liquid cooled architecture offers consistent performance and durability over a wide range of operating temperatures.




</p>
      </div>
     </div>
    </TabPanel>
  </Tabs>
      </div>
    </div>
  </div>
</section>

<section className='mt-5'>
  <div className="container">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <h6>SAFETY</h6>
    <h1 style={{color:'#00adba'}}><b>Uncompromised SAFETY
</b></h1>


  </div>
<div className="row">
  <div className="col-lg-10 col-sm-6 col-xs-6 text-center">
  <Tabs
   id="controlled-tab-example"
   activeKey={key}
   onSelect={(k) => setKey(k)}
   className="mb-3"
  >
    <TabList>
      <div className="row">
<div className="col-lg-2">
      <Tab eventKey="dual">Dual Airbags</Tab></div>
      <div className="col-lg-3">
      <Tab eventKey="Abs">ABS with EBD & corner stability control</Tab></div>
      <div className="col-lg-2">
      <Tab eventKey="reinforced">reinforced body structure</Tab></div>
      <div className="col-lg-3">
      <Tab  eventKey="liquid">Liquid cool battery pack Rated IP67</Tab> </div>
      <div className="col-lg-2">
      <Tab  eventKey="IsoFix">ISOFIX</Tab>
      </div> 
      </div>
    </TabList>

    <TabPanel >
     <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/safety/img-safety-airbag.jpg" alt="" />
     <p>With an aim to increase occupant safety, the Tata Nexon EV PRIME relies on its Dual Airbags to ensure safety of both, the Driver and the Co-driver.

</p>

    </TabPanel>
    <TabPanel>
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/safety/img-safety-abs.jpg" alt="" />
      <p>Equipped with the latest 9.3 generation Anti-lock Braking System, Electronic Brake-Distribution System and Cornering Stability Control, the Tata Nexon EV PRIME checks all the boxes when it comes to keeping you in control.

</p>
    </TabPanel>
    <TabPanel>
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/safety/img-safety-reinforced.jpg" alt="" />
      <p>Nexon EV’s PRIME rigid structure of reinforced steel and energy absorbing body structure complies with latest full frontal, side impact and offset impact crash regulations.

</p>
    </TabPanel>
    <TabPanel>
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/safety/img-safety-liquid.jpg" alt="" />
      <p>Liquid cooled, IP67 certified and AIS-048 safety standard compliant battery pack, rigorously tested for protection from nail penetration, crush, overcharge, and short. Designed to operate over a wide range of temperatures, withstands all weathers, and is shockproof, waterproof, and dustproof.

</p>
    </TabPanel>
    <TabPanel>
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/safety/img-safety-isofix.jpg
" alt="" />
<p>Nexon EV PRIME is equipped with ISOFIX anchorage for child seats to promise a safe and sound drive for your little ones.

</p>
    </TabPanel>
  </Tabs>
  </div>
</div>

  </div>
</section>

<div className="container mt-5">

  <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-never-pay-for-gas.jpg" alt="" />
</div>

<section
                  className= "elementor-section elementor-top-section elementor-element elementor-element-9574062 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="9574062"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-64927e2"
                        data-id="64927e2"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-30e42b8 elementor-widget elementor-widget-karde_budget_gallery"
                              data-id="30e42b8"
                              data-element_type="widget"
                              data-widget_type="karde_budget_gallery.default"
                            >
                              <div className="elementor-widget-container">
                                <section className="car_budget_area p_100">
                                  <div className="container">
                                    <div
                                      className="flag_center_title wow animated fadeInUp"
                                      data-wow-delay="0.2s"
                                    >
                                      <h6>ANNUAL SAVINGS CALCULATOR
</h6>
                                      <h2 style={{color:'#00adba'}}>
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/flag-6.png"
                                          title="flag-6"
                                          alt="flag-6"
                                        />
                                        How Much Will You Save On Fuel?

                                      </h2>
                                    </div>
                                   
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-4 mx-5" style={{color:'#00adba'}}>
                                      <h6>Estimated Savings
</h6>
<p>(Over 5 Years) </p>
<hr />
<h4><b>₹ 122 654
</b></h4>
<p>Fuel Cost Savings

</p>
<h4><b>5.29 MILLION GRAMS
</b></h4>
<p>Tailpipe CO2 Reduction

</p>
<h4><b>48 FULL-GROWN TREES
</b></h4>
<p>Equivalent to CO2 reduction

</p>
                                    </div>
                                   
                                    <div className="col-lg-6">
                                                                    <div className="price_content">
                                                                      <div
                                                                        className="price_km wow animated fadeInUp"
                                                                        data-wow-delay="0.4s"
                                                                      >
                                                                   
                                                                      <img
                                                                        className="img-fluid wow animated fadeInLeft"
                                                                        data-wow-delay="0"
                                                                        src='https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/img-calculator-car.png'
                                                                        
                                                                        alt="Alt"
                                                                      />
                                                                    </div>
                                                                  </div>
                                  </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
</div>

</div>
  </>
}
