import React,{useState} from 'react'
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import abouts_banner_1 from '../images/about/abouts_banner_1'
import tiago_header_1 from '../images/header/tiago_header_1' 
import tata_altroz_1 from '../images/header/tata_altroz_1' 

import header_2 from '../images/header/header_2'
import header_3 from '../images/header/header_3'

import tiago_1 from '../images/header/tiago_1'
import tiago_video from '../video/tiago_video'
import tiagoNRG_video from '../video/tiagoNRG_video'

import tiago_features_1 from '../images/tiago_images/tiago_features_1'
import tiago_nrg_features_1 from '../images/tiago_images/tiago_nrg_features_1'
import tiago_features_2 from '../images/tiago_images/tiago_features_2'
import tiago_features_3 from '../images/tiago_images/tiago_features_3'
import tiago_features_4 from '../images/tiago_images/tiago_features_4'
import tiago_features_5 from '../images/tiago_images/tiago_features_5'
import tiago_nrg_features_2 from '../images/tiago_images/tiago_nrg_features_2'
import tiago_nrg_features_3 from '../images/tiago_images/tiago_nrg_features_3'
import tiago_nrg_features_4 from '../images/tiago_images/tiago_nrg_features_4'
import tiago_nrg_features_5 from '../images/tiago_images/tiago_nrg_features_5'

import Navigation_ev_tiago from '../../Components/Navigation_ev_tiago'

export default function Tiago() {



  return (


    <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
      <Navigation_ev_tiago/>
      <section>
  <div id="carouselExampleIndicators" class="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={header_2}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={tiago_1} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div className="carousel-item">
      <img src={header_3} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>



</section>

{/* video section */}
{/* <Tabs className="mt-5">
    <TabList>
      <Tab><button type="button" class="btn btn-primary" >Tiago</button>
</Tab>
      <Tab><button type="button" class="btn btn-danger">Tiago NRG</button>
</Tab>
    </TabList>

    <TabPanel>
       <div className="row">
 <p className='text-light' ><strong>Explore Tata Tiago key Highlights</strong> </p>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={tiago_video}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div>
    </TabPanel>
    <TabPanel>
    <div className="row">
 <p className='text-light' ><strong>Explore Tata NRG Tiago key Highlights</strong> </p>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={tiagoNRG_video}   controls style={{border:'2px solid white'}}></video>
   </div>
 </div>
    </TabPanel>
  </Tabs> */}

{/* highlights */}
<Tabs className="mt-5">
    <TabList>
      <Tab><button type="button" className="btn btn-primary mx-3" >Tiago</button>
</Tab>
      <Tab><button type="button" className="btn btn-danger">Tiago NRG</button>
</Tab>
    </TabList>

    <TabPanel>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={tiago_features_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong> ABSOLUTE SAFETY</strong> </h1>
    <p className='hiddenMobile'> Absolute safety as standard in all variants. Dual Airbags, ABS with EBD and CSC, Rear view camera adds to the safety in Tiago</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong> SPORTY DESIGN</strong> </h1>
<p className='hiddenMobile mx-3'>New sporty exterior design. Boldly Sculpted Hood Design, Sleek Tri Arrow Designed Piano Black Front Grille & Stylish Dual Tone Bumpers giving a new look to the front fascia</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={tiago_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={tiago_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong> NEXT-GEN TECHNOLOGY</strong> </h1>
    <p className='hiddenMobile mx-3'>7” Connect Next infotainment by Harman with 8 speakers. Android Auto and Apple CarPlay connectivity</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong>DYNAMIC PERFORMANCE</strong> </h1>
<p className='hiddenMobile mx-3'> Zippy drive with new Revotron 1.2 l BS6 petrol engine. Best in segment power of 86PS. Also available in AMT</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={tiago_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={tiago_features_5} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong> CUSTOMER DELIGHT</strong> </h1>
    <p className='hiddenMobile mx-3'> Intelligent designed utility spaces. Fully Automatic Climate Control , Steering mounted control, Digital Instrument Cluster, Height Adjustable Drivers Seat</p>
    </div> 
</div>
 </div>
 </div>

    </TabPanel>

    <TabPanel>
    <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={tiago_nrg_features_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#ce2029'}}> <strong>EXPRESSIVE DESIGN</strong> </h1>
    <p className='hiddenMobile mx-3'> The all-new Tata Tiago NRG is crafted for those who want to make a different impression everyday. A testament of class and style unlike any other, it doesn’t just stand out, but makes a bold style statement. It is the toughroader, for the tough ones. </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
  <h3 className='text-style' style={{color:'#ce2029'}}> <strong>NEXTGEN CONNECTIVITY</strong> </h3>
    <p className='hiddenMobile mx-3'> Recreate your world in a car with just a push of a button. Built-in Apple CarPlayTM and Android AutoTM, unrestricted audio streaming, state-of-the-art navigation and a lot more. </p>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3">
  <img src={tiago_nrg_features_2} alt="" />
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={tiago_nrg_features_3} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#ce2029'}}> <strong>EXCEPTIONAL PERFORMANCE</strong> </h1>
    <p className='hiddenMobile mx-3'> Get ready to experience a different rush altogether – a rush of NRG with a power-packed performance. </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
  <h3 className='text-style' style={{color:'#ce2029'}}> <strong>ROBUST SAFETY</strong> </h3>
    <p  className='hiddenMobile mx-3'>  Let nothing stop your quest for adventure as safety engulfs you in the all-new NRG. </p>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3">
  <img src={tiago_nrg_features_4} alt="" />
    </div> 
</div>
 </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={tiago_nrg_features_5} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#ce2029'}}> <strong>GREAT CONVENIENCE</strong> </h1>
    <p className='hiddenMobile mx-3'> What appears cool is also convenient. That is exactly what the all-new Tiago NRG offers with its array of features. </p>
    </div> 
</div>
 </div>
 </div>
    </TabPanel>
  </Tabs>
  </div>

    
  )
}
