import React from 'react'
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import punch_header_2 from '../images/punch_images/punch_header_2'
import punch_header_1 from '../images/punch_images/punch_header_1'
import punch_video_features from '../video/punch_video_features'

import punch_features_5 from '../images/punch_images/punch_features_5'
import punch_features_4 from '../images/punch_images/punch_features_4'
import punch_features_3 from '../images/punch_images/punch_features_3'
import punch_features_2 from '../images/punch_images/punch_features_2'
import punch_features_1 from '../images/punch_images/punch_features_1'

import punch_blue from '../images/punch_images/punch_blue'
import punch_orange from '../images/punch_images/punch_orange'
import punch_red from '../images/punch_images/punch_red'
import punch_green from '../images/punch_images/punch_green'
import punch_white from '../images/punch_images/punch_white'
import punch_gold from '../images/punch_images/punch_gold'

export default function Punch() {
  return <>
  <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
  <section>
  <div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={punch_header_1}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={punch_header_2} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</section>


{/* video section */}

{/* <div className="row mt-4">
 <h1 className='text-light text-style' ><strong>Explore Tata PUNCH Key Highlights
</strong> </h1>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={punch_video_features}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div> */}
    
{/* highlights */}
<section>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={punch_features_1} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong>BOLD SUV DESIGN</strong> </h1>
    <p className='hiddenMobile'> The world will take notice of two showstoppers. You and the Punch.</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong> CLASS LEADING COMFORT & CONVIENIENCE FEATURES</strong> </h1>
<p className='hiddenMobile'>Sit back and relax. Cruise with your gang. Make room for more fun. Its your space to unwind. 
             </p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={punch_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={punch_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#fff'}}> <strong> ABSOLUTE SAFETY</strong> </h1>
    <p className='hiddenMobile'>This is for the fun-lovers, the thrill-seekers, the daily commuters. But most of all, this is for the responsible ones. The PUNCH vibes with your need for safety.
              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#fff'}}> <strong>VERSATILE & SPIRITED PERFORMANCE</strong> </h1>
<p className='hiddenMobile'>The high performance of the PUNCH will be the second reason onlookers will turn green with envy. The first will be the smile on your face while you are behind the wheel.</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={punch_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={punch_features_5} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h5 className='text-style' style={{color:'#fff'}}> <strong>ADVANCE INFOTAINMENT AND CONNECTIVITY</strong> </h5>
    <p className='hiddenMobile'> The PUNCH loves to entertain just like you. It's got state-of-the-art-technology that keeps you connected and will give your already thrilling drive experience, that advanced touch.</p>
    </div> 
</div>
 </div>
 </div>
 </section>

 <div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-white'><b>COLORS</b> </h1>
    <hr className='bg-white' />

    <br />
     
      <Tabs>
   
    <TabPanel>
      <div className='col-lg-12'><img src={punch_gold} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={punch_green} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={punch_orange} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={punch_white} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={punch_red} alt="" />
</div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={punch_blue} alt="" />
</div>
    </TabPanel>

<div className="row">
    <TabList>
      <Tab>     
            
      <img src={punch_gold} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={punch_green} alt="" height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={punch_orange} alt="" height="180px" width="180px"/>
</Tab>
      <Tab>     

         <img src={punch_white} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={punch_red} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={punch_blue} alt=""height="180px" width="180px" />
</Tab>
    </TabList>
    </div>
  </Tabs>




 
  
  </div>

<div className="container hiddenDesktop">
  <h1 className='text-white'>Colors</h1>
  <hr />
<div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
     
      <img src={punch_gold}
       class="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div class="carousel-item">
      <img src={punch_red} class="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div class="carousel-item">
      <img src={punch_green} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={punch_blue} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={punch_orange} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={punch_white} class="d-block w-100" alt="..."/>
    </div>
    
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>
  {/* colors end */}

 </div>
  </>
}
