import React, { useEffect, useState } from 'react'
import loader_gif from '../pages/images/loader_gif'

export default function Preloader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
  <div style={{backgroundColor:'rgb(0,0,0)'}}>

   <div className="d-flex justify-content-center align-items-center vh-100" >
      <img src={loader_gif} alt="Loading..." />
    
    </div>
    </div>
    
 )
}
