import React from 'react'
import contactHeader from './images/contact_header.jpg'

import contact_BG from './images/contact_BG.jpg'

export default function Contact_New() {
  return <>
  <section>
    {/* <img src='https://media.istockphoto.com/id/1323765737/photo/close-up-of-a-businessman-using-a-laptop-computer-and-a-mobile-phone.jpg?b=1&s=170667a&w=0&k=20&c=xhxUuce47qfFXME0SyZH1B4rYtxLU2f9GWY5Y46ABhA=' alt="" className='bg-header-manage' /> */}
    <img src='https://media.istockphoto.com/id/1477510030/photo/website-page-contact-us-or-e-mail-marketing-concept-customer-support-hotline-contact-us.jpg?s=612x612&w=0&k=20&c=gfTTImiv-EAEyYmeyurT_ts_BxFugpmdI0cfeNE_GBI=' alt="" className='bg-header-manage' />
    <h1 className='text-center centered' style={{color:"#000"}}><b>Contact Us</b></h1>
  </section>

  {/* <section>
<h1 className='text-center p-2' style={{color:'#000'}}><i>Connect with Media</i>  <hr/> </h1>
<div className="contact-company-icons text-center">
              <ul className="company-social-icons">
                <li className="company-social-icons-list">
                  <a href="#." className="facebook-icon">
                    <i className="bi bi-facebook" />
                  </a>
                </li>
               
                <li className="company-social-icons-list">
                  <a href="#." className="instagram-icon">
                    <i className="bi bi-instagram" />
                  </a>
                </li>
                <li className="company-social-icons-list">
                  <a href="#." className="googleplus-icon">
                    <i className="bi bi-google" />
                  </a>
                </li>
                <li className="company-social-icons-list">
                  <a href="#." className="dribble-icon">
                    <i className="bi bi-whatsapp" />
                  </a>
                </li>
              </ul>
            </div>
  </section> */}

<div  

>
  <section className="car-dot-img " 
  // style={{backgroundColor:"#000"}} 
  >
<div className="container">
   
  <div className="row" >
    <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
      <div className="card-body box-design" style={{backgroundColor:'#ffff'}}>
        <h4 className='text-center p-2 mt-2' style={{color:'#00abc9',fontSize:'28px'}}> <b>Mutha Motors (Ch.Sambhajinagar)</b> </h4>
        <p className='text-center ' style={{fontSize:'18px'}}> R8XW+9G8, Gopi Nath Pura, Satara <br /> Deolai Parisar, Chh.Sambhajinagar, <br /> Maharashtra 431005</p>

        <ul  className='text-center'>
          <li>
            <h5 >Sales</h5>
            <h6 ><b>Mobile   :  </b>
               <a href="tel:+917066039659"className='text-decoration-none' style={{color:"#454545"}}>7066039659</a></h6>
               <h6 ><b>Email :</b> <a href="mailto:cro@muthamotors.com"className='text-decoration-none' style={{color:"#454545"}}>cro@muthamotors.com</a></h6>
<br />
<h5>Service</h5>
            <h6 ><b>Mobile :</b>
             <a href="tel:+919850902096" className='text-decoration-none' style={{color:"#454545"}}>9850902096</a></h6>
             <h6><b>Email :</b> <a href="mailto:sales.abad@muthamotors.com"className='text-decoration-none' style={{color:"#454545"}}>sales.abad@muthamotors.com</a></h6>


            {/* <h6 ><b>Service :</b> <a href="tel:+917350990700" className='text-decoration-none' style={{color:"#454545"}}>7350990700</a></h6>
            <h6 ><b>Service :</b> <a href="tel:+919552513873" className='text-decoration-none' style={{color:"#454545"}}>9552513873</a></h6> */}
            <a
                        href="https://www.google.com/maps/dir/19.8748734,75.3761947/R8XW%2B9G8+MUTHA+MOTORS,+Gopi+Nath+Pura,+Satara+Deolai+Parisar,+Aurangabad,+Maharashtra+431005/@19.8623813,75.3340877,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bdba3974a4356c3:0xfcfba4eb70fc7cb2!2m2!1d75.3463192!2d19.8484111"
                        className="text-decoration-none button-setting background-primary text-color-white button-setting-secondary"
                      >
                        Location
                      </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
      <div className="card-body box-design" style={{backgroundColor:'#ffff'}}>
        <h4  style={{color:'#00abc9',fontSize:'28px'}} className="text-center  p-2 mt-2"><b>Mutha Motors (Jalna)</b> </h4>
        <p className='text-center'>  Aditya nagar, Chandanzira, Jalna,  Maharashtra 431203</p>

        <ul className='text-center'>
          <li>
            <h5>Sales</h5>
            <h6><b>Mobile :  </b> <a href="tel:+917066039656" className='text-decoration-none' style={{color:"#454545"}}>7066039656</a></h6>
            <h6><b>Email :</b> <a href="mailto:digitalleads@muthamotors.com"  className='text-decoration-none' style={{color:"#454545"}}>digitalleads@muthamotors.com</a></h6>
          
          <h5>Service</h5>
            <h6><b>Mobile :</b> <a href="tel:+919552513873" className='text-decoration-none' style={{color:"#454545"}}>9552513873</a></h6>
            {/* <h6><b>Service :</b> <a href="tel:+917350990700"  className='text-decoration-none' style={{color:"#454545"}}>7350990700</a></h6> */}
            <h6><b>Email :</b> <a href="mailto:det.jalna@muthamotors.com"  className='text-decoration-none' style={{color:"#454545"}}>det.jalna@muthamotors.com</a></h6>

            <br />
            <a
                        href="https://www.google.com/maps/dir/19.874865,75.377049/TATA+MOTORS+LIMITED+MUTHA+MOTORS+JALNA,+Aditya+nagar,+Chandanzira,+Jalna,+Maharashtra+431203/@19.8870144,75.1443048,9.5z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bda51dd332f304b:0xa7ae2289377481a1!2m2!1d75.8563004!2d19.8589784"
                        className="text-decoration-none button-setting background-primary text-color-white button-setting-secondary"
                      >
                        Location
                      </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
      <div className="card-body box-design" style={{backgroundColor:'#ffff'}}>
        <h4 style={{color:'#00abc9', fontSize:'28px'}} className="text-center  p-2 mt-2"> <b>Mutha Motors (Buldhana)</b> </h4>
        <p className='text-center'>Khetan Square Shegaon Buldhana-443001 landmark. Opposite Shegaon Urban Bank</p>

        <ul className='text-center'>
          <li>
            <h5>Sales</h5>

            <h6><b>Mobile :  </b> <a href="tel:+9187938813110" className='text-decoration-none' style={{color:"#454545"}}>87938813110</a></h6>
            <h6><b>Email :</b> <a href="mailto:service.buldhana@muthamotors.com" className='text-decoration-none' style={{color:"#454545"}}>service.buldhana@muthamotors.com</a></h6>
            {/* <h6><b> Test drive :</b> <a href="tel:+919850902096" className='text-decoration-none' style={{color:"#454545"}}>9158691014</a></h6> */}
            <h5>Service</h5>
            <h6><b>Mobile :</b> <a href="tel:+917350990700" className='text-decoration-none' style={{color:"#454545"}}>9552513873</a></h6>
            <p><b>Email:</b>
             <a href="mailto:spareparts.buldhana@muthamotors.com" className='text-decoration-none ml-0' style={{color:"#454545"}}>spareparts.buldhana
             @muthamotors.com</a></p>
            <a
                        href="https://www.google.com/maps/dir//mutha+motors+buldhana+Khetan+Square+Shegaon+Buldhana+-+443001.+Landmark.+Opposite+Shegaon+Urban+Bank./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bda1e077e700edb:0x14e04802a6c02141?sa=X&ved=2ahUKEwjBsoDnytP9AhUwIbcAHTa3DHEQ9Rd6BAhREAQ"
                        className="text-decoration-none  button-setting background-primary text-color-white button-setting-secondary"
                       
                      >
                        Location
                      </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <div className="row">
    <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
      <div className="card-body box-design" style={{backgroundColor:'#ffff'}}>
        <h4 style={{color:'#00abc9',fontSize:'28px'}} className="text-center  p-2 mt-2"> <b> Mutha Motors (Khamgaon)</b></h4>
        <p className="text-center"> Nandura Road Kishan nagar, Sutala Road, Khamgaon, Maharashtra-444303</p>

        <ul className='text-center'>
          <li>
            <h5>Sales</h5>
            <h6><b>Mobile :  </b> <a href="tel:+917066039659"  className='text-decoration-none' style={{color:"#454545"}}>7066039659</a></h6>
            <h6><b>Email :</b> <a href="mailto:sales.khamgaon@muthamotors.com" className='text-decoration-none' style={{color:"#454545"}}>sales.khamgaon@muthamotors.com</a></h6>
            {/* <h6><b>Service :</b> <a href="tel:+919850902096"  className='text-decoration-none' style={{color:"#454545"}}>9850902096</a></h6> */}
            {/* <h6><b>Service :</b> <a href="tel:+917350990700"  className='text-decoration-none' style={{color:"#454545"}}>7350990700</a></h6> */}
           <h5>Service</h5>
            <h6><b>Mobile :</b> <a href="tel:+919552513873"  className='text-decoration-none' style={{color:"#454545"}}>9552513873</a></h6>
            <h6><b>Email :</b> <a href="mailto:cro@muthamotors.com" className='text-decoration-none' style={{color:"#454545"}}>cro@muthamotors.com</a></h6>
            <br />
            <a
                        href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                        className="text-decoration-none button-setting background-primary text-color-white button-setting-secondary"
                      >
                        Location
                      </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
      <div className="card-body box-design" style={{backgroundColor:'#ffff'}}>
        <h4 style={{color:'#00abc9',fontSize:'28px'}} className="text-center  p-2 mt-2"> <b>Mutha Motors (Malkapur)</b> </h4>
        <p className="text-center">Dipak Nagar, Buldhana Road,  Malkapur, Buldhana-443101</p>

        <ul className='text-center'>
          <li>
            <h5>Sales</h5>
            <h6><b>Mobile:  </b> <a href="tel:+917066039656"  className='text-decoration-none' style={{color:"#454545"}}>7066039656</a></h6>
            <h6><b>Email :</b> <a href="mailto:sales.malkapur@muthamotors.com"  className='text-decoration-none' style={{color:"#454545"}}>sales.malkapur@muthamotors.com</a></h6>
            {/* <h6><b> Test drive :</b> <a href="tel:+919158691014"  className='text-decoration-none' style={{color:"#454545"}}>9158691014</a></h6> */}
            {/* <h6><b>Service :</b> <a href="tel:+919552513873"  className='text-decoration-none' style={{color:"#454545"}}>9552513873</a></h6> */}
            <h6><b>Mobile :</b> <a href="tel:+917350990700"  className='text-decoration-none' style={{color:"#454545"}}>7350990700</a></h6>
            <h6><b>Email :</b> <a href="mailto:digitalleads@muthamotors.com"  className='text-decoration-none' style={{color:"#454545"}}>digitalleads@muthamotors.com</a></h6>
            <a
                        href="https://www.google.com/maps/dir/19.8761653,75.3433139/mutha+motors++Dipak+Nagar,+Buldhana+Road,+Malkapur,+buldhana+-+443101/@20.380068,75.2174967,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bd9e91b5bb8f013:0xd2695479c6986a71!2m2!1d76.1934365!2d20.8725101"
                        className="text-decoration-none button-setting background-primary text-color-white button-setting-secondary"
                      >
                        Location
                      </a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>
  </section>

{/* Contact Form Section Start */}
<section
    className="contact-form-section padding-top-bottom car-dot-img"
    style={{backgroundColor:"#0a0a5f"}}
    id="contact-form-section"
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div
            className="company-contact-detail wow fadeInLeft"
            data-wow-delay="300ms"
          >
            <div className="contact-heading padding-bottom-35">
              <h2 className="fontface-one text-color-dark">Connect</h2>
              <h2 className="fontface-one text-color-dark">
                with
                <span className="font-weight-700">
                  Mutha Motors<span className="text-color-primary">.</span>
                </span>
              </h2>
            </div>
            <p className="paragraph-14 text-color-darkgrey fontface-two">
              We invite you to visit to any of our Maharashtra locations and
              experience the Mutha Motors prestige for yourself.
            </p>
            <div className="contact-detail-list">
              <ul>
                <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                  {/* 31 Van Choung Street */}
                </li>
                <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                  {/* Ha Noi, Viet Nam */}
                </li>
                <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                  {/* +84 (91)2 847 911 */}
                </li>
                <li className="paragraph-15 text-color-darkgrey fontface-two padding-top-10">
                  {/* Pagina@info.com */}
                </li>
              </ul>
            </div>
            <div className="contact-company-icons padding-top-35">
              <ul className="company-social-icons">
                <li className="company-social-icons-list">
                  <a href="#." className="facebook-icon">
                    <i className="bi bi-facebook" />
                  </a>
                </li>
                {/* <li className="company-social-icons-list">
                  <a href="#." className="twitter-icon">
                    <i className="fab fa-twitter" />
                  </a>
                </li> */}
                <li className="company-social-icons-list">
                  <a href="#." className="instagram-icon">
                    <i className="bi bi-instagram" />
                  </a>
                </li>
                <li className="company-social-icons-list">
                  <a href="#." className="googleplus-icon">
                    <i className="bi bi-google" />
                  </a>
                </li>
                <li className="company-social-icons-list">
                  <a href="#." className="dribble-icon">
                    <i className="bi bi-whatsup" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-6 offset-lg-1">
          <div
            className="company-contact-form wow fadeInRight"
            data-wow-delay="300ms"
          >
            <h3 className="text-light-blue font-weight-600 fontface-one padding-bottom-45 ">
              Write Us
            </h3>
            <form className="contact-form-outer">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-form-textfield padding-bottom-30">
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="form-control paragraph-16"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-form-textfield padding-bottom-30">
                    <input
                      type="email"
                      placeholder="Your Email"
                      className="form-control paragraph-16"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-form-textfield padding-bottom-45">
                    <input
                      type="text"
                      placeholder="Subject"
                      className="form-control paragraph-16"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact-form-textfield padding-bottom-40">
                    <textarea
                      placeholder="Your Message"
                      className="form-control paragraph-16"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <a
                    className="button-setting button-setting-primary background-secondary text-color-white width-100 text-center"
                    href="#."
                  >
                    Send Message
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Contact Form Section End */}
  </div>
  </>
}
