import React from 'react'
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import harrier_video_1 from '../video/harrier_video_1'
import harrier_header_1 from '../images/harrier_images/harrier_header_1'
import harrier_header_2 from '../images/harrier_images/harrier_header_2'

import harrier_features_1 from '../images/harrier_images/harrier_features_1'
import harrier_features_2 from '../images/harrier_images/harrier_features_2'
import harrier_features_3 from '../images/harrier_images/harrier_features_3'
import harrier_features_4 from '../images/harrier_images/harrier_features_4'
import harrier_features_5 from '../images/harrier_images/harrier_features_5'
import harrier_features_6 from '../images/harrier_images/harrier_features_6'
import harrier_features_7 from '../images/harrier_images/harrier_features_7'


import harrier_dark from '../images/harrier_images/harrier_dark'
import harrier_blue from '../images/harrier_images/harrier_blue'
import harrier_red from '../images/harrier_images/harrier_red'
import harrier_white from '../images/harrier_images/harrier_white'
import harrier_sky from '../images/harrier_images/harrier_sky'
import harrier_grey from '../images/harrier_images/harrier_grey'

export default function Harrier() {
  return <>
   <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
  <div id="carouselExampleIndicators" class="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={harrier_header_1}
       className="d-block w-100" alt="tata_punch_1"/>
    </div>
    <div className="carousel-item">
      <img src={harrier_header_2} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

<section>
  <div className="row mt-4">
    <div className="col-lg-12 col-sm-6 col-xs-6 p-5">
<h3 className='text-highlights' style={{color
:"#98040d"}}><b>Introducing The All- New Tata Safari, Get Ready To Reclaim Your Life.
</b></h3>
<hr className='bg-light' />
<p>The All-New Safari, in its new avatar, is a potent combination of power and elegant sophistication. It has evolved to satisfy the new-age SUV customers, who demand arresting design, plush and comfortable interiors, unparalleled versatility, outstanding performance, and connected car technology for a modern, multifaceted lifestyle.

</p>
</div>
  </div>
</section>
{/* video section */}

{/* <div className="row mt-4">
 <h1 className='text-light text-style' ><strong>Tata Harrier Key Features
</strong> </h1>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={harrier_video_1}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div> */}
{/* highlights */}
<section>
       <div className="row mt-5">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={harrier_features_1} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#98040d'}}> <strong>ADAS
</strong> </h1>
    <p className='hiddenMobile'> Advanced Driver Assistance Systems (ADAS) are Advanced technology Solutions intended to enhance the road safety by preventing or reducing the impact of potential accidents. ADAS systems activate safety measures by alerting the driver and initiating possible safeguard measures in the vehicle. ADAS by Tata Motors is an intuitive driving technology which is built to take on any surprises while driving, so that you can experince a safe journey with your loved ones.


</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#98040d'}}> <strong> STUNNING IMPACT DESIGN 2.0

</strong> </h1>
<p className='hiddenMobile'>Based on the Impact Design 2.0 philosophy, with strong proportions and expressive surfaces providing an incredible stance, powerful road presence and a sense of dynamism, Harrier is truly Above All.


             </p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={harrier_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
    <img src={harrier_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#98040d'}}> <strong> EXHILARATING PERFORMANCE

</strong> </h1>
    <p className='hiddenMobile'>With 6 speed Automatic/6 speed Manual Transmission mated to the next generation Kryotec 170 Turbocharged BS6 Phase 2 Diesel engine makes for an effortless driving experience.


              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#98040d'}}> <strong>PEDIGREE OF OMEGARC

</strong> </h1>
<p className='hiddenMobile'>Based on the OMEGA Architecture, with the rich pedigree of Land Rover’s legendary D8 platform running through its veins, the Harrier is a cut Above All.


</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={harrier_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={harrier_features_5} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h3 className='text-style' style={{color:'#98040d'}}> <strong>ABSOLUTE CONVENIENCE

</strong> </h3>
    <p className='hiddenMobile'>Best in class luxurious yet practical interiors and future ready infotainment ensures serene in- cabin experience with a blend of design flourish.


</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#98040d'}}> <strong>CLASS LEADING INFOTAINMENT


</strong> </h1>
<p className='hiddenMobile'>Next-gen infotainment with Wi-Fi based smartphone connectivity through Android Auto™ & Apple Car Play™ along with best in class voice recognition technology to access navigation, phone calls, music with a press of a button.


</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={harrier_features_6} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={harrier_features_7} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#98040d'}}> <strong>TOP-OF-THE-LINE SAFETY


</strong> </h1>
    <p className='hiddenMobile'>Instilling a greater sense of confidence every time you take the wheel, the Harrier comes equipped with ESP & ABS as a standard feature ensuring safety in difficult driving conditions.


</p>
    </div> 
</div>
 </div>
 </div>
 </section>


 {/* colors */}
 <div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-white'><b>COLORS</b> </h1>
    <hr className='bg-white' />

    <br />
     
      <Tabs>
   
    <TabPanel>
      <div className='col-lg-12'><img src={harrier_blue} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={harrier_dark} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={harrier_white} alt="" /></div>
    </TabPanel>
  
    <TabPanel>
    <div className='col-lg-12'>         <img src={harrier_red} alt="" />
</div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={harrier_sky} alt="" />
</div>
    </TabPanel>

<div className="row">
    <TabList>
      <Tab>     
            
      <img src={harrier_blue} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={harrier_dark} alt="" height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={harrier_white} alt="" height="180px" width="180px"/>
</Tab>
      
      <Tab>     

         <img src={harrier_red} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={harrier_sky} alt=""height="180px" width="180px" />
</Tab>
    </TabList>
    </div>
  </Tabs>




 
  
  </div>

<div className="container hiddenDesktop">
  <h1 className='text-white'>Colors</h1>
  <hr />
<div id="carouselExampleIndicators" class="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={harrier_blue}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={harrier_red} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div className="carousel-item">
      <img src={harrier_dark} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={harrier_sky} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={harrier_sky} className="d-block w-100" alt="..."/>
    </div>
   
    
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
  {/* colors end */}



  
</div>
  </>
}
