import React from "react";
import darkRed_header_1 from "../images/darkRed_images/darkRed_header_1";
import darkRed_features_1 from "../images/darkRed_images/darkRed_features_1";
import darkRed_features_2 from "../images/darkRed_images/darkRed_features_2";
import darkRed_features_3 from "../images/darkRed_images/darkRed_features_3";
import darkRed_features_4 from "../images/darkRed_images/darkRed_features_4";
import darkRed_features_5 from "../images/darkRed_images/darkRed_features_5";
import darkRed_features_6 from "../images/darkRed_images/darkRed_features_6";
import darkRed_features_7 from "../images/darkRed_images/darkRed_features_7";
import darkRed_features_8 from "../images/darkRed_images/darkRed_features_8";
import darkRed_features_9 from "../images/darkRed_images/darkRed_features_9";
import Navigation_ev_nexon from "../../Components/Navigation_ev_nexon";

export default function DarkRed_Nexon() {
  return (
    <>
    <Navigation_ev_nexon/>
      <div style={{ backgroundColor: "rgb(0,0,0)", color: "#fff" }}>
        <div className="container-fluid">
          <img src={darkRed_header_1} alt="" />
        </div>

        <section className="mt-5">
          <h3 className="mx-5">
            <b>Key Features</b>
          </h3>
          <hr className="text-white" />
          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-6 col-sm-3 col-xs-3">
                  <img src={darkRed_features_1} alt="" />
                </div>
                <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>BOLD OBERON BLACK COLOUR</strong>
                  </h1>
                  <p className="hiddenMobile">
                    Shine with an unique hue that puts you in the spotlight.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6 ">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>R16 BLACKSTONE ALLOYS</strong>
                  </h1>
                  <p className="hiddenMobile">
                    Dark and classy alloys that will never let you blend with
                    the rest.
                  </p>
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3">
                  <img src={darkRed_features_2} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_3} alt="" className="img-style" />
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>
                      PIANO BLACK TRI ARROW GRILLE WITH ZIRCON RED ACCENTS
                    </strong>
                  </h1>
                  <p className="hiddenMobile">Never go unnoticed</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>EXQUISITE CARNELIAN RED INTERIOR THEME</strong>
                  </h1>
                  <p className="hiddenMobile">Reflects your Bold personality</p>
                </div>
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_4} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_5} alt="" className="img-style" />
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>STEEL -BLACK FINISHED DASHBOARD</strong>
                  </h1>
                  <p className="hiddenMobile">
                    Adds suaveness to the Bold interiors
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>WIRELESS CHARGER</strong>
                  </h1>
                  <p className="hiddenMobile">
                    Wireless charger ensures your mobile devices stay charged
                    always.
                  </p>
                </div>
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_6} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_7} alt="" className="img-style" />
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>AIR PURIFIER WITH AQI DISPLAY</strong>
                  </h1>
                  <p className="hiddenMobile">
                    For a constant supply of fresh air into the cabin.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>VENTILATED CARNELIAN RED LEATHERETTE SEATS</strong>
                  </h1>
                  <p className="hiddenMobile">
                    Adjust your comfort with venitaltion leather* seats with tri
                    arrow pattern for the front row
                  </p>
                </div>
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_8} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={darkRed_features_9} alt="" className="img-style" />
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                    <strong>ELECTRIC SUNROOF WITH TILT FUNCTION</strong>
                  </h1>
                  <p className="hiddenMobile">
                    The all-new exquisite sunroof to bask your mood
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
