import React from 'react'
import { Link } from 'react-router-dom'

export default function Tigor_ev_Navigation(){
  return <>
  <nav className="navbar navbar-expand-lg text-dark bg-light">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse d-flex justify-content-center" id="navbarNavAltMarkup" style={{rowGap:'10px'}}>
      <div className="navbar-nav text-dark">
      <Link className="nav-link  text-dark" to="/ev/icng_tigor"><b>Icng</b> </Link>
        <Link className="nav-link text-dark" to="/ev/tigor"><b>Tigor EV </b> </Link>
        {/* <Link className="nav-link text-light" to="/tiago_features">Features</Link> */}
      </div>
    </div>
  </div>
</nav>
  </>
}
