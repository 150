import React from "react";
import Tigor_ev_Navigation from "../../Components/Tigor_ev_Navigation";
import icng_tigor_header_1 from "../images/tigor_icng_images/icng_tigor_header_1";
import tigor_icg_features_1 from "../images/tigor_icng_images/tigor_icg_features_1";
import tigor_icg_features_2 from "../images/tigor_icng_images/tigor_icg_features_2";
import tigor_icg_features_3 from "../images/tigor_icng_images/tigor_icg_features_3";
import tigor_icg_features_4 from "../images/tigor_icng_images/tigor_icg_features_4";

export default function Icng_Tigor() {
  return (
    <>
      <Tigor_ev_Navigation />

      <div style={{ backgroundColor: "rgb(0,0,0)", color: "#fff" }}>
        <div className="container-fluid">
          <img src={icng_tigor_header_1} alt="" />
        </div>

        <section className="mt-5">
          <h3 className="mx-5">
            <b>Tigor iCNG Key Features</b>
          </h3>
          <hr className="text-white" />
          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-6 col-sm-3 col-xs-3">
                  <img src={tigor_icg_features_1} alt="" />
                </div>
                <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                   
                    <strong>INCREDIBLE PERFORMANCE</strong>{" "}
                  </h1>
                  <p className="hiddenMobile">
                   
                    Advance iCNG technology assures unmatched performance no
                    matter where road takes you. Quick for city traffic and
                    powered enough to zip through highways during long commutes.
                    It’s a perfect combination of Power and Pickup.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6 ">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                   
                    <strong>ICONIC SAFETY</strong>{" "}
                  </h1>
                  <p className="hiddenMobile">
                    Tigor CNG have been built on a solid platform proven for
                    safety. Use of High strength steel along with reinforced
                    body structure gives rigidity to the car. Both the cars
                    comes with key safety features like Dual Airbags, ABS with
                    EBD and Corner Stability Control as Standard.
                  </p>
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3">
                  <img src={tigor_icg_features_2} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img
                    src={tigor_icg_features_3}
                    alt=""
                    className="img-style"
                  />
                </div>
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                   
                    <strong> INTELLIGENT TECHNOLOGY</strong>{" "}
                  </h1>
                  <p className="hiddenMobile">
                    iCNG’s intelligent technology works effortlessly to ensure a
                    seamless driving experience for you at every step. From
                    ensuring high performance to lower maintenance costs, the
                    smart technology is a delight at every turn.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-6">
              <div className="row">
                <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
                  <h1 className="text-style" style={{ color: "#40b857" }}>
                   
                    <strong>IMPRESSIVE FEATURES</strong>
                  </h1>
                  <p className="hiddenMobile">
                    Make the smarter choice without having to make any of the
                    compromises with the impressively feature-loaded iCNG cars.
                    With India’s first CNG available in the new top trims of
                    Tigor and have multiple trims to choose from.
                  </p>
                </div>
                <div className="col-lg-7 col-sm-3 col-xs-3">
                  <img src={tigor_icg_features_4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
