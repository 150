import React from 'react'
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import tigor_video from '../video/tigor_video'
import tigor_new_facility_video from '../video/tigor_new_facility_video'
import tigor_header_1 from '../images/tigor_images/tigor_header_1'
import tigor_header_2 from '../images/tigor_images/tigor_header_2'
import tigor_features_5 from '../images/tigor_images/tigor_features_5'
import tigor_features_4 from '../images/tigor_images/tigor_features_4'
import tigor_features_3 from '../images/tigor_images/tigor_features_3'
import tigor_features_2 from '../images/tigor_images/tigor_features_2'
import tigor_features_1 from '../images/tigor_images/tigor_features_1'

import tigor_white from '../images/tigor_images/tigor_white'
import tigor_red from '../images/tigor_images/tigor_red'
import tigor_grey from '../images/tigor_images/tigor_grey'
import tigor_blue from '../images/tigor_images/tigor_blue'
import Tigor_ev_Navigation from '../../Components/Tigor_ev_Navigation';


export default function Tigor() {
  return <>
  <Tigor_ev_Navigation/>
   <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
      <section>
  <div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={tigor_header_1}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={tigor_header_2} className="d-block w-100" alt="tiago_header_1"/>
    </div>
  
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>



</section>
{/* video section */}

{/* <div className="row">
 <h1 className='text-light text-style' ><strong>KEY FEATURES</strong> </h1>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={tigor_video}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div> */}
    
{/* highlights */}
<section>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={tigor_features_1} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9f2d47'}}> <strong>COMPREHENSIVE SAFETY</strong> </h1>
    <p className='hiddenMobile'> The new Tigor offers comprehensive safety with best in segment* Global NCAP 4-star rating – It ensures that all occupants are safe and stress free , no matter what the road throws at them.</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9f2d47'}}> <strong> IMPACT DESIGN</strong> </h1>
<p className='hiddenMobile'>The new Tigor exhibits a confident, understated and executive-oriented design that comes from the IMPACT 2.0 Design language . Exteriors feature a bold , confident and sharp look , while the interiors exude premiumness and comfort.
             </p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={tigor_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={tigor_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9f2d47'}}> <strong> CLASS LEADING INFOTAINMENT</strong> </h1>
    <p className='hiddenMobile'>The new Tigor offers a complete infotainment experience with its Harman 17.78cm touchscreen infotainment and 4 speakers and 4 tweeter system with Android AutoTM and Apple CarPlayTM connectivity and a surround sound like audio experience.
              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9f2d47'}}> <strong>DYNAMIC PERFORMANCE</strong> </h1>
<p className='hiddenMobile'>Powered by the refined 1.2L BS6 PH2 Revtron Petrol & iCNG Engine  with 2 transmission options - 5 speed manual or 5 speed AMT for an engaging driving experience . The dual path suspension system ensures a smooth and comfortable drive even in bad road conditions.</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={tigor_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={tigor_features_5} alt="" />
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9f2d47'}}> <strong>CUSTOMER DELIGHT</strong> </h1>
    <p className='hiddenMobile'>Packed with features to make every drive an absolute delight like a wide and comfortable rear seat , height adjustable driver seat , push button start stop and electrically adjustable mirrors.</p>
    </div> 
</div>
 </div>
 </div>
 </section>

 <section>{/* colors */}
  <div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-white'><b>COLORS</b> </h1>
    <hr className='bg-white' />

    <br />
     
      <Tabs>
   
    <TabPanel>
      <div className='col-lg-12'><img src={tigor_blue} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={tigor_grey} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={tigor_red} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={tigor_white} alt="" /></div>
    </TabPanel>
   
<div className="row">
    <TabList>
      <Tab>     
            
      <img src={tigor_blue} alt=""height="250px" width="250px" />
</Tab>
      <Tab>     

         <img src={tigor_grey} alt="" height="250px" width="250px" />
</Tab>
      <Tab>     

         <img src={tigor_red} alt="" height="250px" width="250px"/>
</Tab>
      <Tab>     

         <img src={tigor_white} alt=""height="250px" width="250px" />
</Tab>
      
    </TabList>
    </div>
  </Tabs>




 
  
  </div>

<div className="container hiddenDesktop">
  <h1 className='text-white'>Colors</h1>
  <hr />
<div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={tigor_blue}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    
    <div className="carousel-item">
      <img src={tigor_grey} className="d-block w-100" alt="..."/>
    </div>
   
    <div className="carousel-item">
      <img src={tigor_red} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={tigor_white} className="d-block w-100" alt="..."/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
  {/* colors end */}
  </section>


  {/* <section>
    <div className="container">
    <h1 className='text-style text-white'><b>What's New ?</b></h1>
    <hr className='bg-white'/>
    <div className="row">
      <div className="col-lg-10 col-sm-6 col-xs-6">
        <video src={tigor_new_facility_video} className="tiago_video" style={{border:'2px solid white'}} controls allowFullScreen></video>
      </div>
    </div>
    </div>
  </section> */}
  </div>

  </>
}
