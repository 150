import React from 'react'
import NexonEv_Navigation from '../../Components/NexonEv_Navigation'

export default function NexonEv_DarkEdition() {
  return <>
  <NexonEv_Navigation/>
<div style={{backgroundColor:"#202a33", color:'white'}}>
  <div className="container-fluid">
    <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/daskbanner-dark-edition-2july.jpg" alt="" />
  </div>

  <section>
    <div className="container mt-5">
      <h1 className='text-white text-center'>EXTERIORS</h1>
      <div className="row">
        <div className="col-lg-10 offset-1">
        <div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-indicators" autoPlay  style={{ color:'#00adba'}}>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1" ></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-Complete-Exterior-Look-banner1.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-Complete-Exterior-Look-banner2.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-Complete-Exterior-Look-banner3.jpg" class="d-block w-100" alt="..."/>
    </div>
  </div>
 
</div>
        </div>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-lg-4 offset-1 p-5 text-white">
        <h1  style={{ color:'#00adba'}}><b>#Dark Themed
Satin Black
Beltline
</b></h1>
<p >A powerful stroke that complements
the Nexon EV PRIME’s #Dark demeanor</p>
      </div>
      <div className="col-lg-6">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/dask-dark-themed-hockey-stick.jpg" alt="" />
      </div>
    </div>

    <div className="row mt-5">
    
      <div className="col-lg-6 offset-1">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/dask-dark-themed-hockey-stick.jpg" alt="" />
      </div>
      <div className="col-lg-4 offset-1 p-5 text-white">
        <h1  style={{ color:'#00adba'}}><b>Satin Black
Humanity Line
</b></h1>
<p >A humanity line set in the deepest satin black
as an ode to the Tata Nexon EV PRIME
#Dark edition</p>
      </div>
    </div>


    <div className="row mt-5 p-5">
      <div className="col-lg-6">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-eye-catching-front-skid-plate.jpg" alt="" />
    <div >
     <h1  style={{ color:'#00adba'}}>Eye-Catching Satin Black
</h1><h1  style={{ color:'#00adba'}}>Front Skid Plate</h1> 
<p>A satin black front skid plate created to unleash
  <br />
the power of the #Dark edition

</p>
</div>
      </div>
      <div className="col-lg-6">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-premium-alloy-wheels.jpg" alt="" />
        <h1  style={{ color:'#00adba'}}>Premium

</h1><h1  style={{ color:'#00adba'}}>Alloy Wheels
</h1> 
<p>An emboldened look fueled by <br /> charcoal
grey alloy wheels</p>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-lg-4 p-5 mx-3 mt-3">
<h1  style={{ color:'#00adba'}}>Embossed
#Dark Badge</h1>
<p>A stamp of allegiance to the #Dark edition

</p>
      </div>
<div className="col-lg-7">
  <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-embossed-dark-badge.jpg" alt="" />
</div>

    </div>

    <div className="row mt-5 p-5">
      <div className="col-lg-7">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-piano-black-xfactor.jpg" alt="" />
      
    
      </div>
      <div className="col-lg-4 mt-3 p-5">
      <h1 style={{ color:'#00adba'}}>Metallic Black
X-Factor</h1>
<p>A sturdy, metallic black panel that adds to
the Nexon EV PRIME’s #Dark aura</p>
      </div>
    </div>

    <div className="row mt-5">
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-all-new-dark-interiors.jpg" alt="" />
    </div>

<h1 className='text-center mt-3' style={{ color:'#00adba'}}>All New Dark Interiors</h1>
<p className='text-center'>Sink into the all-new dark themed interiors of the
Nexon EV PRIME’s #Dark edition

</p>
    <div className="row mt-5 p-5">
      <div className="col-lg-6">
        <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-dark-themed-arm-rest.jpg" alt="" />
      </div>
      <div className="col-lg-6 p-5">
        <h1 style={{ color:'#00adba'}}>#Dark Themed
Central Armrest</h1>
<p>A bold, black central armrest that adds to
your comfort while capturing the
spirit of the #Dark edition

</p>
      </div>
    </div>

    <div className="row mt-5 p-5">
<div className="col-lg-6">
  <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-innovative-instrument-panel.jpg" alt="" />
<h2 style={{ color:'#00adba'}}>Innovative
Instrument Panel</h2>
<p>A sensational instrument panel in the deepest
black to complement the Nexon EV PRIME’s interiors

</p>
</div>
<div className="col-lg-6">
  <img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition/desk-elegant-leatherette-seats.jpg" alt="" />
<h2 style={{ color:'#00adba'}}>Elegant Leatherette Seats
With Tri-Arrow Perforations</h2>
<p>The ultimate embodiment of the #Dark theme captured in the
finest quality leatherette seats with tri-arrow perforations
</p>
</div>
    </div>

    

  </section>

  </div>
  </>
}
