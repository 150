import React from 'react'
import altroz_header_3 from '../images/altroz_images/altroz_header_3'
import altroz_header_2 from '../images/altroz_images/altroz_header_2'
import altroz_header_1 from '../images/altroz_images/altroz_header_1'

import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import altroz_features_video_1 from '../video/altroz_features_video_1'
import altroz_brochure_video from '../video/altroz_brochure_video'

import altroz_features_1 from '../images/altroz_images/altroz_features_1'
import altroz_features_2 from '../images/altroz_images/altroz_features_2'
import altroz_features_3 from '../images/altroz_images/altroz_features_3'
import altroz_features_4 from '../images/altroz_images/altroz_features_4'
import altroz_features_5 from '../images/altroz_images/altroz_features_5'

import altroz_dark from '../images/altroz_images/altroz_dark'
import altroz_blue from '../images/altroz_images/altroz_blue'
import altroz_gold from '../images/altroz_images/altroz_gold'
import altroz_grey from '../images/altroz_images/altroz_grey'
import altroz_red from '../images/altroz_images/altroz_red'
import altroz_sky from '../images/altroz_images/altroz_sky'
import altroz_white from '../images/altroz_images/altroz_white'
 
export default function Altroz() {
  return <>
   <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
      <section>
  <div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
     
      <img src={altroz_header_1}
       class="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div class="carousel-item">
      <img src={altroz_header_2} class="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div class="carousel-item">
      <img src={altroz_header_3} class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>



</section>

{/* video section */}

       {/* <div className="row">
 <h1 className='text-light text-style' ><strong>KEY FEATURES</strong> </h1>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={altroz_features_video_1}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div> */}
    

{/* highlights */}
<section>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={altroz_features_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e2bd4c'}}> <strong>GOLD STANDARD OF SAFETY</strong> </h1>
    <p className='hiddenMobile'> ALTROZ has been designed to offer world-class protection to its occupants. Equipped with ALFA Architecture and a comprehensive safety system ALTROZ has achieved 5-star Global NCAP rating.</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#e2bd4c'}}> <strong> GOLD STANDARD OF SAFETY</strong> </h1>
<p className='hiddenMobile'>Inspired by the world of laser carving, the exteriors of ALTROZ exemplify <br />
                athletic sharpness. Design so sharp, it cuts through the air like a hot knife through butter.
             </p>
      </div>
      <div className="col-lg-5 col-sm-3 col-xs-3">
      <img src={altroz_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={altroz_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e2bd4c'}}> <strong> GOLD STANDARD OF TECHNOLOGY</strong> </h1>
    <p className='hiddenMobile'>Infotainment system by Harman <sup>TM</sup> equipped with an advanced 17.78cm touchscreen interface, unmatched acoustics system and iRA leaves no stone unturned.
                iRA is a cool new connected tech platform offering a range of innovative features developed in India for India.
              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#e2bd4c'}}> <strong>GOLD STANDARD OF DRIVING DYNAMICS</strong> </h1>
<p className='hiddenMobile'>Experience the exhilarating performance of ALTROZ with powerful BS6 petrol and diesel engines, fine-tuned suspension and comprehensive control and to conquer every city road. Set ablaze every trail with an impressive new 1.2L i-Turbo engine, and give your drive the added boost you seek.</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={altroz_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={altroz_features_5} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e2bd4c'}}> <strong>GOLD STANDARD OF CUSTOMER DELIGHT</strong> </h1>
    <p className='hiddenMobile'> Step inside and be amazed by the modern, intelligent and tastefully crafted interiors. Experience every mile in the luxurious comfort of premium leatherette seats, rear AC vents, rear flat floor design, 90° opening doors and personalized wallpapers all that makes you wish your drive lasts longer.</p>
    </div> 
</div>
 </div>
 </div>
 </section>

{/* colors */}
  <div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-white'><b>COLORS</b> </h1>
    <hr className='bg-white' />

    <br />
     
      <Tabs>
   
    <TabPanel>
      <div className='col-lg-12'><img src={altroz_dark} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={altroz_gold} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={altroz_white} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={altroz_red} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={altroz_blue} alt="" />
</div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={altroz_grey} alt="" />
</div>
    </TabPanel>

<div className="row">
    <TabList>
      <Tab>     
            
      <img src={altroz_dark} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={altroz_gold} alt="" height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={altroz_white} alt="" height="180px" width="180px"/>
</Tab>
      <Tab>     

         <img src={altroz_red} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={altroz_blue} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={altroz_grey} alt=""height="180px" width="180px" />
</Tab>
    </TabList>
    </div>
  </Tabs>




 
  
  </div>

<div className="container hiddenDesktop">
  <h1 className='text-white'>Colors</h1>
  <hr />
<div id="carouselExampleIndicators" class="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={altroz_dark}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={altroz_blue} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div className="carousel-item">
      <img src={altroz_gold} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={altroz_grey} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={altroz_white} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={altroz_red} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={altroz_sky} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
  {/* colors end */}

  <section>
    <div className="container">
    <h1 className='text-style text-white'><b>What's New ?</b></h1>
    <hr className='bg-white'/>
    <div className="row">
      <div className="col-lg-10 col-sm-6 col-xs-6">
        <video src={altroz_brochure_video} style={{border:'2px solid white'}} controls allowFullScreen></video>
      </div>
    </div>
    </div>
  </section>
  </div>
  </>
}
