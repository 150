    import React from "react";
    import {Link} from 'react-router-dom'
    import tata_logo from "../pages/images/tata_logo";
    import mutha from '../pages/images/mutha.png'
    // import sampoorna_seva_logo from "../pages/images/sampoorna_seva_logo";

    export default function Footer() {
      return (
        <>

        <div className=""style={{backgroundColor:'#002366 '}}>
          <footer className="footer_area red_footer_area budget_footer" style={{backgroundColor:'#002366 '}}>
            <div className="footer_widgets">
              <div className="elementor elementor-102">
                <div className="elementor-inner">
                  <div className="elementor-section-wrap">
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-1e8f680a elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="1e8f680a"
                      data-element_type="section"
                      data-settings='{"background_background":"classNameic"}'
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div className="elementor-row">
                          <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-43d2393b">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-e3051dc elementor-widget elementor-widget-karde_footer_contact">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget footer_logo_contact about_widget wow animated fadeInUp"
                                      data-wow-delay="0.2s"
                                    >
                                    
                                    
      {/* <h5 className='logo-text-footer'>MUTHA MOTORS</h5> */}
      <div className="margin-manage-footer">
                                      <Link  to="/">
                                        <h5>
      <img src={mutha} width="100" height="100" alt="" className="img-footer-logo" />
                                         
          <span className='text-white  logo-text-footer'><b>MUTHA MOTORS</b> </span>
          </h5>
          <span className="logo-tagline font-style text-danger"> We belive in humanity</span>
            
          </Link>
          </div>
          <br />
                                      {/* <p className="text-para">
                                      Our Dealership Network has a <br /> strong presence in places such as <br /> Jalna, Ch.Sambhajinagar, Buldhana, <br /> and Khamgaon in the state of Maharashtra.
                                      </p> */}

                                      {/* <a
                                        href="#"
                                        target="_blank"
                                        rel="nofollow"
                                      >
                                        <i
                                          aria-hidden="true"
                                          className="bi bi-envelope"
                                        ></i>
                                        <span>
                                          <span
                                            className="__cf_email__"
                                            data-cfemail="3253415972574a535f425e571c515d5f"
                                          >
                                          muthamotors@gmail.com
                                          </span>
                                        </span>
                                      </a> */}

                                      {/* <a
                                        href="tel:0951856558"
                                        target="_blank"
                                        rel="nofollow"
                                      >
                                        <i
                                          aria-hidden="true"
                                          className="bi bi-telephone-fill"
                                        ></i>
                                        999999999 (Toll Free)
                                      </a> */}
                                    </aside>
                                  </div>
{/* social media icons */}
                                    <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget subscribe_widget wow animated fadeInUp"
                                      data-wow-delay="0.8s"
                                    >
                                      {/* <div className="f_title">
                                        <h3>Subscribe us</h3>
                                      </div> */}
                                      
                                      <div className="wpcf7">
                                        <div className="screen-reader-response">
                                          <p></p> <ul></ul>
                                        </div>
                                        <form
                                          action="#"
                                          method="post"
                                          className="#"
                                          noValidate="noValidate"
                                          data-status="init"
                                        >
                                          <div>
                                            <input
                                              type="hidden"
                                              name="_wpcf7"
                                              value="453"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_version"
                                              value="5.5.5"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_locale"
                                              value="en_US"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_unit_tag"
                                              value="wpcf7-f453-o1"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_container_post"
                                              value="0"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_posted_data_hash"
                                              value=""
                                            />
                                          </div>
                                          <div className="input-group">
                                            {/* <span className="wpcf7-form-control-wrap email">
                                              <input
                                                type="text"
                                                name="email"
                                                value=""
                                                size="40"
                                                className="wpcf7-form-control wpcf7-text form-control"
                                                aria-invalid="false"
                                                placeholder="Your Email"
                                              />
                                            </span> */}
                                            {/* <div className="input-group-append">
                                              <button className="wpcf7-form-control wpcf7-submit btn btn-outline-secondary red">
                                                <i className="bi bi-envelope"></i>
                                              </button>
                                            </div> */}
                                          </div>
                                        </form>
                                      </div>
                                      <ul className="nav">
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-facebook"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className=" bi bi-youtube"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-twitter"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-google"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-whatsapp"
                                            ></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-497ba5f5">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-878a8eb elementor-widget elementor-widget-karde_footer_linklist">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget link_widget wow animated fadeInUp"
                                      data-wow-delay="0.4s"
                                    >
                                      <div className="f_title">
                                        <h3>Quick Links</h3>
                                      </div>
                                      <ul className="nav flex-column">
                                        <li>
                                          <Link
                                            to="/about"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            About Us
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/service"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Our Service
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/sale"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                           Sales
                                          </Link>
                                        </li>
                                        {/* <li>
                                          <a
                                            href="https://smartdatawp.com/motodeal/budget/news/"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Blog
                                          </a>
                                        </li> */}
                                        <li>
                                          <Link
                                            to="/contact"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Contact
                                          </Link>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-35f5ce79">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-44ee1b07 elementor-widget elementor-widget-karde_footer_linklist">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget link_widget wow animated fadeInUp"
                                      data-wow-delay="0.6s"
                                    >
                                      <div className="f_title">
                                        <h3>Our Brands</h3>
                                      </div>
                                      <ul className="nav flex-column">
                                     
                                        <li>
                                          <Link
                                            to="/harrier"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Harrier
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/safari"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Safari
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/nexon"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                         Tata Nexon
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/punch"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                           Tata Punch
                                          </Link>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                       
                          <div className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-43c4f625">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-43f1bdad elementor-widget elementor-widget-karde_footer_subscribe">
                                  
                                <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget link_widget wow animated fadeInUp"
                                      data-wow-delay="0.6s"
                                    >
                                      <div className="f_title">
                                        <h3>Our Locations</h3>
                                      </div>
                                      <ul className="nav flex-column">
                                       
                                        <li>
                                          <a
                                            href="https://www.google.com/maps/dir/19.8748734,75.3761947/R8XW%2B9G8+MUTHA+MOTORS,+Gopi+Nath+Pura,+Satara+Deolai+Parisar,+Aurangabad,+Maharashtra+431005/@19.8623813,75.3340877,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bdba3974a4356c3:0xfcfba4eb70fc7cb2!2m2!1d75.3463192!2d19.8484111"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Chh.Sambhajinagar
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://www.google.com/maps/dir/19.874865,75.377049/TATA+MOTORS+LIMITED+MUTHA+MOTORS+JALNA,+Aditya+nagar,+Chandanzira,+Jalna,+Maharashtra+431203/@19.8870144,75.1443048,9.5z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bda51dd332f304b:0xa7ae2289377481a1!2m2!1d75.8563004!2d19.8589784"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Jalna
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://www.google.com/maps/dir/19.8761653,75.3433139/mutha+motors++Dipak+Nagar,+Buldhana+Road,+Malkapur,+buldhana+-+443101/@20.380068,75.2174967,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bd9e91b5bb8f013:0xd2695479c6986a71!2m2!1d76.1934365!2d20.8725101"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                         Malkapur
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                           Khamgaon
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://www.google.com/maps/dir//mutha+motors+buldhana+Khetan+Square+Shegaon+Buldhana+-+443001.+Landmark.+Opposite+Shegaon+Urban+Bank./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bda1e077e700edb:0x14e04802a6c02141?sa=X&ved=2ahUKEwjBsoDnytP9AhUwIbcAHTa3DHEQ9Rd6BAhREAQ"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                           Buldhana
                                          </a>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div>
                                  {/* <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget subscribe_widget wow animated fadeInUp"
                                      data-wow-delay="0.8s"
                                    >
                                    
                                      
                                      <div className="wpcf7">
                                        <div className="screen-reader-response">
                                          <p></p> <ul></ul>
                                        </div>
                                        <form
                                          action="#"
                                          method="post"
                                          className="#"
                                          noValidate="noValidate"
                                          data-status="init"
                                        >
                                          <div>
                                            <input
                                              type="hidden"
                                              name="_wpcf7"
                                              value="453"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_version"
                                              value="5.5.5"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_locale"
                                              value="en_US"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_unit_tag"
                                              value="wpcf7-f453-o1"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_container_post"
                                              value="0"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_posted_data_hash"
                                              value=""
                                            />
                                          </div>
                                          <div className="input-group">
                                           
                                          </div>
                                        </form>
                                      </div>
                                      <ul className="nav">
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-facebook"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className=" bi bi-youtube"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-twitter"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-google"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-whatsapp"
                                            ></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-5672c67 elementor-hidden-desktop elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="5672c67"
                      data-element_type="section"
                    >
                      {/* city */}
                      <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4376dfb">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-6d80082 elementor-widget elementor-widget-karde_footer_contact">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget footer_logo_contact about_widget wow animated fadeInUp"
                                      data-wow-delay="0.2s"
                                    >
                                      <Link to="/">
                                        <img
                                          src={tata_logo}
                                          title="general footer logo"
                                          alt="general footer logo"
                                        />
                                      </Link>
                                      <p>
                                        <i
                                          aria-hidden="true"
                                          className="bi bi-geo-alt-fill"
                                        ></i>
                                        Aurangabad,Maharashtra
                                      </p>

                                      <a
                                        href="/cdn-cgi/l/email-protection#ccadbfa78ca9b4ada1bca0a9e2afa3a1"
                                        target="_blank"
                                        rel="nofollow"
                                      >
                                        <i
                                          aria-hidden="true"
                                          className="bi bi-envelope"
                                        ></i>
                                        <span>
                                          <span
                                            className="__cf_email__"
                                            data-cfemail="#"
                                          >
                                            muthamotors@gmail.com
                                          </span>
                                        </span>
                                      </a>

                                      <a
                                        href="tel:9999999999"
                                        target="_blank"
                                        rel="nofollow"
                                      >
                                        <i
                                          aria-hidden="true"
                                          className="bi bi-telephone-fill"
                                        ></i>
                                        999999999 (Toll Free)
                                      </a>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-08eb403">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-7fab28b elementor-widget elementor-widget-karde_footer_linklist">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget link_widget wow animated fadeInUp"
                                      data-wow-delay="0.4s"
                                    >
                                      <div className="f_title">
                                        <h3>Our Info</h3>
                                      </div>
                                      <ul className="nav flex-column">
                                        <li>
                                          <Link
                                            to="/about"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            About Us
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/service"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Our Service
                                          </Link>
                                        </li>
                                       
                                        <li>
                                          <Link
                                            to="/contact"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Contact
                                          </Link>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-75208e8 elementor-hidden-desktop elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="75208e8"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-default">
                        <div className="elementor-row">
                          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b373e77">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-cf4c75a elementor-widget elementor-widget-karde_footer_linklist">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget link_widget wow animated fadeInUp"
                                      data-wow-delay="0.6s"
                                    >
                                      <div className="f_title">
                                        <h3>Quick Link</h3>
                                      </div>
                                      <ul className="nav flex-column">
                                        <li>
                                          <Link
                                            to="/harrier"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Harrier
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/safari"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Safari
                                          </Link>
                                        </li>
                                        
                                        <li>
                                          <Link
                                            to="/nexon"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Nexon
                                          </Link>
                                        </li>
                                      
                                        <li>
                                          <Link
                                            to="/tigor"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Tigor
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            to="/tiago"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            Tata Tiago
                                          </Link>
                                        </li>
                                      
                                      </ul>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1d759db">
                            <div className="elementor-column-wrap elementor-element-populated">
                              <div className="elementor-widget-wrap">
                                <div className="elementor-element elementor-element-7c7e72e elementor-widget elementor-widget-karde_footer_subscribe">
                                  <div className="elementor-widget-container">
                                    <aside
                                      className="f_widget subscribe_widget wow animated fadeInUp"
                                      data-wow-delay="0.8s"
                                    >
                                      <div className="f_title">
                                        <h3>Subscribe us</h3>
                                      </div>
                                      <div className="wpcf7">
                                        <div className="screen-reader-response">
                                          <p></p> <ul></ul>
                                        </div>
                                        <form
                                          action="/motodeal/budget/#wpcf7-f453-o2"
                                          method="post"
                                          className="wpcf7-form init"
                                          noValidate="noValidate"
                                          data-status="init"
                                        >
                                          <div>
                                            <input
                                              type="hidden"
                                              name="_wpcf7"
                                              value="453"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_version"
                                              value="5.5.5"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_locale"
                                              value="en_US"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_unit_tag"
                                              value="wpcf7-f453-o2"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_container_post"
                                              value="0"
                                            />
                                            <input
                                              type="hidden"
                                              name="_wpcf7_posted_data_hash"
                                              value=""
                                            />
                                          </div>
                                          <div className="input-group">
                                            <span className="wpcf7-form-control-wrap email">
                                              <input
                                                type="text"
                                                name="email"
                                                value=""
                                                size="40"
                                                className="wpcf7-form-control wpcf7-text form-control"
                                                aria-invalid="false"
                                                placeholder="Your Email"
                                              />
                                            </span>
                                            <div className="input-group-append">
                                              <button className="wpcf7-form-control wpcf7-submit btn btn-outline-secondary red">
                                                <i className="bi bi-envelope"></i>
                                              </button>
                                            </div>
                                          </div>
                                          <div className="wpcf7-response-output"></div>
                                        </form>
                                      </div>
                                      <ul className="nav">
                                        <li>
                                          <a
                                            href="https://www.facebook.com/"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-facebook"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://www.youtube.com/"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className=" bi bi-youtube"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://twitter.com/?lang=en"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-twitter"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="https://www.google.com/"
                                            target="_blank"
                                            rel="nofollow"
                                          >
                                            <i
                                              aria-hidden="true"
                                              className="bi bi-google"
                                            ></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </aside>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_copyright">
              <div className="container">
                <div className="copyright_inner d-flex justify-content-between">
                  <div className="left">
                    <p>
                      Copyright Reserved by
                      <a href="https://topnotchmediaworks.com/">💖  Topnotch mediaworks </a>
                    </p>
                  </div>
                  {/* <div className="right">
                    <ul className="nav">
                      <li>
                        <a href="#">Terms of use</a>
                      </li>
                      <li>
                        <a href="#">Privacy Environmental</a>
                      </li>
                      <li>
                        <a href="#">Policy</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </footer>
          </div>
        
        </>
      );
    }
