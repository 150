import React from 'react'
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import nexon_dark_header from '../ev/ev_images/nexon_dark_images/nexon_dark_header'
import nexon_dark_header_2 from '../ev/ev_images/nexon_dark_images/nexon_dark_header_2'
import nexon_dark_features_1 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_1'
import nexon_dark_features_2 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_2'
import nexon_dark_features_3 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_3'
import nexon_dark_features_4 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_4'
import nexon_dark_features_5 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_5'
import nexon_dark_features_6 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_6'
import nexon_dark_features_7 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_7'
import nexon_dark_features_8 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_8'
import nexon_dark_features_9 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_9'
import nexon_dark_features_10 from '../ev/ev_images/nexon_dark_images/nexon_dark_features_10' 
import nexon_dark_gallary_1 from '../ev/ev_images/nexon_dark_images/nexon_dark_gallary_1' 
import nexon_dark_gallary_2 from '../ev/ev_images/nexon_dark_images/nexon_dark_gallary_2' 
import nexon_dark_gallary_3 from '../ev/ev_images/nexon_dark_images/nexon_dark_gallary_3' 
import nexon_dark_gallary_4 from '../ev/ev_images/nexon_dark_images/nexon_dark_gallary_4' 
import nexon_dark_gallary_5 from '../ev/ev_images/nexon_dark_images/nexon_dark_gallary_5' 
import Navigation_ev_nexon from '../../Components/Navigation_ev_nexon';

export default function Nexon_dark() {
  return <>
  <Navigation_ev_nexon/>
  <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>

  <div className="container-fluid">
    <div className="col-lg-12 col-sm-6 col-xs-6">
        <img src={nexon_dark_header} alt="" />
    </div>
</div>

<section>
  {/* highlights */}
<Tabs className="mt-5">
    <TabList>
      <Tab><button type="button" class="btn btn-primary mx-3" >Features</button>
</Tab>
      <Tab><button type="button" class="btn btn-primary">Gallary</button>
</Tab>
    </TabList>

    <TabPanel>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_dark_features_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong> ATLAS BLACK EXTERIOR COLOUR
</strong> </h1>
    <p className='hiddenMobile'>Unleash your Dark Side with Nexon #Dark.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong> SONIC SILVER BELT LINE
</strong> </h1>
<p className='hiddenMobile'>Perfectly compliments the contrast Dark Exterior.

</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={nexon_dark_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_dark_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong>SPECIAL #DARK MASCOT
</strong> </h1>
    <p className='hiddenMobile'>Flaunt your Dark Side.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong>R16 CHARCOAL ALLOYS
</strong> </h1>
<p className='hiddenMobile'> The bold New R16 Charcoal Alloy Wheels compliment the roads and your style.

</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={nexon_dark_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_dark_features_6} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong> DARK INTERIOR PACK
</strong> </h1>
    <p className='hiddenMobile'>It's not just a cabin. It's an extension of your attitude.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong>BLACK LEATHERETTE SEATS WITH TRI ARROW PERFORATIONS

</strong> </h1>
<p className='hiddenMobile'> Enter into the world of sleek black upholstery and everything dark and dominating. Just like you.

.

</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={nexon_dark_features_6} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_dark_features_7} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong> #DARK EMBROIDERY ON HEADREST

</strong> </h1>
    <p className='hiddenMobile'>Signature embroidery on Headrest to separate the Dark from the rest.



</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong>PREMIUM BLACK TRI ARROW DASHBOARD PANEL

</strong> </h1>
<p className='hiddenMobile'> The All Black Tri-Arrow Dashboard Panel gives out an aura that one can only dream of. Bold, gutsy, brilliant. And most of all, it's dark and it rules.



</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={nexon_dark_features_8} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_dark_features_9} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#9359B5'}}> <strong>DOOR TRIM WITH TRI-ARROW PERFORATIONS


</strong> </h1>
    <p className='hiddenMobile'>Premium Tri Arrow theme door trims for added style & attitude.


</p>
    </div> 
</div>
 </div>
 </div>
 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#9359B5'}}> <strong>PUNCTURE REPAIR KIT


</strong> </h1>
<p className='hiddenMobile'> Nothing should stop you from moving ahead.


</p>
      </div>
      <div className="col-lg-6 col-sm-3 col-xs-3">
      <img src={nexon_dark_features_10} alt="" />

      </div>
    </div>
  </div>
 </div>

    </TabPanel>

    <TabPanel>
    <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_dark_gallary_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <div className="row">
      <div className="col-lg-6 col-sm-6 col-xs-6">
      <img src={nexon_dark_gallary_2} alt="" />

      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6">
      <img src={nexon_dark_gallary_3} alt="" />
      </div>
      </div>
      <div className="row">
      <div className="col-lg-6 col-sm-6 col-xs-6">
      <img src={nexon_dark_gallary_4} alt="" />
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6">
      <img src={nexon_dark_gallary_5} alt="" />
      </div>
      </div>
    </div>
    </div> 
</div>
 </div>

 <div className="row mt-3 mx-5">
  <h3>Build your Own Nexon</h3>

 </div>
 <hr  className='text-light'/>

<div className="row">
  <img src={nexon_dark_header_2} alt="" />
</div>
 

 

 
    </TabPanel>
  </Tabs>
</section>
  </div>
  </>
}
