import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function Careers() {
  return <>
  <section  style={{backgroundImage:" linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)"}}>
    <div className="container">
      <div className="row">
        <h1 className='bg-header-manage text-center p-5'><b>Careers</b></h1>
      </div>
    </div>
  </section>

  <section style={{backgroundColor:"#f7f9fb"}}>
<div className="container">
  <div className="row ">
<div className="col-lg-6 col-md-6 col-sm-6 mt-5">

          <div class="card">
            <h4 class="card-header text-center text-light bg-dark">Job Enquiry</h4>
            <div class="card-body">
              <div>
                <label for="name" class="form-label">Full name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter your Full Name*"
                />
                <div class="valid-feedback">Looks good!</div>
                <div class="invalid-feedback">Please choose a username.</div>
              </div>
              <div class="mt-2">
                <label for="email" class="form-label"> Email Id</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Enter Your Email*"
                />
                <div class="valid-feedback">Looks good!</div>
                <div class="invalid-feedback">Please choose a username.</div>
              </div>
              <div class="mt-2">
                <label for="mobile" class="form-label">Mobile Number</label>
                <input
                  type="number"
                  class="form-control"
                  id="mobile"
                  placeholder="Enter Your Mobile Number*"
                />
                <div class="valid-feedback">Looks good!</div>
                <div class="invalid-feedback">Please choose a password.</div>
              </div>
              <div class="mt-2">
                <label for="experience" class="form-label"
                  >Experience In Years</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="number"
                  placeholder="Experience In Years*"
                />
               
              </div>
              <div class="mt-2">
                <label for="company" class="form-label"
                  >Current Company</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="company"
                  placeholder="Company Name*"
                />
               
              </div>
             
              <div class="mt-2">
                <label for="company" class="form-label"
                  >Current Location</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="Location"
                  placeholder="Current Location*"
                />
               
              </div>
              <div class="mt-2">
                <label for="company" class="form-label"
                  >Notice Period in Months</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="Location"
                  placeholder="Notice Period in Months*"
                />
               
              </div>
              <div class="mt-2">
                <label for="company" class="form-label"
                  >Upload CV</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="Location"
                  placeholder="Upload your CV*"
                />
               
              </div>
              <button type="button" class="btn btn-dark text-center w-50 mt-3 offset-2">
                Submit
              </button>
              {/* <p class="text-center mt-3">
                Already Have Account? <a href="#">Login</a>
              </p> */}
            </div>
          </div>
      
</div>


    <div className="col-lg-6 col-md-6 col-sm-6 mt-5">
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion>
    </div>
    </div>
  </div>
</div>

 
  </section>
  
  </>
}
