import React from 'react'
import 'react-tabs/style/react-tabs.css';
// import '../../App.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import nexon_video_1 from '../video/nexon_video_1'
import nexon_features_1 from '../images/nexon_images/nexon_features_1'
import nexon_features_2 from '../images/nexon_images/nexon_features_2'
import nexon_features_3 from '../images/nexon_images/nexon_features_3'
import nexon_features_4 from '../images/nexon_images/nexon_features_4'
import nexon_features_5 from '../images/nexon_images/nexon_features_5'

import nexon_dark from '../images/nexon_images/nexon_dark'
import nexon_red from '../images/nexon_images/nexon_red'
import nexon_green from '../images/nexon_images/nexon_green'
import nexon_white from '../images/nexon_images/nexon_white'
import nexon_blue from '../images/nexon_images/nexon_blue'
import nexon_gold from '../images/nexon_images/nexon_gold'

import nexon_header_1 from '../images/nexon_images/nexon_header_1'
import nexon_header_2 from '../images/nexon_images/nexon_header_2'
import nexon_header_3 from '../images/nexon_images/nexon_header_3'
import Navigation_ev_nexon from '../../Components/Navigation_ev_nexon';

export default function Nexon() {
  return <>
  <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>

    <Navigation_ev_nexon/>
      <section>
  <div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={nexon_header_1}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={nexon_header_2} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div className="carousel-item">
      <img src={nexon_header_3} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>



</section>

{/* video section */}

       {/* <div className="row">
 <h1 className='text-light text-style' ><strong>KEY FEATURES</strong> </h1>
   <div className="col-lg-12 col-sm-4 col-xs-6 ">
   <video className="tiago_video" src={nexon_video_1}   controls style={{border:'2px solid white'}}></video>
 </div>
 </div> */}
    

{/* highlights */}
<section>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_features_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#007bff'}}> <strong>GNEX LEVEL SAFETY
</strong> </h1>
    <p className='hiddenMobile'> Nexon’s high-strength steel structure absorbs impact energy and protects the passenger during an unfortunate collision.

</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#007bff'}}> <strong> NEX LEVEL DESIGN
</strong> </h1>
<p className='hiddenMobile'>A box breaking SUV design that combines the practicality of an SUV with the style of a sports coupe.


             </p>
      </div>
      <div className="col-lg-5 col-sm-3 col-xs-3">
      <img src={nexon_features_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
    <img src={nexon_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#007bff'}}> <strong> NEX LEVEL TECHNOLOGY
</strong> </h1>
    <p className='hiddenMobile'>Presenting iRA – a cool new connected tech platform that is in sync with India. It offers a range of innovative features that are especially developed keeping in mind the needs of the India car buyer


              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#007bff'}}> <strong>NEX LEVEL PERFORMANCE
</strong> </h1>
<p className='hiddenMobile'>Powered by 120PS Turbocharged engines, 6 speed transmission and three drive modes – ECO, CITY & SPORT, the NEXON provides a drive experience like no other.

</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={nexon_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={nexon_features_5} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#007bff'}}> <strong>NEX LEVEL COMFORT
</strong> </h1>
    <p className='hiddenMobile'> The Nexon is geared with ventilated Leatherette seats in the front to keep you at a comfortable temperature no matter what the weather is outside.

</p>
    </div> 
</div>
 </div>
 </div>
 </section>

{/* colors */}
  <div className="container mt-5 hiddenMobile">
    <h1 className='text-style text-white'><b>COLORS</b> </h1>
    <hr className='bg-white' />

    <br />
     
      <Tabs>
   
    <TabPanel>
      <div className='col-lg-12'><img src={nexon_dark} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={nexon_gold} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src="https://cars.tatamotors.com/images/nexon/color/Desktop_Calgary-White-new.jpg" alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'><img src={nexon_green} alt="" /></div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={nexon_blue} alt="" />
</div>
    </TabPanel>
    <TabPanel>
    <div className='col-lg-12'>         <img src={nexon_red} alt="" />
</div>
    </TabPanel>

<div className="row">
    <TabList>
      <Tab>     
            
      <img src={nexon_dark} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={nexon_gold} alt="" height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={nexon_white} alt="" height="180px" width="180px"/>
</Tab>
      <Tab>     

         <img src={nexon_green} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={nexon_blue} alt=""height="180px" width="180px" />
</Tab>
      <Tab>     

         <img src={nexon_red} alt=""height="180px" width="180px" />
</Tab>
    </TabList>
    </div>
  </Tabs>




 
  
  </div>

<div className="container hiddenDesktop">
  <h1 className='text-white'>Colors</h1>
  <hr />
<div id="carouselExampleIndicators" class="carousel slide">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
     
      <img src={nexon_dark}
       className="d-block w-100" alt="tata_punch_1"/>
        

    </div>
    <div className="carousel-item">
      <img src={nexon_blue} className="d-block w-100" alt="tiago_header_1"/>
    </div>
    <div className="carousel-item">
      <img src={nexon_gold} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={nexon_red} class="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={nexon_white} className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={nexon_green} className="d-block w-100" alt="..."/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
  {/* colors end */}

 
  </div>
  </>
}
