import React from 'react'
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


import tiago_header_1 from '../images/header/tiago_header_1' 
import tiago_1 from '../images/header/tiago_1'

import header_2 from '../images/header/header_2'
import header_3 from '../images/header/header_3'
import Navigation_ev_tiago from '../../Components/Navigation_ev_tiago'

import features_icng_1 from '../images/icng_images_tiago/features_icng_1'
import features_icng_2 from '../images/icng_images_tiago/features_icng_2'
import features_icng_3 from '../images/icng_images_tiago/features_icng_3'
import features_icng_4 from '../images/icng_images_tiago/features_icng_4'

export default function Icng_Tiago() {
  return <>

  <Navigation_ev_tiago/>

  <div style={{backgroundColor:'rgb(0,0,0)', color:'#fff'}}>
  

<Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={header_2}
          alt="First slide"
        />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={header_3}
          alt="Second slide"
        />

        
      </Carousel.Item>
     
    </Carousel>

    <section>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={features_icng_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#40b857'}}> <strong>INCREDIBLE PERFORMANCE

</strong> </h1>
    <p className='hiddenMobile'> Advance iCNG technology assures unmatched performance no matter where road takes you. Quick for city traffic and powered enough to zip through highways during long commutes. It’s a perfect combination of Power and Pickup.


</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6 ">
    <div className="row">
      <div className="col-lg-7 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#40b857'}}> <strong>ICONIC SAFETY

</strong> </h1>
<p className='hiddenMobile'>Tiago and NRG CNG have been built on a solid platform proven for safety. Use of High strength steel along with reinforced body structure gives rigidity to the car. Both the cars comes with key safety features like Dual Airbags, ABS with EBD and Corner Stability Control as Standard.
             </p>
      </div>
      <div className="col-lg-5 col-sm-3 col-xs-3">
      <img src={features_icng_2} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3">
    <img src={features_icng_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#40b857'}}> <strong> INTELLIGENT TECHNOLOGY

</strong> </h1>
    <p className='hiddenMobile'>iCNG’s intelligent technology works effortlessly to ensure a seamless driving experience for you at every step. From ensuring high performance to lower maintenance costs, the smart technology is a delight at every turn.


              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#40b857'}}> <strong>IMPRESSIVE FEATURES

</strong> </h1>
<p className='hiddenMobile'>Make the smarter choice without having to make any of the compromises with the impressively feature-loaded iCNG cars. With India’s first CNG available in the new top trims of Tiago and have multiple trims to choose from.


</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={features_icng_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 
 </section>

</div>
  </>
}
