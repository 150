import React from "react";
import { Link } from "react-router-dom";
import header_1 from "./images/services/header_1";
import header_2 from "./images/services/header_2";
import header_3 from "./images/services/header_3";

import map1 from "./images/map1.png";
import map_3d from "./images/services/map_3d.png";

import speed_img from "./images/services/speed_img";
import online_img from "./images/services/online_img";
import doorstep_img from "./images/services/doorstep_img";
import repair_img from "./images/services/repair_img";
import road_img from "./images/services/road_img";
import quaity_services from "./images/services/quaity_services";

import safari_video from "./video/safari_video";
import safari_slider_car from "./images/services/safari_slider_car";

import electronic_diagnostic_img from "./images/electronic_diagnostic_img";
import tyre_fitting_img from "./images/tyre_fitting_img";
import air_conditioning_img from "./images/air_conditioning_img";
import accident_reparing from "./images/accident_reparing";
import mechanic_repairs from "./images/mechanic_repairs";
import paint_img from "./images/paint_img";
import breaking_repair_img from "./images/breaking_repair_img";
import mobile_mechaninc from "./images/mobile_mechaninc";

import service_lounge_images from "./images/service_lounge_images";
import luxury_images from "./images/luxury_images";
import ev_charging_img_1 from "./images/ev_charging_img_1";
import online_billing from "./images/online_billing";
import visibility_images from "./images/visibility_images";

export default function Service() {
  return (
    <>
      {/* carousel */}
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={header_1} className="d-block w-100" alt="tata_punch_1" />
          </div>
          <div className="carousel-item">
            <img
              src={header_2}
              className="d-block w-100"
              alt="tiago_header_1"
            />
          </div>
          <div className="carousel-item">
            <img src={header_3} className="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <div
        id="post-297"
        className="post-297 page type-page status-publish has-post-thumbnail hentry"
      >
        <div className="entry-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id="297"
            className="elementor elementor-297"
            data-elementor-settings="[]"
          >
            <div className="elementor-inner">
              <div className="elementor-section-wrap">
                {/* our services */}

                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-9574062 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="9574062"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-64927e2"
                        data-id="64927e2"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-30e42b8 elementor-widget elementor-widget-karde_budget_gallery"
                              data-id="30e42b8"
                              data-element_type="widget"
                              data-widget_type="karde_budget_gallery.default"
                            >
                              <div className="elementor-widget-container">
                                <section className="car_budget_area p_100">
                                  <div className="container">
                                    <div
                                      className="flag_center_title wow animated fadeInUp"
                                      data-wow-delay="0.2s"
                                    >
                                      <h2>
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/flag-6.png"
                                          title="flag-6"
                                          alt="flag-6"
                                        />
                                        Our Services
                                      </h2>
                                    </div>
                                    <div className="car_budget_inner">
                                      <ul
                                        className="nav nav-tabs"
                                        id="myTab"
                                        role="tablist"
                                      >
                                        <li className="nav-item">
                                          <a
                                            className="nav-link 
			active	"
                                            style={{ background: "#0478d7" }}
                                            id="micro-tab"
                                            data-toggle="tab"
                                            href="#micro"
                                            role="tab"
                                            aria-controls="micro"
                                            aria-selected="true"
                                          >
                                            Speed-O-Service
                                          </a>
                                        </li>

                                        <li className="nav-item">
                                          <a
                                            className="nav-link 
								"
                                            style={{ background: "#0478d7" }}
                                            id="economy-tab"
                                            data-toggle="tab"
                                            href="#economy"
                                            role="tab"
                                            aria-controls="economy"
                                            aria-selected="false"
                                          >
                                            Quality Service
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link 
								"
                                            style={{ background: "#0478d7" }}
                                            id="comfort-tab"
                                            data-toggle="tab"
                                            href="#comfort"
                                            role="tab"
                                            aria-controls="comfort"
                                            aria-selected="false"
                                          >
                                            Comfort
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link 
								"
                                            style={{ background: "#0478d7" }}
                                            id="business-tab"
                                            data-toggle="tab"
                                            href="#business"
                                            role="tab"
                                            aria-controls="business"
                                            aria-selected="false"
                                          >
                                            Doorstep Service
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link 
								"
                                            style={{ background: "#0478d7" }}
                                            id="minivan-tab"
                                            data-toggle="tab"
                                            href="#minivan"
                                            role="tab"
                                            aria-controls="minivan"
                                            aria-selected="false"
                                          >
                                            Visibility
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link 
								"
                                            style={{ background: "#0478d7" }}
                                            id="minibus-tab"
                                            data-toggle="tab"
                                            href="#minibus"
                                            role="tab"
                                            aria-controls="minibus"
                                            aria-selected="false"
                                          >
                                            24/7 service
                                          </a>
                                        </li>
                                      </ul>
                                      <div
                                        className="tab-content"
                                        id="myTabContent"
                                      >
                                        <div
                                          className="tab-pane fade 
			show active				"
                                          id="micro"
                                          role="tabpanel"
                                          aria-labelledby="micro-tab"
                                        >
                                          <div
                                            data-elementor-type="section"
                                            data-elementor-id="298"
                                            className="elementor elementor-298"
                                            data-elementor-settings="[]"
                                          >
                                            <div className="elementor-inner">
                                              <div className="elementor-section-wrap">
                                                <section
                                                  className="elementor-section elementor-top-section elementor-element elementor-element-53f995e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                  data-id="53f995e"
                                                  data-element_type="section"
                                                >
                                                  <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-row">
                                                      <div
                                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0562a8"
                                                        data-id="e0562a8"
                                                        data-element_type="column"
                                                      >
                                                        <div className="elementor-column-wrap elementor-element-populated">
                                                          <div className="elementor-widget-wrap">
                                                            <div
                                                              className="elementor-element elementor-element-091dc17 elementor-widget elementor-widget-karde_texi_budget"
                                                              data-id="091dc17"
                                                              data-element_type="widget"
                                                              data-widget_type="karde_texi_budget.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div className="row">
                                                                  <div className="col-lg-6">
                                                                    <div className="taxi_text">
                                                                      <h3>
                                                                        Speed-O-Service
                                                                      </h3>
                                                                      <p>
                                                                        Speed
                                                                        -O-
                                                                        Service
                                                                        aims to
                                                                        provide
                                                                        service
                                                                        to the
                                                                        customers
                                                                        with
                                                                        shortest
                                                                        turnaround
                                                                        time (90
                                                                        mins)
                                                                        for
                                                                        scheduled
                                                                        services
                                                                        & minor
                                                                        repairs
                                                                        of their
                                                                        vehicles.
                                                                      </p>
                                                                      <ul className="nav">
                                                                        <li>
                                                                          <a href="#">
                                                                            This
                                                                            Speed-O-Service
                                                                            process
                                                                            is
                                                                            designed
                                                                            to
                                                                            help
                                                                            in :
                                                                          </a>
                                                                        </li>
                                                                        <li>
                                                                          <a href="#">
                                                                            Reducing
                                                                            waiting
                                                                            period
                                                                            for
                                                                            getting
                                                                            appointments
                                                                            for
                                                                            schedule
                                                                            service
                                                                            of
                                                                            the
                                                                            vehicle
                                                                          </a>
                                                                        </li>
                                                                        <li>
                                                                          <a href="#">
                                                                            Ensuring
                                                                            quick,
                                                                            timely
                                                                            &
                                                                            same
                                                                            day
                                                                            delivery
                                                                            of
                                                                            your
                                                                            vehicle
                                                                          </a>
                                                                        </li>
                                                                      </ul>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                    <div className="price_content">
                                                                      {/* <div
                                                                        className="price_km wow animated fadeInUp"
                                                                        data-wow-delay="0.4s"
                                                                      > */}
                                                                      <img
                                                                        src={
                                                                          speed_img
                                                                        }
                                                                        alt=""
                                                                        height={
                                                                          70
                                                                        }
                                                                        width={
                                                                          90
                                                                        }
                                                                        className="hiddenMobile"
                                                                      />

                                                                      {/* </div> */}
                                                                      <img
                                                                        className="hiddenMobile img-fluid wow animated fadeInLeft"
                                                                        data-wow-delay="0"
                                                                        src={
                                                                          safari_slider_car
                                                                        }
                                                                        // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/taxi-price.png"
                                                                        alt="Alt"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="tab-pane fade 
							"
                                          id="economy"
                                          role="tabpanel"
                                          aria-labelledby="economy-tab"
                                        >
                                          <div
                                            data-elementor-type="section"
                                            data-elementor-id="298"
                                            className="elementor elementor-298"
                                            data-elementor-settings="[]"
                                          >
                                            <div className="elementor-inner ">
                                              <div className="elementor-section-wrap">
                                                <section
                                                  className="elementor-section elementor-top-section elementor-element elementor-element-53f995e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                  data-id="53f995e"
                                                  data-element_type="section"
                                                >
                                                  <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-row">
                                                      <div
                                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0562a8"
                                                        data-id="e0562a8"
                                                        data-element_type="column"
                                                      >
                                                        <div className="elementor-column-wrap elementor-element-populated ">
                                                          <div className="elementor-widget-wrap">
                                                            <div
                                                              className="elementor-element elementor-element-091dc17 elementor-widget elementor-widget-karde_texi_budget"
                                                              data-id="091dc17"
                                                              data-element_type="widget"
                                                              data-widget_type="karde_texi_budget.default"
                                                            >
                                                              <div className="elementor-widget-container ">
                                                                <div className="row">
                                                                  <div className="col-lg-6">
                                                                    <div className="taxi_text">
                                                                      <h3>
                                                                        Quality
                                                                        Service
                                                                      </h3>
                                                                      <p>
                                                                        Under
                                                                        this
                                                                        program,
                                                                        each
                                                                        vehicle
                                                                        is
                                                                        subjected
                                                                        to
                                                                        standard
                                                                        quality
                                                                        checks
                                                                        to
                                                                        identify
                                                                        repair
                                                                        requirements
                                                                        not
                                                                        noticed
                                                                        or
                                                                        reported
                                                                        by the
                                                                        customer,
                                                                        ensuring
                                                                        that
                                                                        complete
                                                                        health
                                                                        of the
                                                                        vehicle
                                                                        is
                                                                        assessed,
                                                                        attended
                                                                        to and
                                                                        customers
                                                                        are
                                                                        apprised.
                                                                        Final
                                                                        Inspection
                                                                        at the
                                                                        end of
                                                                        the
                                                                        repair
                                                                        process
                                                                        through
                                                                        a
                                                                        stringent
                                                                        quality
                                                                        control
                                                                        SOP
                                                                        (Standard
                                                                        Operating
                                                                        Procedures)
                                                                        ensures
                                                                        hassle
                                                                        free
                                                                        operations
                                                                        for the
                                                                        customer.
                                                                      </p>
                                                                    </div>
                                                                  </div>

                                                                  <div className="col-lg-6">
                                                                    <img
                                                                      src={
                                                                        quaity_services
                                                                      }
                                                                      alt=""
                                                                      className="hiddenMobile"
                                                                    />
                                                                    <div className="price_content">
                                                                      <img
                                                                        className="hiddenMobile img-fluid wow animated fadeInLeft"
                                                                        data-wow-delay="0"
                                                                        src={
                                                                          safari_slider_car
                                                                        }
                                                                        // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/taxi-price.png"
                                                                        alt="Alt"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="tab-pane fade 
							"
                                          id="comfort"
                                          role="tabpanel"
                                          aria-labelledby="comfort-tab"
                                        >
                                          <div
                                            data-elementor-type="section"
                                            data-elementor-id="298"
                                            className="elementor elementor-298"
                                            data-elementor-settings="[]"
                                          >
                                            <div className="elementor-inner">
                                              <div className="elementor-section-wrap">
                                                <section
                                                  className="elementor-section elementor-top-section elementor-element elementor-element-53f995e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                  data-id="53f995e"
                                                  data-element_type="section"
                                                >
                                                  <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-row">
                                                      <div
                                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0562a8"
                                                        data-id="e0562a8"
                                                        data-element_type="column"
                                                      >
                                                        <div className="elementor-column-wrap elementor-element-populated">
                                                          <div className="elementor-widget-wrap">
                                                            <div
                                                              className="elementor-element elementor-element-091dc17 elementor-widget elementor-widget-karde_texi_budget"
                                                              data-id="091dc17"
                                                              data-element_type="widget"
                                                              data-widget_type="karde_texi_budget.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div className="row">
                                                                  <div className="col-lg-6">
                                                                    <div className="taxi_text">
                                                                      <h3>
                                                                        Online
                                                                        Service
                                                                      </h3>
                                                                      <p>
                                                                        Speed
                                                                        -O-
                                                                        Service
                                                                        aims to
                                                                        provide
                                                                        service
                                                                        to the
                                                                        customers
                                                                        with
                                                                        shortest
                                                                        turnaround
                                                                        time (90
                                                                        mins)
                                                                        for
                                                                        scheduled
                                                                        services
                                                                        & minor
                                                                        repairs
                                                                        of their
                                                                        vehicles.
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                    <img
                                                                      src={
                                                                        online_img
                                                                      }
                                                                      alt=""
                                                                      className="hiddenMobile"
                                                                    />
                                                                    <div className="price_content">
                                                                      {/* <div
                                                                        className="price_km wow animated fadeInUp"
                                                                        data-wow-delay="0.4s"
                                                                      >
                                                                        <h6>
                                                                          /km
                                                                        </h6>
                                                                        <h5>
                                                                          <span>
                                                                            $
                                                                          </span>
                                                                          0.50
                                                                        </h5>
                                                                      </div> */}
                                                                      <img
                                                                        className="hiddenMobile img-fluid wow animated fadeInLeft"
                                                                        data-wow-delay="0"
                                                                        src={
                                                                          safari_slider_car
                                                                        }
                                                                        // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/taxi-price.png"
                                                                        alt="Alt"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="tab-pane fade 
							"
                                          id="business"
                                          role="tabpanel"
                                          aria-labelledby="business-tab"
                                        >
                                          <div
                                            data-elementor-type="section"
                                            data-elementor-id="298"
                                            className="elementor elementor-298"
                                            data-elementor-settings="[]"
                                          >
                                            <div className="elementor-inner">
                                              <div className="elementor-section-wrap">
                                                <section
                                                  className="elementor-section elementor-top-section elementor-element elementor-element-53f995e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                  data-id="53f995e"
                                                  data-element_type="section"
                                                >
                                                  <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-row">
                                                      <div
                                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0562a8"
                                                        data-id="e0562a8"
                                                        data-element_type="column"
                                                      >
                                                        <div className="elementor-column-wrap elementor-element-populated">
                                                          <div className="elementor-widget-wrap">
                                                            <div
                                                              className="elementor-element elementor-element-091dc17 elementor-widget elementor-widget-karde_texi_budget"
                                                              data-id="091dc17"
                                                              data-element_type="widget"
                                                              data-widget_type="karde_texi_budget.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div className="row">
                                                                  <div className="col-lg-6">
                                                                    <div className="taxi_text">
                                                                      <h3>
                                                                        Doorstep
                                                                        Service
                                                                      </h3>
                                                                      <p>
                                                                        Tata
                                                                        Motors
                                                                        Service
                                                                        brings
                                                                        you a
                                                                        Unique
                                                                        Service
                                                                        experience
                                                                        in the
                                                                        form of
                                                                        Door
                                                                        Step
                                                                        Service
                                                                        (DSS).
                                                                      </p>
                                                                      <p>
                                                                        The Door
                                                                        Step
                                                                        Service
                                                                        Van is a
                                                                        self-sustained
                                                                        standalone
                                                                        workshop-on-wheels
                                                                        equipped
                                                                        with all
                                                                        the
                                                                        necessary
                                                                        workshop
                                                                        tools
                                                                        and
                                                                        equipments
                                                                        which
                                                                        are
                                                                        required
                                                                        to
                                                                        perform
                                                                        scheduled
                                                                        services
                                                                        & minor
                                                                        repairs
                                                                        on your
                                                                        vehicle.
                                                                        To
                                                                        ensure
                                                                        that our
                                                                        customers
                                                                        enjoy
                                                                        the Tata
                                                                        Motors
                                                                        Service
                                                                        at their
                                                                        convenience,
                                                                        the DSS
                                                                        Van will
                                                                        actually
                                                                        come to
                                                                        a
                                                                        location
                                                                        comfortable
                                                                        for the
                                                                        customer
                                                                        and get
                                                                        the
                                                                        repairs
                                                                        done.
                                                                      </p>
                                                                      <p>
                                                                        *Currently,
                                                                        this
                                                                        service
                                                                        is
                                                                        available
                                                                        only in
                                                                        select
                                                                        cities
                                                                        with
                                                                        prior
                                                                        appointment.
                                                                        Please
                                                                        check
                                                                        with
                                                                        your
                                                                        local
                                                                        dealer
                                                                        for more
                                                                        details.
                                                                      </p>{" "}
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                    <img
                                                                      src={
                                                                        doorstep_img
                                                                      }
                                                                      alt=""
                                                                      className="hiddenMobile"
                                                                    />

                                                                    <div className="price_content">
                                                                      <img
                                                                        className="hiddenMobile img-fluid wow animated fadeInLeft"
                                                                        data-wow-delay="0"
                                                                        src={
                                                                          safari_slider_car
                                                                        }
                                                                        // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/taxi-price.png"
                                                                        alt="Alt"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="tab-pane fade 
							"
                                          id="minivan"
                                          role="tabpanel"
                                          aria-labelledby="minivan-tab"
                                        >
                                          <div
                                            data-elementor-type="section"
                                            data-elementor-id="298"
                                            className="elementor elementor-298"
                                            data-elementor-settings="[]"
                                          >
                                            <div className="elementor-inner">
                                              <div className="elementor-section-wrap">
                                                <section
                                                  className="elementor-section elementor-top-section elementor-element elementor-element-53f995e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                  data-id="53f995e"
                                                  data-element_type="section"
                                                >
                                                  <div className="elementor-container elementor-column-gap-no">
                                                    <div className="elementor-row">
                                                      <div
                                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e0562a8"
                                                        data-id="e0562a8"
                                                        data-element_type="column"
                                                      >
                                                        <div className="elementor-column-wrap elementor-element-populated">
                                                          <div className="elementor-widget-wrap">
                                                            <div
                                                              className="elementor-element elementor-element-091dc17 elementor-widget elementor-widget-karde_texi_budget"
                                                              data-id="091dc17"
                                                              data-element_type="widget"
                                                              data-widget_type="karde_texi_budget.default"
                                                            >
                                                              <div className="elementor-widget-container">
                                                                <div className="row">
                                                                  <div className="col-lg-6">
                                                                    <div className="taxi_text">
                                                                      <h3>
                                                                        Visibility
                                                                        of your
                                                                        own
                                                                        Vehicle
                                                                      </h3>
                                                                      <p>
                                                                        If your
                                                                        vehicle
                                                                        needs
                                                                        minor
                                                                        repairs
                                                                        like a
                                                                        bulb
                                                                        change,
                                                                        fuse
                                                                        repairs
                                                                        or even
                                                                        flat
                                                                        tyre
                                                                        replacement,
                                                                        feel
                                                                        free to
                                                                        drop by
                                                                        our
                                                                        workshop
                                                                        and get
                                                                        the same
                                                                        resolved
                                                                        within
                                                                        30
                                                                        minutes.
                                                                        We have
                                                                        dedicated
                                                                        bays in
                                                                        the
                                                                        workshop
                                                                        to
                                                                        fulfill
                                                                        our
                                                                        promise.
                                                                      </p>

                                                                      <div className="car_details"></div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                    <img
                                                                      src={
                                                                        repair_img
                                                                      }
                                                                      alt=""
                                                                      className="hiddenMobile"
                                                                    />
                                                                    <div className="price_content">
                                                                      <img
                                                                        className="hiddenMobile img-fluid wow animated fadeInLeft"
                                                                        data-wow-delay="0"
                                                                        // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/taxi-price.png"
                                                                        src={
                                                                          safari_slider_car
                                                                        }
                                                                        alt="Alt"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="tab-pane fade 
							"
                                          id="minibus"
                                          role="tabpanel"
                                          aria-labelledby="minibus-tab"
                                        >
                                          <div
                                            data-elementor-type="section"
                                            data-elementor-id="298"
                                            className="elementor elementor-298"
                                            data-elementor-settings="[]"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="row">
                                                <div className="col-lg-6">
                                                  <div className="taxi_text">
                                                    <h3>
                                                      24 X 7 Road Side
                                                      Assistance
                                                    </h3>
                                                    <p>
                                                      This is a pan India
                                                      breakdown and towing
                                                      assistance program that is
                                                      serviced by a dedicated
                                                      network of over 2,700
                                                      authorized service
                                                      providers and can be
                                                      accessed through a toll
                                                      free helpline - 1800 209
                                                      8282. A quick response is
                                                      ensured within 60 minutes
                                                      in city limits and within
                                                      120 minutes on highways
                                                      and other places. This
                                                      service is provided free
                                                      of cost till the warranty
                                                      period of your car.
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <img
                                                    src={road_img}
                                                    alt=""
                                                    className="hiddenMobile"
                                                  />
                                                  <div className="price_content">
                                                    <img
                                                      className="hiddenMobile img-fluid wow animated fadeInLeft"
                                                      data-wow-delay="0"
                                                      // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/taxi-price.png"
                                                      src={safari_slider_car}
                                                      alt="Alt"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  className="hiddenMobile  text-dark elementor-section elementor-top-section elementor-element elementor-element-c1a101c elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="c1a101c"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2c873ac"
                        data-id="2c873ac"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-f799917 elementor-widget elementor-widget-karde_ourservice"
                              data-id="f799917"
                              data-element_type="widget"
                              data-widget_type="karde_ourservice.default"
                            >
                              <div className="elementor-widget-container">
                                <section
                                  className="our_service_area 
			pad_top"
                                >
                                  <div className="container">
                                    <div
                                      className="  
			title_two text-center white		 wow animated fadeInUp"
                                      data-wow-delay="0.2s"
                                    >
                                      {/* <h5>Our Expertise</h5> */}
                                      <h2>Our Services</h2>
                                    </div>
                                    <div className="or_service_inner">
                                      <div className="row flex-row-reverse our_service_row">
                                        <div className="col-lg-8 service_col_content">
                                          <div className="service_body">
                                            <div
                                              className="tab-content"
                                              id="myTabContent"
                                            >
                                              <div
                                                className="tab-pane fade 
															"
                                                id="mechanical_repairs"
                                                role="tabpanel"
                                                aria-labelledby="mechanical_repairs-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={mechanic_repairs}
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon  bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>Mechanical Repairs</h4>
                                                    <p>
                                                      The mission of the
                                                      AutoMechanica company is
                                                      to provide high-quality
                                                      auto repair and car
                                                      maintenance service.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* body work repair  ev charging*/}
                                              <div
                                                className="tab-pane fade active show"
                                                id="body_work_repair"
                                                role="tabpanel"
                                                aria-labelledby="body_work_repair-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={ev_charging_img_1}
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon  bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>
                                                      EV Charging Facility
                                                      Available
                                                    </h4>
                                                    <p>
                                                      Knowing the different
                                                      types of auto body repair
                                                      is necessary to help you
                                                      make the right decision.
                                                      The knowledge helps avoid
                                                      the nervousness that comes
                                                      when you find your car is
                                                      damaged. Besides, you also
                                                      make the right decision as
                                                      to whether you take it to
                                                      a car body shop Vancouver
                                                      or your mechanic.Types of
                                                      auto body repair · 1.
                                                      Paintless dent repair · 2.
                                                      Body filler · 3. Bumper
                                                      replacements · 4. Car body
                                                      painting · 5. Car
                                                      collision repair ·
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                      style={{
                                                        backgroundColor:
                                                          "#0478d7",
                                                        background: "#0478d7",
                                                      }}
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* electronic diagnostic  Online Estimation Of Billing */}
                                              <div
                                                className="tab-pane fade 
															"
                                                id="electronic_diagnostics"
                                                role="tabpanel"
                                                aria-labelledby="electronic_diagnostics-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={online_billing}
                                                      width="750px"
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>
                                                      Online Estimation Of
                                                      Billing
                                                    </h4>
                                                    <p>
                                                      The next and best facility
                                                      available for you. An
                                                      easiest way of your budget
                                                      estimation by early online
                                                      estimation of billing. You
                                                      will get a
                                                      systematic,organised and
                                                      structured billing online
                                                      document for the charges
                                                      of your vehicle servicing.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* tyre fitting service launge */}
                                              <div
                                                className="tab-pane fade 
															"
                                                id="tyre_fitting"
                                                role="tabpanel"
                                                aria-labelledby="tyre_fitting-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={
                                                        service_lounge_images
                                                      }
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>
                                                      {" "}
                                                      Executive Customer Lounge
                                                    </h4>
                                                    <p>
                                                      A comfort place for our
                                                      customers. Facilities like
                                                      air conditioners, proper
                                                      sedentary services and
                                                      polite hospitality are
                                                      executed.
                                                    </p>

                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/*accident repairs luxury   */}
                                              <div
                                                className="tab-pane fade 
															"
                                                id="accident_repairs"
                                                role="tabpanel"
                                                aria-labelledby="accident_repairs-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={luxury_images}
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>Luxury Services</h4>
                                                    <p>
                                                      Conveniences available for
                                                      our customers like proper
                                                      sedentary arrangements,
                                                      refreshments, separate
                                                      driver's lounge and
                                                      air-conditioned
                                                      atmosphere. We believe in
                                                      complete facilitation,
                                                      proper care and prompt
                                                      services to our customers.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* air conditioning visibility  */}
                                              <div
                                                className="tab-pane fade 
															"
                                                id="air_conditioning"
                                                role="tabpanel"
                                                aria-labelledby="air_conditioning-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={visibility_images}
                                                      title="service-1"
                                                      width="750px"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon  bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>
                                                      Visiblity of your own
                                                      vehicle
                                                    </h4>
                                                    <p>
                                                      When in servicing, you can
                                                      have an easier access of
                                                      visibility to your
                                                      vehicle. you can decide,
                                                      calculate and alter as per
                                                      your own choices. A
                                                      flexible code of conduct
                                                      and structured
                                                      arrangements.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className="tab-pane fade 
															"
                                                id="paint_body_and_parts"
                                                role="tabpanel"
                                                aria-labelledby="paint_body_and_parts-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={paint_img}
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon  bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>
                                                      Paint Body and Parts
                                                    </h4>
                                                    <p>
                                                      Paint the parts in a
                                                      well-ventilated area.
                                                      Choose a clean, dry room
                                                      such as a garage or
                                                      workshop.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className="tab-pane fade 
															"
                                                id="braking_repair"
                                                role="tabpanel"
                                                aria-labelledby="braking_repair-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={breaking_repair_img}
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon  bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>Braking Repair</h4>
                                                    <p>
                                                      The best way to avoid
                                                      things like brake grinding
                                                      or warped rotors is to
                                                      keep up with good brake
                                                      maintenance.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className="tab-pane fade 
															"
                                                id="mobile_mechanic"
                                                role="tabpanel"
                                                aria-labelledby="mobile_mechanic-tab"
                                              >
                                                <div className="service_ex">
                                                  <div className="service_img">
                                                    <img
                                                      src={mobile_mechaninc}
                                                      title="service-1"
                                                      alt="service-1"
                                                    />
                                                  </div>
                                                  <div className="service_text">
                                                    <i
                                                      aria-hidden="true"
                                                      className="icon bi bi-car-front-fill"
                                                    ></i>
                                                    <h4>Mobile Mechanic</h4>
                                                    <p>
                                                      A mobile mechanic can do
                                                      most of the repairs and
                                                      servicing you'll need on
                                                      your vehicle – all with
                                                      the benefit of driving to
                                                      a place of your choosing.
                                                      Operating out of a van,
                                                      rather than a garage, they
                                                      can transport most of the
                                                      necessary tools to carry
                                                      out work on a vehicle to
                                                      get it back in good
                                                      working condition.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      className="main_btn red"
                                                      rel="nofollow"
                                                    >
                                                      <i className="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 our_service_col_tab col-sm-6">
                                          <div className="service_button">
                                            <ul
                                              className="nav nav-tabs"
                                              id="myTab"
                                              role="tablist"
                                            >
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
								active							"
                                                  id="body_work_repair-tab"
                                                  data-toggle="tab"
                                                  href="#body_work_repair"
                                                  role="tab"
                                                  aria-controls="body_work_repair"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon bi bi-gear-wide-connected"
                                                  ></i>
                                                  EV Charging Facility Available
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="braking_repair-tab"
                                                  data-toggle="tab"
                                                  href="#braking_repair"
                                                  role="tab"
                                                  aria-controls="braking_repair"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon bi bi-building-gear"
                                                  ></i>
                                                  Braking Repair
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="mobile_mechanic-tab"
                                                  data-toggle="tab"
                                                  href="#mobile_mechanic"
                                                  role="tab"
                                                  aria-controls="mobile_mechanic"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon  bi bi-fuel-pump-diesel-fill"
                                                  ></i>
                                                  Mobile Mechanic
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="mechanical_repairs-tab"
                                                  data-toggle="tab"
                                                  href="#mechanical_repairs"
                                                  role="tab"
                                                  aria-controls="mechanical_repairs"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon bi bi-sliders2-vertical"
                                                  ></i>
                                                  Mechanical Repairs
                                                </a>
                                              </li>

                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="paint_body_and_parts-tab"
                                                  data-toggle="tab"
                                                  href="#paint_body_and_parts"
                                                  role="tab"
                                                  aria-controls="paint_body_and_parts"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon  bi bi-palette-fill"
                                                  ></i>
                                                  Paint Body and Parts
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="electronic_diagnostics-tab"
                                                  data-toggle="tab"
                                                  href="#electronic_diagnostics"
                                                  role="tab"
                                                  aria-controls="electronic_diagnostics"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon  bi bi-database-fill-gear"
                                                  ></i>
                                                  Online Estimation Of Billing
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="tyre_fitting-tab"
                                                  data-toggle="tab"
                                                  href="#tyre_fitting"
                                                  role="tab"
                                                  aria-controls="tyre_fitting"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon  bi bi-car-front"
                                                  ></i>
                                                  Executive Customer Lounge
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="air_conditioning-tab"
                                                  data-toggle="tab"
                                                  href="#air_conditioning"
                                                  role="tab"
                                                  aria-controls="air_conditioning"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon  bi bi-fan"
                                                  ></i>
                                                  Visiblity of your own vehicle
                                                </a>
                                              </li>
                                              <li className="nav-item">
                                                <a
                                                  className="nav-link 
															"
                                                  id="accident_repairs-tab"
                                                  data-toggle="tab"
                                                  href="#accident_repairs"
                                                  role="tab"
                                                  aria-controls="accident_repairs"
                                                  aria-selected="false"
                                                >
                                                  <i
                                                    aria-hidden="true"
                                                    className="icon bi bi-database-fill-gear"
                                                  ></i>
                                                  Luxury Services-
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* mobile view only */}
                <section
                  class="d-lg-none
elementor-section elementor-top-section elementor-element elementor-element-de28572 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="de28572"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                  style={{ backgroundColor: "rgb(0,0,0)", color: "#fff" }}
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-row">
                      <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9ac2a66"
                        data-id="9ac2a66"
                        data-element_type="column"
                      >
                        <div class="elementor-column-wrap elementor-element-populated">
                          <div class="elementor-widget-wrap">
                            <div
                              class="elementor-element elementor-element-c792e36 elementor-widget elementor-widget-karde_ourservice"
                              data-id="c792e36"
                              data-element_type="widget"
                              data-widget_type="karde_ourservice.default"
                            >
                              <div class="elementor-widget-container">
                                <section class="our_service_area luxuary_s_car">
                                  <div
                                    class="left_tiar wow animated fadeInLeft"
                                    data-wow-delay="0.4s"
                                  >
                                    <img
                                      src="https://smartdatawp.com/motodeal/luxuary/wp-content/uploads/sites/9/2019/12/luxuary-tyre-2.png"
                                      alt="Alt"
                                    />
                                  </div>
                                  <div class="container">
                                    <div class="single_br_title">
                                      <h2
                                        data-text="Luxurious"
                                        class="wow animated fadeInLeft"
                                        data-wow-delay="0.2s"
                                      >
                                        OUR SERVICES
                                      </h2>
                                    </div>
                                    <div class="or_service_inner">
                                      <div class="row">
                                        <div class="col-lg-2">
                                          <div class="service_button">
                                            <ul
                                              class="nav nav-tabs"
                                              id="myTab"
                                              role="tablist"
                                            >
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link 
									active"
                                                  id="jamtordhini_adentavor-tab"
                                                  data-toggle="tab"
                                                  href="#jamtordhini_adentavor"
                                                  role="tab"
                                                  aria-controls="jamtordhini_adentavor"
                                                  aria-selected="true"
                                                >
                                                  EV Charging Facility Available
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link "
                                                  id="tarcenez_lenz-tab"
                                                  data-toggle="tab"
                                                  href="#tarcenez_lenz"
                                                  role="tab"
                                                  aria-controls="tarcenez_lenz"
                                                  aria-selected="false"
                                                >
                                                  Braking Repair
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link "
                                                  id="fa_gerrali-tab"
                                                  data-toggle="tab"
                                                  href="#fa_gerrali"
                                                  role="tab"
                                                  aria-controls="fa_gerrali"
                                                  aria-selected="false"
                                                >
                                                  Mobile Mechanic
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link 
																			"
                                                  id="amn_series-tab"
                                                  data-toggle="tab"
                                                  href="#amn_series"
                                                  role="tab"
                                                  aria-controls="amn_series"
                                                  aria-selected="false"
                                                >
                                                  Mechanical Repairs
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link 
																			"
                                                  id="sky_cover-tab"
                                                  data-toggle="tab"
                                                  href="#sky_cover"
                                                  role="tab"
                                                  aria-controls="sky_cover"
                                                  aria-selected="false"
                                                >
                                                  Paint Body and Parts
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link 
																			"
                                                  id="dubatli-tab"
                                                  data-toggle="tab"
                                                  href="#dubatli"
                                                  role="tab"
                                                  aria-controls="dubatli"
                                                  aria-selected="false"
                                                >
                                                  Online Estimation Of Billing
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link 	"
                                                  id="polls_choyce-tab"
                                                  data-toggle="tab"
                                                  href="#polls_choyce"
                                                  role="tab"
                                                  aria-controls="polls_choyce"
                                                  aria-selected="false"
                                                >
                                                  Executive Customer Lounge
                                                </a>
                                              </li>
                                              <li class="nav-item">
                                                <a
                                                  class="nav-link 
																			"
                                                  id="gemhly-tab"
                                                  data-toggle="tab"
                                                  href="#gemhly"
                                                  role="tab"
                                                  aria-controls="gemhly"
                                                  aria-selected="false"
                                                >
                                                  Visiblity of your own vehicle
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div class="col-lg-10">
                                          <div class="service_body">
                                            <div
                                              class="tab-content"
                                              id="myTabContent"
                                            >
                                              <div
                                                class="tab-pane fade 
										active show	"
                                                id="jamtordhini_adentavor"
                                                role="tabpanel"
                                                aria-labelledby="jamtordhini_adentavor-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={ev_charging_img_1}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>
                                                      EV Charging Facility
                                                      Available
                                                    </h4>
                                                    <p>
                                                      Knowing the different
                                                      types of auto body repair
                                                      is necessary to help you
                                                      make the right decision.
                                                      The knowledge helps avoid
                                                      the nervousness that comes
                                                      when you find your car is
                                                      damaged. Besides, you also
                                                      make the right decision as
                                                      to whether you take it to
                                                      a car body shop Vancouver
                                                      or your mechanic.Types of
                                                      auto body repair · 1.
                                                      Paintless dent repair · 2.
                                                      Body filler · 3. Bumper
                                                      replacements · 4. Car body
                                                      painting · 5. Car
                                                      collision repair ·
                                                    </p>
                                                    <Link
                                                      to="/ev/nexon"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade 
																			"
                                                id="tarcenez_lenz"
                                                role="tabpanel"
                                                aria-labelledby="tarcenez_lenz-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={breaking_repair_img}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>Braking Repair</h4>
                                                    <p>
                                                      The best way to avoid
                                                      things like brake grinding
                                                      or warped rotors is to
                                                      keep up with good brake
                                                      maintenance.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade 
																			"
                                                id="fa_gerrali"
                                                role="tabpanel"
                                                aria-labelledby="fa_gerrali-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={mobile_mechaninc}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>Mobile Mechanic</h4>
                                                    <p>
                                                      A mobile mechanic can do
                                                      most of the repairs and
                                                      servicing you'll need on
                                                      your vehicle – all with
                                                      the benefit of driving to
                                                      a place of your choosing.
                                                      Operating out of a van,
                                                      rather than a garage, they
                                                      can transport most of the
                                                      necessary tools to carry
                                                      out work on a vehicle to
                                                      get it back in good
                                                      working condition.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade"
                                                id="amn_series"
                                                role="tabpanel"
                                                aria-labelledby="amn_series-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={mechanic_repairs}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>Mechanical Repairs</h4>
                                                    <p>
                                                      The mission of the
                                                      AutoMechanica company is
                                                      to provide high-quality
                                                      auto repair and car
                                                      maintenance service.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade "
                                                id="sky_cover"
                                                role="tabpanel"
                                                aria-labelledby="sky_cover-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={paint_img}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>
                                                      Paint Body and Parts
                                                    </h4>
                                                    <p>
                                                      Paint the parts in a
                                                      well-ventilated area.
                                                      Choose a clean, dry room
                                                      such as a garage or
                                                      workshop.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade 
																			"
                                                id="dubatli"
                                                role="tabpanel"
                                                aria-labelledby="dubatli-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={online_billing}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>
                                                      Online Estimation Of
                                                      Billing
                                                    </h4>
                                                    <p>
                                                      The next and best facility
                                                      available for you. An
                                                      easiest way of your budget
                                                      estimation by early online
                                                      estimation of billing. You
                                                      will get a
                                                      systematic,organised and
                                                      structured billing online
                                                      document for the charges
                                                      of your vehicle servicing.
                                                      s
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade 	"
                                                id="polls_choyce"
                                                role="tabpanel"
                                                aria-labelledby="polls_choyce-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={
                                                        service_lounge_images
                                                      }
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>
                                                      Executive Customer Lounge
                                                    </h4>
                                                    <p>
                                                      A comfort place for our
                                                      customers. Facilities like
                                                      air conditioners, proper
                                                      sedentary services and
                                                      polite hospitality are
                                                      executed.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="tab-pane fade 
																			"
                                                id="gemhly"
                                                role="tabpanel"
                                                aria-labelledby="gemhly-tab"
                                              >
                                                <div class="service_ex">
                                                  <div class="service_img">
                                                    <img
                                                      src={visibility_images}
                                                      title="luxuary-1"
                                                      alt="luxuary-1"
                                                    />
                                                  </div>
                                                  <div class="service_text">
                                                    <h4>
                                                      Visiblity of your own
                                                      vehicle
                                                    </h4>
                                                    <p>
                                                      When in servicing, you can
                                                      have an easier access of
                                                      visibility to your
                                                      vehicle. you can decide,
                                                      calculate and alter as per
                                                      your own choices. A
                                                      flexible code of conduct
                                                      and structured
                                                      arrangements.
                                                    </p>
                                                    <a
                                                      href="#"
                                                      class="main_btn red"
                                                      target="_blank"
                                                      rel="nofollow"
                                                    >
                                                      <i class="bi bi-arrow-right"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="view_all wow animated fadeInRight animated"
                                        data-wow-delay="0.3s"
                                      >
                                        <a
                                          class="border_btn"
                                          href="https://smartdatawp.com/motodeal/luxuary/services-details/"
                                        >
                                          View All
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* service videos */}
                {/* <div className="row">
            <div className="col-lg-10 col-sm-4 col-xs-6">
              <video src={safari_video} controls style={{border:"3px solid gold"}} className="video-manage"></video>
            </div>
          </div> */}

                {/* area we find us */}
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-7120e8b elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="7120e8b"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c89f18f"
                        data-id="c89f18f"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-655abbb elementor-widget elementor-widget-karde_findus_area"
                              data-id="655abbb"
                              data-element_type="widget"
                              data-widget_type="karde_findus_area.default"
                            >
                              <div className="elementor-widget-container">
                                <section className="find_us_area p_100">
                                  <div className="container">
                                    <div className="row find_us_inner">
                                      <div className="col-lg-6">
                                        <div className="find_us_text">
                                          <h2>
                                            Areas You Can <span>Find Us</span>
                                          </h2>
                                          <p>
                                            Mutha Motors are available
                                            <br /> in below regions also
                                          </p>
                                          <div className="list_finding">
                                            <ul className="nav flex-column">
                                              <li className="new_york_city">
                                                <a href="javascript:void(0)">
                                                  Aurangabad
                                                </a>
                                              </li>

                                              <li className="san_diego">
                                                <a href="javascript:void(0)">
                                                  Buldhana
                                                </a>
                                              </li>

                                              <li className="los_angeles">
                                                <a href="javascript:void(0)">
                                                  Jalna
                                                </a>
                                              </li>

                                              <li className="boston">
                                                <a href="javascript:void(0)">
                                                  Khamgaon
                                                </a>
                                              </li>
                                              <li className="chicago">
                                                <a href="javascript:void(0)">
                                                  Malkapur
                                                </a>
                                              </li>
                                            </ul>
                                            {/* <ul className="nav flex-column">
                                              <li className="chicago">
                                                <a href="javascript:void(0)">
                                                  Chicago
                                                </a>
                                              </li>

                                              <li className="san_francisco">
                                                <a href="javascript:void(0)">
                                                  San Francisco
                                                </a>
                                              </li>

                                              <li className="baltimore">
                                                <a href="javascript:void(0)">
                                                  Baltimore
                                                </a>
                                              </li>

                                              <li className="houston">
                                                <a href="javascript:void(0)">
                                                  Houston
                                                </a>
                                              </li>

                                              <li className="phoenix">
                                                <a href="javascript:void(0)">
                                                  Phoenix
                                                </a>
                                              </li>

                                              <li className="philadelphia">
                                                <a href="javascript:void(0)">
                                                  Philadelphia
                                                </a>
                                              </li>
                                            </ul> */}
                                            {/* <ul className="nav flex-column">
                                              <li className="detroit">
                                                <a href="javascript:void(0)">
                                                  Detroit
                                                </a>
                                              </li>

                                              <li className="miami">
                                                <a href="javascript:void(0)">
                                                  Miami
                                                </a>
                                              </li>

                                              <li className="seattle">
                                                <a href="javascript:void(0)">
                                                  Seattle
                                                </a>
                                              </li>
                                            </ul> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="find_us_img">
                                          <img
                                            className="img-fluid wow animated fadeIn"
                                            data-wow-delay="0.2s"
                                            // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/find-map-2.png"
                                            // src={map1}
                                            src={map_3d}
                                            // src={safari_slider_car}
                                            alt="Alt"
                                          />
                                          <div className="marker_icon">
                                            <ul className="nav elementor-repeater-item-c59fbd0">
                                              <li
                                                style={{
                                                  top: "35%",
                                                  left: "35%",
                                                }}
                                                className="new_york_city"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir/19.8748734,75.3761947/R8XW%2B9G8+MUTHA+MOTORS,+Gopi+Nath+Pura,+Satara+Deolai+Parisar,+Aurangabad,+Maharashtra+431005/@19.8620045,75.3434205,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bdba3974a4356c3:0xfcfba4eb70fc7cb2!2m2!1d75.3463192!2d19.8484111"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Chhatrapati Sambhajinagar"
                                                  data-original-title="Chhatrapati Sambhajinagar"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Chhatrapati Sambhajinagar"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "31%",
                                                  left: "40%",
                                                }}
                                                className="atlanta"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+buldhana+Khetan+Square+Shegaon+Buldhana+-+443001.+Landmark.+Opposite+Shegaon+Urban+Bank./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bda1e077e700edb:0x14e04802a6c02141?sa=X&ved=2ahUKEwjBsoDnytP9AhUwIbcAHTa3DHEQ9Rd6BAhREAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Buldhana"
                                                  data-original-title="Buldhana"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Buldhana"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "22%",
                                                  left: "40%",
                                                }}
                                                className="san_diego"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Khamgaon"
                                                  data-original-title="Khamgaon"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Khamgaon"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "38%",
                                                  // right: "30%",
                                                  // bottom: "30%",
                                                  left: "40%",
                                                }}
                                                className="los_angeles"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir/19.874865,75.377049/TATA+MOTORS+LIMITED+MUTHA+MOTORS+JALNA,+Aditya+nagar,+Chandanzira,+Jalna,+Maharashtra+431203/@19.8870144,75.1443048,9.5z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bda51dd332f304b:0xa7ae2289377481a1!2m2!1d75.8563004!2d19.8589784"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Jalna"
                                                  data-original-title="Jalna"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Jalna"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "25%",
                                                  left: "55%",
                                                }}
                                                className="boston"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Malkapur"
                                                  data-original-title="Malkapur"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Malkapur"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              {/* <li
                                                style={{
                                                  top: "49%",
                                                  left: "33%",
                                                }}
                                                className="st._louis"
                                              >
                                                <a
                                                  href="#"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="St. Louis"
                                                  data-original-title="St. Louis"
                                                >
                                                  /
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="St. Louis"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "43%",
                                                  left: "49%",
                                                }}
                                                className="chicago"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Chicago"
                                                  data-original-title="Chicago"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Chicago"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "14%",
                                                  left: "54%",
                                                }}
                                                className="san_francisco"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="San Francisco"
                                                  data-original-title="San Francisco"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="San Francisco"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "60%",
                                                  left: "60%",
                                                }}
                                                className="baltimore"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Baltimore"
                                                  data-original-title="Baltimore"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Baltimore"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "44%",
                                                  left: "70%",
                                                }}
                                                className="houston"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Houston"
                                                  data-original-title="Houston"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Houston"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "auto",
                                                  bottom: "3%",
                                                  left: "55%",
                                                }}
                                                className="phoenix"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Phoenix"
                                                  data-original-title="Phoenix"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Phoenix"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "48%",
                                                  right: "23%",
                                                  left: "auto",
                                                }}
                                                className="philadelphia"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Philadelphia"
                                                  data-original-title="Philadelphia"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Philadelphia"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "auto",
                                                  right: "13%",
                                                  bottom: "24%",
                                                  left: "auto",
                                                }}
                                                className="detroit"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Detroit"
                                                  data-original-title="Detroit"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Detroit"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "39%",
                                                  right: "4%",
                                                  left: "auto",
                                                }}
                                                className="miami"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Miami"
                                                  data-original-title="Miami"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Miami"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "13%",
                                                  right: "4%",
                                                  left: "auto",
                                                }}
                                                className="seattle"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Seattle"
                                                  data-original-title="Seattle"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Seattle"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-cec26b9 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="cec26b9"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1aef6bc"
                        data-id="1aef6bc"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-d66e880 elementor-widget elementor-widget-karde_sposor"
                              data-id="d66e880"
                              data-element_type="widget"
                              data-widget_type="karde_sposor.default"
                            >
                              <div className="elementor-widget-container">
                                <section className="client_logo_slider_area">
                                  <div className="container">
                                    <div className="client_logo_slider owl-carousel">
                                      <div className="item">
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/client-log-1.png"
                                          title="client-log-1"
                                          alt="client-log-1"
                                        />
                                      </div>
                                      <div className="item">
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/client-log-4.png"
                                          title="client-log-4"
                                          alt="client-log-4"
                                        />
                                      </div>
                                      <div className="item">
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/client-log-3.png"
                                          title="client-log-3"
                                          alt="client-log-3"
                                        />
                                      </div>
                                      <div className="item">
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/client-log-4.png"
                                          title="client-log-4"
                                          alt="client-log-4"
                                        />
                                      </div>
                                      <div className="item">
                                        <img
                                          src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/client-log-5.png"
                                          title="client-log-5"
                                          alt="client-log-5"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* more about serice */}
                <div className="row m-5 ">
                  <div className="col-lg-12 col-sm-6 col-xs-6">
                    <p className="hiddenMobile">
                      <strong>
                        {" "}
                        For all your vehicle maintenance and repair needs, we
                        have a network of Tata Motors authorized workshops
                        spread across India. A Tata Authorized workshop gives
                        you best-in-className service for your Tata vehicle. Few
                        key benefits are highlighted below:{" "}
                      </strong>{" "}
                    </p>

                    <ul className="hiddenMobile mt-3 ">
                      {/* <div className="hiddenMobile"> */}
                      <li>
                        1) State-of-the-art pneumatically automated service
                        center
                      </li>
                      <li>
                        2) State-of-the-art diagnostic system, available only
                        with authorized network to ensure accurate diagnosis and
                        best performance of your vehicle
                      </li>
                      <li>
                        3) Qualified and trained technicians, team leaders,
                        service advisors, expert diagnosticians and trainers and
                        customer relationship officers
                      </li>
                      <li>4) 100% assurance – Only genuine spare parts used</li>
                      <li>5) Warranty, AMC and extended warranty facility</li>
                      <li>
                        6) Specially blended lubricants approved by Tata Motors
                        for your car
                      </li>
                      <li>
                        7) 6 months warranty on workmanship for all kinds of
                        repairs*
                      </li>
                      <li>8) 6 months warranty on spare parts*</li>
                      <li>
                        9) Comprehensive AC repairs conducted using modern AC
                        charging machines
                      </li>
                      <li>
                        10) Tata motors insurance policy and claims facility
                      </li>
                      <li>
                        11) Best-in-industry market competitive labour charges
                      </li>
                      <li>
                        12) Computerized wheel aligners and balancers with
                        product alignment readings
                      </li>
                      <li>
                        13) Tata motors-approved value added products like
                        anti-rust, interior and exterior enrichment
                      </li>
                      <li>14) Road Side Assistance facility</li>
                      <li>
                        15) Comprehensive accidental repairs conducted by
                        experts with long lasting paints
                      </li>
                      {/* </div>       */}
                    </ul>
                  </div>

                  <div className="col-lg-12 col-sm-6 col-xs-6 m-5">
                    <h5 className="hiddenMobile">
                      <b>Body and Paint Workshop :</b>
                    </h5>
                    <ul className="hiddenMobile">
                      <li>
                        {" "}
                        1) Only best practices are followed in the Body and
                        Paint workshop at the dealership
                      </li>
                      <li>
                        2) The workshops help to maintain the OEM standard while
                        repairing the vehicle
                      </li>
                      <li>
                        3) We have the best-in-className infrastructure with the
                        latest tools and equipments
                      </li>
                      <li>4) We will ensure the highest quality of repairs</li>
                      <li>
                        5) We have a cashless tie up with all major insurance
                        companies ensuring that you have a hassle-free repair
                        and insurance process
                      </li>
                      <li>
                        6) We are backed by Tata Motors Insurance – one stop
                        solution for all renewals, claim processing etc.
                      </li>
                      <li>
                        7) We provide quick delivery of the vehicle with a very
                        low turnaround time
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
