import React from 'react'
import {Link} from 'react-router-dom'
import tiago_ev_header_1 from '../ev/ev_images/ev_tiago/tiago_ev_header_1'
import ev_tiago_features_1 from '../ev/ev_images/ev_tiago/ev_tiago_features_1'
import ev_tiago_features_2 from '../ev/ev_images/ev_tiago/ev_tiago_features_2'
import ev_tiago_performance from '../ev/ev_images/ev_tiago/ev_tiago_performance'
import ev_tiago_1 from '../ev/ev_images/ev_tiago/ev_tiago_1'
import ev_tiago_2 from '../ev/ev_images/ev_tiago/ev_tiago_2'
import ev_tiago_3 from '../ev/ev_images/ev_tiago/ev_tiago_3'
import tiago_ev_video from '../ev/ev_video/tiago_ev_video'
import tiago_ev_video_2 from '../ev/ev_video/tiago_ev_video_2'
import headlamp_tiago from '../ev/ev_images/ev_tiago/headlamp_tiago'
import roof_tiago from '../ev/ev_images/ev_tiago/roof_tiago'
import tiago_front from '../ev/ev_images/ev_tiago/tiago_front'
import tiago_inner from '../ev/ev_images/ev_tiago/tiago_inner'


import Navigation_ev_tiago from '../../Components/Navigation_ev_tiago'

export default function Tiago_ev() {
  return <>
      <Navigation_ev_tiago/>


  <div className="container-fluid bg-dark">
  <img src={tiago_ev_header_1} alt="" />
   
  </div>
  <section className='price-sec'>
 <div className="col-lg-9 col-sm-12 col-xs-12 myContainer">
    <div className="performance-cotent">
  <div className="row">
    <div className="col-lg-12">
      <div className="prfmnce-cnt">
        <h2 className='prfmnce-title'>Range</h2>
        <span>315 <sup>#</sup></span>
        <small>Km</small>
      </div>
    </div>
    </div>
    <div class="prfmnce-cnt">
                <h6 class="prfmnce-title">0 to 60 km/h</h6>
                <span>5.7<sup>^</sup></span>
                <small>sec</small>
            </div>

            <div class="prfmnce-cnt">
                <h2 class="prfmnce-title">Fast-Charge</h2>
                <span>58<sup>**</sup></span>
                <small>min</small>
               
            </div>

    </div>
    </div>
 </section>

 {/* features */}

 <section style={{backgroundColor:'#f4f6f9'}}>


    <div className="row">
      <div className="col-lg-6 col-sm-6 col-xs- col-md-6">
        <video autoplay="" muted="" loop="" playsinline="" preload="metadata"  src={tiago_ev_video} controls>
              </video>
    </div>
    <div className="col-lg-6 col-sm-6 col-xs-6 text-center zigzag-right">
<h1 className='ev-text-heading'>Watch the Launch Event
</h1>
    </div>
    </div>

    <div className="row">
    <div className="col-lg-6 col-sm-6 col-xs-6 text-center zigzag-right">
<h1 className='ev-text-heading'>Say hello to the gamechanger
</h1>
    </div>
    <div className="col-lg-6 col-sm-6 col-xs-6">
        <video autoplay="" muted="" loop="" playsinline="" preload="metadata"  src={tiago_ev_video_2} controls>
              </video>
    </div>
    </div>

    <div className="row text-center mt-5">
      <h1><b>Design</b></h1>
      <h3>Your turn to turn heads
</h3>
    </div>
    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
  

    <div className="row text-center">
      <div className="col-lg-6 col-sm-6 col-xs-6">
        <img src={headlamp_tiago} alt="" />
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right ev-text-heading">
<h1>Projector Headlamps</h1>
<h4>Confidently navigate the road ahead with better visibility. </h4>
      </div>
    </div>
    </div>
    <div class="carousel-item">
 

    <div className="row text-center">
      <div className="col-lg-6 col-sm-6 col-xs-6">
        <img src={ev_tiago_features_1} alt="" />
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right ev-text-heading">
<h1>.EV Front Grill</h1>
<h4>Proudly sport the badge of honor.</h4>
      </div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row text-center">
      <div className="col-lg-6 col-sm-6 col-xs-6">
        <img src={roof_tiago} alt="" />
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right ev-text-heading">
<h1>Contrast Roof</h1>
<h4>Accentuate your style with dual tone colours. </h4>
      </div>
    </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
   

<div id="carouselExampleRide" class="carousel slide" data-bs-ride="true">
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div className="row text-center">
      
      <div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right ev-text-heading">
<h1>Plush Leatherette Upholstery </h1>
<h4>Welcomes you to an immensely premium in-cabin experience. </h4>
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6">
        <img src={ev_tiago_features_2} alt="" />
      </div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row text-center">
      
      <div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right ev-text-heading">
<h1>Flat Bottom Steering Wheel</h1>
<h4>Sporty leatherette steering for better grip.</h4>
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6">
        <img src={tiago_front} alt="" />
      </div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row text-center">
      
      <div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right ev-text-heading">
<h1>Chrome Inner Door Handle </h1>
<h4>Add a dash of luxe to the cabin.</h4>
      </div>
      <div className="col-lg-6 col-sm-6 col-xs-6">
        <img src={tiago_inner} alt="" />
      </div>
    </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
  

    <div className="row text-center mt-5">
      <h1><b>Performance</b></h1>
      <h3>Runs on electricity, laced with adrenaline
</h3>
<div className="col-lg-12 col-sm-6 col-xs-12">
  <img src={ev_tiago_performance} alt="" />
</div>
    </div>
    
 </section>

 <section style={{backgroundColor:'#f4f6f9'}} className='mt-5'>
<div className="row">
  <div className="col-lg-6 col-sm-6 col-xs-6">
    <img src={ev_tiago_1} alt="" />
  </div>
  <div className="col-lg-6 col-sm-6 col-xs-6 tiago_text ">
<h3> 0-60 km/h in 5.7</h3>
<p>Instant acceleration for that electric drive.</p>
<li> Sport Mode </li>
<li> Power & Torque </li>
<li> Fully automatic Drive</li>
  </div>
</div>


<div className="col-lg-12 col-sm-6 col-xs-12 mt-5">
  <img src={ev_tiago_2} alt="" />
</div>
    
<div className="row text-center mt-5">
      <h1><b>Choose your range
</b></h1>
      <h3>Tailored for all your drives

</h3>

    </div>

    <div className="row mt-5">
  
  <div className="col-lg-5 col-sm-6 col-xs-6 tiago_text ">
<h3 className='mx-5'>
<li> Prefer longer daily drives?  </li>
<li>  Short & frequent daily drives? </li>
<li> Multi-Mode Regen</li></h3>
  </div>
  <div className="col-lg-6 col-sm-6 col-xs-6">
    <img src={ev_tiago_3} alt="" />
  </div>
</div>
 </section>
 


  </>
}
