import React from 'react'
import { Link } from 'react-router-dom'

export default function NexonEv_Navigation() {
  return <>
  <nav className="navbar navbar-expand-lg text-dark bg-light">
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse d-flex justify-content-center" id="navbarNavAltMarkup" style={{rowGap:'10px'}}>
      <div className="navbar-nav text-dark">
      <Link className="nav-link active text-dark" to="/nexon/darkred"><b> Dark </b><b className='text-danger'>Red</b></Link>
      <Link className="nav-link active text-dark" to="/brands/kaziranga">Kaziranga</Link>
      <Link className="nav-link active text-dark" to="/brands/nexon">Dark</Link>
        <Link className="nav-link text-dark" to="/ev/nexon">Nexon EV  Prime</Link>
        <Link className="nav-link text-dark" to="/ev/nexonmax">Nexon EV Max </Link>
        <Link className="nav-link text-dark" to="/ev/nexondarkedition">Nexon EV <b className='text-dark'>Dark Edition </b> </Link>
      </div>
    </div>
  </div>
</nav>
  </>
}
