import React from 'react'
import kaziranga_header_1 from '../ev/ev_images/kaziranga/kaziranga_header_1'
import kaziranga_features_1 from '../ev/ev_images/kaziranga/kaziranga_features_1'
import kaziranga_features_2 from '../ev/ev_images/kaziranga/kaziranga_features_2'
import kaziranga_features_3 from '../ev/ev_images/kaziranga/kaziranga_features_3'
import kaziranga_features_4 from '../ev/ev_images/kaziranga/kaziranga_features_4'
import kaziranga_features_5 from '../ev/ev_images/kaziranga/kaziranga_features_5'
import kaziranga_features_6 from '../ev/ev_images/kaziranga/kaziranga_features_6'
import kaziranga_features_7 from '../ev/ev_images/kaziranga/kaziranga_features_7'
import kaziranga_features_8 from '../ev/ev_images/kaziranga/kaziranga_features_8'
import kaziranga_features_9 from '../ev/ev_images/kaziranga/kaziranga_features_9'
import Navigation_ev_nexon from '../../Components/Navigation_ev_nexon'

export default function Kaziranga() {
  return <>
  <Navigation_ev_nexon/>
  <div style={{backgroundColor:'rgb(0, 0, 0)', color:'white'}}>
<div className="container-fluid">
    <div className="col-lg-12 col-sm-6 col-xs-6">
        <img src={kaziranga_header_1} alt="" />
    </div>
</div>

<div className="container">
    <div className="row mt-2">
        <div className="col-lg-12 col-sm-6 col-xs-6">
            <h1 className='text-light'>Tata Nexon Kaziranga Edition
</h1>
<p>Introducing Nexon Kaziranga, taking it to Nex Level with addition of premium features.. Inspired by India’s rich geography and biological diversity while also paying homage to India’s great national parks, this special edition has added premium features with exclusive exterior & interior theme while reinforcing the “Go-Anywhere” DNA of our true SUVs

</p>
        </div>
    </div>
</div>
<hr className='text-light' />

{/* highlights */}
<section>
       <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={kaziranga_features_1} alt=""  className='mx-3'/>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong>GRASSLAND BEIGE WITH PIANO BLACK ROOF

</strong> </h1>
    <p className='hiddenMobile mx-4'>The Nexon Kaziranga comes in a unique Grassland Beige shade, inspired by the majestic grasslands of Kaziranga.
</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong> EXQUISITE TROPICAL WOOD FINISH DASHBOARD

</strong> </h1>
<p className='hiddenMobile mx-4'>The dashboards of the new Nexon Kaziranga sport a unique wooden finish, which gives its interiors a rugged look.


             </p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={kaziranga_features_2} alt="" className='mx-3'/>

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-7 col-sm-3 col-xs-3 ">
    <img src={kaziranga_features_3} alt=""  className='img-style'/>
  </div>
  <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong> EMBOSSED RHINO MASCOT ON HEADREST

</strong> </h1>
    <p className='hiddenMobile mx-4'>Signature embossed Rhino outline on the front headrests perfectly complements premium and elegant interiors of the Nexon Kaziranga.


              </p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong>JET BLACK DIAMOND CUT ALLOYS

</strong> </h1>
<p className='hiddenMobile mx-4'>The Jet Piano Black, R16 Diamond Cut alloys with Black Tinted Lacquer makes the Nexon Kaziranga stand out of the herd in the rugged landscape.


</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={kaziranga_features_4} alt="" />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={kaziranga_features_5} alt="" className='mx-3'/>
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong>AUTO DIMMING IRVM

</strong> </h1>
    <p className='hiddenMobile'> Jungle or city, no matter where the Nexon Kaziranga takes you, the Electro Chromatic Inside Rear-view Mirror detects the brightness of the surroundings and dims the reflecting light accordingly


</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong>AIR PURIFIER


</strong> </h1>
<p className='hiddenMobile mx-4'>Inspired by the pureness of wild natural air, the Nexon Kaziranga is equipped with a climate control system that helps keep the inside Air Quality Index levels in check


</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={kaziranga_features_6} alt=""className='mx-3' />

      </div>
    </div>
  </div>
 </div>

 <div className="row">
 <div className="col-lg-12 col-sm-6 col-xs-6">
<div className="row">
  <div className="col-lg-6 col-sm-3 col-xs-3">
    <img src={kaziranga_features_7} alt=""className='mx-3' />
  </div>
  <div className="col-lg-6 col-sm-3 col-xs-3 p-5">
    <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong>EARTHY BEIGE AND BLACK FINISHED INTERIOR THEME


</strong> </h1>
    <p className='hiddenMobile'>Feel Like Being In The Wilderness Of The Kaziranga


</p>
    </div> 
</div>
 </div>
 </div>

 <div className="row">
  <div className="col-lg-12 col-sm-6 col-xs-6">
    <div className="row">
      <div className="col-lg-5 col-sm-3 col-xs-3 p-5">
      <h1 className='text-style' style={{color:'#e8dcb5'}}> <strong>SATIN BLACK RHINO MASCOT



</strong> </h1>
<p className='hiddenMobile mx-4'>The Nexon Kaziranga is studded with a signature Satin Black Rhino Mascot on fenders that accentuates its wild side.


</p>
      </div>
      <div className="col-lg-7 col-sm-3 col-xs-3">
      <img src={kaziranga_features_8} alt="" className='mx-3'/>

      </div>
    </div>
  </div>
 </div>
 </section>

  </div>
  </>
}
