import React from 'react'
import { useState } from 'react'

import { Link, NavLink } from 'react-router-dom'
import mutha_logo_final from '../pages/images/mutha_logo_final'
import mutha_logo_1 from '../pages/images/mutha_logo_1'
import mutha_logo_full from '../pages/images/mutha_logo_full'
import mutha_logo_new from '../pages/images/header/mutha_logo_new.png'


// import '../App.css'
// import 'bootstrap/dist/css/bootstrap.css';

import brands_punch_1 from '../pages/images/brands_punch_1'

// import {Drawer,Navbar,Menu, Button, Dropdown} from 'react-daisyui'

import tata_motors_logo from '../pages/images/tata_motors_logo.png'

import  tata_punch from '../pages/images/brands/tata_punch'
import  tata_harrier from '../pages/images/brands/tata_harrier'
import  tata_nexon from '../pages/images/brands/tata_nexon'
import  tata_safari from '../pages/images/brands/tata_safari'
import  tata_tiago from '../pages/images/brands/tata_tiago'
import  tata_tigor from '../pages/images/brands/tata_tigor'
import  tata_altroz from '../pages/images/brands/tata_altroz'

import muthaLogo from '../pages/images/mutha.png'
import logo_final_new from '../pages/images/logo_final_new.png'


import { AppBar,MenuItem, Container,Button,Tooltip, Avatar, Toolbar,Box, Typography,Menu, IconButton } from '@mui/material';


import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';

const pages = ['Home', 'About', 'Sales', 'Service','Careers','Contact'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export default function Navigation({args}) {

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);
  
   
    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
      setVisible(!visible)
    }
  
  return <>

{/* <nav class="navbar navbar-expand-lg" style={{backgroundColor:"#002366"}}>
  <div class="container-fluid">
  <a class="navbar-brand" href="#">
      <img src={tata_motors_logo} alt=""  className='' />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <Link class="nav-link nav-item active" to="/">Home</Link>
        <Link class="nav-link" to="/about">About</Link>
        <Link class="nav-link" to="/sales">Sales</Link>
        <Link class="nav-link" to="/service">Service</Link>
        <Link class="nav-link" to="/contact">Contact</Link>
      </div>
    </div>
    <a class="navbar-brand" href="#">
    <span className="logo-text"> Mutha Motors</span> 
      <img src={muthaLogo} alt=""  className='img-logo-header' />
    </a>

  </div>
</nav> */}
    

{/* <nav class="navbar navbar-expand-lg bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-link active" href="#">Home</a>
        <a class="nav-link" href="#">Features</a>
        <a class="nav-link" href="#">Pricing</a>
      </div>
    </div>
  </div>
</nav> */}

<header className=" header_area menu_two menu_three budget_header navbar-fixed">

<div className="main_menu">
  <div className="container">
    <nav className="navbar navbar-expand-lg navbar-fixed">
     
    <Link
        className="navbar-brand"
        to="/"
      >
        <img
       
          src={tata_motors_logo}
          className='navbar-top'
          alt=''
        />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul
          id="menu-primary-menu"
          className="nav navbar-nav menu_nav mr-auto"
        >
          <li
            itemScope="itemscope"
            itemType=""
            id="menu-item-720"
            className="menu-item   menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-22 current_page_item current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children mega_menu dropdown depth-0  menu-item-720 nav-item navbar-fixed"
          >
            <Link
              title="Home"
              target="_blank"
              to="/"
              className="dropdown-toggle text-light nav-link-fade-up"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Home
            </Link>
       
          </li>
          <li
            itemScope="itemscope"
            itemType=""
            id="menu-item-25"
            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children submenu dropdown depth-0 menu-item-25 nav-item nav-link-fade-up navbar-fixed"
          >
            <Link
              title="About US"
              to="/about"
              className="dropdown-toggle text-light "
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              About Us
            </Link>
        
          </li>
          <li
            itemScope="itemscope"
            itemType=""
            id="menu-item-1202"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children submenu dropdown depth-0 menu-item-1202 nav-item navbar-fixed"
          >
            <Link
              title="Services"
              to="/sales"
              className="dropdown-toggle text-light"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Sales
            </Link>
         
          </li>
          <li
            itemScope="itemscope"
            itemType=""
            id="menu-item-1204"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children submenu dropdown depth-0 menu-item-1204 nav-item navbar-fixed"
          >
            <Link
              title="Shop"
              to="/service"
              className="dropdown-toggle text-light"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Services
            </Link>
          
          </li>
          <li
            itemScope="itemscope"
            itemType=""
            id="menu-item-1205"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children submenu dropdown depth-0 menu-item-1205 nav-item navbar-fixed"
          >
            <Link
              title="News"
              to="/careers"
              className="dropdown-toggle text-light"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Careers
            </Link>
         
          </li>
          <li
            itemScope="itemscope"
            itemType=""
            id="menu-item-95"
            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-95 nav-item navbar-fixed"
          >
            <Link
              title="Contact"
              to="/contact"
              className="nav-link text-light"
            >
              Contact
            </Link>
          </li>
         
        </ul>
        {/* <ul>
        <li>
        <Link
              title="Mutha Motors"
              to="/"
              className="navbar-right"
            >
              <img src={muthaLogo} alt="" className='img-logo-header' />
          <span><b>MUTHA  MOTORS</b></span>
            </Link>
        </li>
        
      </ul> */}
      
      </div>
      <Link
        className=""
        to="/"
      >
        <img
       
          src={muthaLogo}
          className='navbar-top img-logo-sideHeader'
          alt=''
/>
      </Link>
<span className='logo-text'><b>MUTHA MOTORS</b> </span>
    </nav>
    
  </div>
</div>
</header> 

{/* <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            // to="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to='/'>
            <img src={tata_motors_logo} alt="" />
            </Link>
           
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
                        <img src={tata_motors_logo} alt="" />

          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar> */}

  </>
}
