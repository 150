import React from 'react'
import altroz_about_1 from './images/about/altroz_about_1'
import altroz_about_2 from './images/about/altroz_about_2'
import tata_punch_1 from './images/header/tata_punch_1'
import altroz_about_3 from './images/about/altroz_about_3'
import altroz_about_4 from './images/about/altroz_about_4'
import tata_tigor_red from './images/about/tata_tigor_red'
import tata_tigor_4 from './images/about/tata_tigor_4'

import map_3d from "./images/services/map_3d.png";


import nexon_blue from './images/nexon_images/nexon_blue'
import altroz_gold from './images/altroz_images/altroz_gold'
import {Link} from 'react-router-dom'

import video_thumb from './images/video_thumb'
import mountain_header from './images/about/mountain_header.webp'
// import mountain_header_1 from './images/about/mountain_header_1.png'
import mountain_header_1 from './images/header/mountain_header_1.png'

import darkRed_header_1 from './images/darkRed_images/darkRed_header_1'
import abouts_banner_1 from './images/about/abouts_banner_1'
import altroz_features_2 from './images/altroz_images/altroz_features_2'
import tigor_blue from './images/tigor_images/tigor_blue'
import safari_sky from './images/safari_images/safari_sky'
import punch_red from './images/punch_images/punch_red'
import muthaLogo from './images/mutha.png'

export default function About() {
  return <>
  
  {/* <div style={{backgroundColor:'rgb(0,0,0)'}}> */}
  <div style={{backgroundColor:'rgb(0,0,0)'}}>
  <div className="container-fluid">
  <img src={mountain_header_1} alt="" className='about-header' />

  </div>

{/* <section>
 <div className="" id='particles-js-none'>
 <div className="container" >
         <div id="carouselExampleCaptions" class="carousel slide carousel-borderRadius" data-bs-ride="carousel">
               <div class="carousel-indicators">
                 <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></button>
                 <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                 <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
               </div>
               <div class="carousel-inner">
                 <div class="carousel-item active">
                  <img src={darkRed_header_1} alt="" />
             
                  
                 </div>
                 <div class="carousel-item">
                  <img src={abouts_banner_1} alt="" />
             
                 
                 </div>
                 <div class="carousel-item">
                 <img src={altroz_features_2} alt="" />
             
                  
                 </div>
               </div>
               <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                 <span class="carousel-control-prev-icon" ></span>
                 <span class="visually-hidden">Previous</span>
               </button>
               <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                 <span class="carousel-control-next-icon" ></span>
                 <span class="visually-hidden">Next</span>
               </button>
             </div>
 </div>
 </div>
</section> */}


 
  {/* <div className="centered">
   
    <h1> <b>About Us</b></h1>

  </div> */}


{/* <section className="intro">
 <div className="container">
   <div className="row">
     <div className="col-lg-5 wow" data-splitting="">
       <h3 className="section-title">
         HOW WE MAKE <br />
         CUSTOMER-CENTRIC ORGANISATION
       </h3>
       <a href="mailto:crmservice@muthamotors.com" className="text-decoration-none text-light">crmservice@muthamotors.com</a>
     </div>
     <div className="col-lg-7 wow" data-splitting="">
       <p>
       We feel that being right away about our prices, services, and processes
        helps to establish assurance with our clients. 
       </p>
       
       
        <b className='number-design' style={{color:"#FFD700"}}>25</b>
       <h4>
         YEARS OF
         <br />
        LEGACY
       </h4>
     </div>
   </div>
 </div>
</section> */}
</div>


<section className="intro white-bg-img" style={{color:'rgb(0, 35, 102)'}}>
 <div className="container">
   <div className="row">
     <div className="col-12 wow fadeIn" style={{visibility:'visible', animationName:'fadeIn'}}>
       {/* <h6 style={{color:'#BBC2CC' }}>ABOUT US</h6> */}
       <h2 data-text="Mutha" >
         ABOUT MUTHA MOTORS
       </h2>
     </div>
     {/* end col-12 */}
     <div className="col-lg-5 wow fadeIn">
       <h4>We invite you to visit to any of our Maharashtra locations and experience the Mutha Motors prestige for yourself.
</h4>
<div className="company-information-inner">
                <img src={muthaLogo} alt="" />
{/* <h5 className='text-danger text-jeader-manage text-center font-style'>We Believe in Humanity</h5> */}
            
            </div>

     </div>
     {/* end col-5 */}
     <div className="col-lg-7 wow fadeIn" data-wow-delay="0.10s">
       <p>
                      {/* end lines */}
                      Mutha Motors was originally established by our MD, Dr. Sandeep Mutha, with an objective to provide our customers with a hassle-free and great sales experience. Since its inception in 2016, we have seen tremendous growth across our sites, largely because of hundreds of Our Happy Customers.

       </p>
       <p>
       In a nutshell Mutha Motors is more than a dealership; we are a customer-centric organisation dedicated to providing exceptional service and support to our customers.  Transparency, honesty, and integrity are basic values that pervade everything we do.
       We feel that being right away about our prices, services, and processes helps to establish assurance with our clients. We think that by prioritising the needs of our customers, we can make a significant and long-lasting impact in the communities we serve.

       </p>
       <div className="custom-link wow fadeIn">
         
         {/* <a href="#">
           <div className="lines">
             
             <span /> <span />
           </div>
           <b>READ TO LEARN</b>
         </a> */}
       </div>
       {/* end custom-link */}
     </div>
     {/* end col-7 */}
   </div>
   {/* end row */}
 </div>
 {/* end container */}
</section>
{/* end intro */}

{/* </div> */}

<div style={{backgroundColor: 'rgb(0, 35, 102)'}}>
<section className='p-5'>
<div className="container">
<h1 className='text-center' style={{color:"#A67B00 "}}><b>IMAGE GALLERY</b></h1>

<div className="row mt-5">
  <div className="col-lg-6">
    <img src="https://ev.tatamotors.com/wp-content/themes/tata-ev/images/evolve/evolve-technology-desk.png" alt="" className='img-border'/>
  </div>
  <div className="col-lg-6">
<img src="https://ev.tatamotors.com/Sierraev/images/image-gallery/5.jpg" alt="" />
<br />
<img src="https://nexonev.tatamotors.com/wp-content/themes/tata-nexon/images/dark-edition-new/exterior-max.jpg" alt="" className='img-border'/>

  </div>
</div>
  <div className="row mt-2">
<div className="col-lg-4">
  <img src="https://cars.tatamotors.com/images/punch/punch-suv-home-thumbnail.png" alt=""className='img-border' />
</div>
<div className="col-lg-4">
  <img src="https://cars.tatamotors.com/images/dark/home-nexon-thumbnail.jpg" alt=""className='img-border' />
</div>
<div className="col-lg-4">
  <img src="https://cars.tatamotors.com/images/dark/home-nexon-thumbnail.jpg" alt="" className='img-border'/>
</div>
  </div>
</div>


</section>
</div>
<section>
{/* <div className="container"> */}
<iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d35699.194550213804!2d75.32099647636049!3d19.86466579940744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d19.8761653!2d75.3433139!4m5!1s0x3bdba3974a4356c3%3A0xfcfba4eb70fc7cb2!2smuthamotors%20aurangabad!3m2!1d19.8484111!2d75.3463192!5e0!3m2!1sen!2sin!4v1683894069590!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0"}}></iframe>

{/* </div> */}
</section>


  {/* area we find us */}
  <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-7120e8b elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-id="7120e8b"
                  data-element_type="section"
                  data-settings='{"stretch_section":"section-stretched"}'
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c89f18f"
                        data-id="c89f18f"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-655abbb elementor-widget elementor-widget-karde_findus_area"
                              data-id="655abbb"
                              data-element_type="widget"
                              data-widget_type="karde_findus_area.default"
                            >
                              <div className="elementor-widget-container">
                                <section className="find_us_area p_100">
                                  <div className="container">
                                    <div className="row find_us_inner">
                                      <div className="col-lg-6">
                                        <div className="find_us_text">
                                          <h2>
                                            Areas You Can <span>Find Us</span>
                                          </h2>
                                          <p>
                                            Mutha Motors are available
                                            <br /> in below regions also
                                          </p>
                                          <div className="list_finding">
                                            <ul className="nav flex-column">
                                              <li className="new_york_city">
                                                <a href="javascript:void(0)">
                                                  Aurangabad
                                                </a>
                                              </li>

                                              <li className="san_diego">
                                                <a href="javascript:void(0)">
                                                  Buldhana
                                                </a>
                                              </li>

                                              <li className="los_angeles">
                                                <a href="javascript:void(0)">
                                                  Jalna
                                                </a>
                                              </li>

                                              <li className="boston">
                                                <a href="javascript:void(0)">
                                                  Khamgaon
                                                </a>
                                              </li>
                                              <li className="chicago">
                                                <a href="javascript:void(0)">
                                                  Malkapur
                                                </a>
                                              </li>
                                            </ul>
                                            {/* <ul className="nav flex-column">
                                              <li className="chicago">
                                                <a href="javascript:void(0)">
                                                  Chicago
                                                </a>
                                              </li>

                                              <li className="san_francisco">
                                                <a href="javascript:void(0)">
                                                  San Francisco
                                                </a>
                                              </li>

                                              <li className="baltimore">
                                                <a href="javascript:void(0)">
                                                  Baltimore
                                                </a>
                                              </li>

                                              <li className="houston">
                                                <a href="javascript:void(0)">
                                                  Houston
                                                </a>
                                              </li>

                                              <li className="phoenix">
                                                <a href="javascript:void(0)">
                                                  Phoenix
                                                </a>
                                              </li>

                                              <li className="philadelphia">
                                                <a href="javascript:void(0)">
                                                  Philadelphia
                                                </a>
                                              </li>
                                            </ul> */}
                                            {/* <ul className="nav flex-column">
                                              <li className="detroit">
                                                <a href="javascript:void(0)">
                                                  Detroit
                                                </a>
                                              </li>

                                              <li className="miami">
                                                <a href="javascript:void(0)">
                                                  Miami
                                                </a>
                                              </li>

                                              <li className="seattle">
                                                <a href="javascript:void(0)">
                                                  Seattle
                                                </a>
                                              </li>
                                            </ul> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        <div className="find_us_img">
                                          <img
                                            className="img-fluid wow animated fadeIn"
                                            data-wow-delay="0.2s"
                                            // src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/find-map-2.png"
                                            // src={map1}
                                            src={map_3d}
                                            // src={safari_slider_car}
                                            alt="Alt"
                                          />
                                          <div className="marker_icon">
                                            <ul className="nav elementor-repeater-item-c59fbd0">
                                              <li
                                                style={{
                                                  top: "35%",
                                                  left: "35%",
                                                }}
                                                className="new_york_city"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir/19.8748734,75.3761947/R8XW%2B9G8+MUTHA+MOTORS,+Gopi+Nath+Pura,+Satara+Deolai+Parisar,+Aurangabad,+Maharashtra+431005/@19.8620045,75.3434205,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bdba3974a4356c3:0xfcfba4eb70fc7cb2!2m2!1d75.3463192!2d19.8484111"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Chhatrapati Sambhajinagar"
                                                  data-original-title="Chhatrapati Sambhajinagar"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Chhatrapati Sambhajinagar"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "31%",
                                                  left: "40%",
                                                }}
                                                className="atlanta"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+buldhana+Khetan+Square+Shegaon+Buldhana+-+443001.+Landmark.+Opposite+Shegaon+Urban+Bank./data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bda1e077e700edb:0x14e04802a6c02141?sa=X&ved=2ahUKEwjBsoDnytP9AhUwIbcAHTa3DHEQ9Rd6BAhREAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Buldhana"
                                                  data-original-title="Buldhana"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Buldhana"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "22%",
                                                  left: "40%",
                                                }}
                                                className="san_diego"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Khamgaon"
                                                  data-original-title="Khamgaon"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Khamgaon"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "38%",
                                                  // right: "30%",
                                                  // bottom: "30%",
                                                  left: "40%",
                                                }}
                                                className="los_angeles"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir/19.874865,75.377049/TATA+MOTORS+LIMITED+MUTHA+MOTORS+JALNA,+Aditya+nagar,+Chandanzira,+Jalna,+Maharashtra+431203/@19.8870144,75.1443048,9.5z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bda51dd332f304b:0xa7ae2289377481a1!2m2!1d75.8563004!2d19.8589784"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Jalna"
                                                  data-original-title="Jalna"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Jalna"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "25%",
                                                  left: "55%",
                                                }}
                                                className="boston"
                                              >
                                                <a
                                                  href="https://www.google.com/maps/dir//mutha+motors+KHAMGAON+Nandura+Rd,+Kishan+Nagar,+Sutala+Kd,+Khamgaon,+Maharashtra+444303/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bd7513af5f97195:0xfaa1bce79a6e0678?sa=X&ved=2ahUKEwi9vfG7y9P9AhVT3XMBHXvVArUQ9Rd6BAg2EAQ"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Malkapur"
                                                  data-original-title="Malkapur"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Malkapur"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              {/* <li
                                                style={{
                                                  top: "49%",
                                                  left: "33%",
                                                }}
                                                className="st._louis"
                                              >
                                                <a
                                                  href="#"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="St. Louis"
                                                  data-original-title="St. Louis"
                                                >
                                                  /
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="St. Louis"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "43%",
                                                  left: "49%",
                                                }}
                                                className="chicago"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Chicago"
                                                  data-original-title="Chicago"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Chicago"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "14%",
                                                  left: "54%",
                                                }}
                                                className="san_francisco"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="San Francisco"
                                                  data-original-title="San Francisco"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="San Francisco"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "60%",
                                                  left: "60%",
                                                }}
                                                className="baltimore"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Baltimore"
                                                  data-original-title="Baltimore"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Baltimore"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "44%",
                                                  left: "70%",
                                                }}
                                                className="houston"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Houston"
                                                  data-original-title="Houston"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Houston"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "auto",
                                                  bottom: "3%",
                                                  left: "55%",
                                                }}
                                                className="phoenix"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Phoenix"
                                                  data-original-title="Phoenix"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Phoenix"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "48%",
                                                  right: "23%",
                                                  left: "auto",
                                                }}
                                                className="philadelphia"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Philadelphia"
                                                  data-original-title="Philadelphia"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Philadelphia"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "auto",
                                                  right: "13%",
                                                  bottom: "24%",
                                                  left: "auto",
                                                }}
                                                className="detroit"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Detroit"
                                                  data-original-title="Detroit"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Detroit"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "39%",
                                                  right: "4%",
                                                  left: "auto",
                                                }}
                                                className="miami"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Miami"
                                                  data-original-title="Miami"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Miami"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li>
                                              <li
                                                style={{
                                                  top: "13%",
                                                  right: "4%",
                                                  left: "auto",
                                                }}
                                                className="seattle"
                                              >
                                                <a
                                                  href=""
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title="Seattle"
                                                  data-original-title="Seattle"
                                                >
                                                  <img
                                                    src="https://smartdatawp.com/motodeal/bus/wp-content/uploads/sites/4/2020/03/map-marker-red.png"
                                                    title="Seattle"
                                                    alt="Alt"
                                                  />
                                                </a>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
  </>
}
