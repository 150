import React, { useState } from 'react'
import tigor_ev_header_1 from './ev_images/tigor_ev/tigor_ev_header_1'
import tigor_video_1 from './ev_video/tigor_video_1'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Modal, Button } from 'react-bootstrap';

import { Card } from 'react-bootstrap';
import Tigor_ev_Navigation from '../../Components/Tigor_ev_Navigation';

export default function Tigor_ev() {

  
  const cards = [
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-6.jpg',
      title: 'Rain sensing wipers',
      description: 'Intelligent wipers that adjust to varied rain intensity, to give you a clear view when you need it.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-7.jpg',
      title: 'i-TPMS',
      description: 'Keep your tyre pressures in check.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-8.jpg',
      title: 'Puncture repair kit',
      description: 'The new normal of managing tyre punctures. comes standard with all variants.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-5.jpg',
      title: 'Auto headlamps',
      description: 'Brightens up the darkest roads, without the touch of a button.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-4.jpg',
      title: 'Cruise control',
      description: 'Don’t just drive, cruise! experience an easy, effortless & efficient drive. .',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-3.jpg',
      title: 'Multi-mode regen',
      description: 'Maximize range & efficiency with 4 levels of regen settings in each drive mode.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-2.jpg',
      title: 'ZConnect',
      description: 'Enjoy the convenience of connected car features with the ZConnect App thats now standard on all variants.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/features/NewFeatures-1.jpg',
      title: 'Smartwatch connectivity',
      description: 'All the information you need of your Tigor.ev is now on your wrist. Just pair and forget!',
     
    },
  ];

  const cards2 = [
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/3.3kwh.png',
      title: 'AC home wall box charger',
      description: 'Charge efficiently from a 15A socket. SOC 10% to 100% in 9.4** hours.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/fast-charging.png',
      title: 'DC fast charger',
      description: 'Charge quickly with 10% to 80% in 59** min.',
    },
    {
      image: 'https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/portable-charging.png',
      title: '15A portable charger',
      description: ' Charge anywhere from a 15A socket. SOC 10% to 100% in 9.4** hours. ',
    }
  ];
  return <>
{/* <Navigation/> */}
<Tigor_ev_Navigation/>

 <div className="container-fluid bg-dark">
  <img src={tigor_ev_header_1} alt="" />
   
  </div>
 <section className='price-sec'>
 <div className="col-lg-9 col-sm-12 col-xs-12 myContainer">
    <div className="performance-cotent">
  <div className="row">
    <div className="col-lg-12">
      <div className="prfmnce-cnt">
        <h2 className='prfmnce-title'>Range</h2>
        <span>315 <sup>#</sup></span>
        <small>Km</small>
      </div>
    </div>
    </div>
    <div class="prfmnce-cnt">
                <h6 class="prfmnce-title">safety</h6>
                <span>4 Star<sup>^</sup></span>
                <small>GnCAP rated</small>
            </div>

            <div class="prfmnce-cnt">
                <h2 class="prfmnce-title">Fast-Charge</h2>
                <span>59<sup>**</sup></span>
                <small>min</small>
               
            </div>

    </div>
    </div>
 </section>

<div className="container-fluid bg-dark">
  <div className="row">
  <video src={tigor_video_1} controls autoPlay></video>
</div>
</div>

<section>
  <div className="container mt-5">
    <h1 className="text-center"><b>Closer Look</b></h1>
    <h5 className="text-center">Let's get down to the details
</h5>
    

    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div className="row">
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <span>EXTERIOR</span>
  <h1> All-new magnetic red colour with contrast black roof
                                </h1>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/exterior/exterior-1.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
     
    </div>
    <div class="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <span><b>EXTERIOR</b> </span>
  <h1> 
                                    A bold EV front grille with EV Blue Humanity Line
                                
                                </h1>
                                <p>A signature statement of style and elegance.</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/exterior/exterior-3.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
<span><b>EXTERIOR</b> </span>
  <h1> 
                                    Projector headlamps
                                
                                </h1>
                                <p>Confidently navigate the road ahead with better visibility. .</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/exterior/exterior-3.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
<span><b>EXTERIOR</b> </span>
  <h1> Daytime running lamps (DRLs)
                                </h1>
                                <p>Make your presence known, be it night or day. .</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/exterior/exterior-4.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
<span><b>EXTERIOR</b> </span>
  <h1>
                                    Signature LED taillamps with LED light guide
                                
                                </h1>
                                <p>Crystal inspired LED taillamps for unique night signature. </p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/exterior/exterior-5.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
<span><b>EXTERIOR</b> </span>
  <h1>
                                    Hyperstyle wheels
                                
                                </h1>
                                <p>Stand out from the crowd with R14 hyperstyle wheels</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/exterior/exterior-6.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>


<div className="row">
{/* 2nd carousel */}
 <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div className="row">

<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/interior/interior-1.jpg" class="d-block w-100" alt="..."/>
</div>

<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right " style={{backgroundColor:'#F4F9F9'}}>
  <span className='mt-3'>INTERIOR</span>
  <h1> 
                                    Two-tone dashboard
                                
                                </h1>
                                <p>An elegant blend of black and light grey interior shades that adds richness to your EV experience.</p>

</div>
    </div>
     
    </div>
    <div class="carousel-item">
    <div className="row" >
    <div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/interior/interior-4.jpg" class="d-block w-100" alt="..."/>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <span>INTERIOR</span>
  <h1> 
                                    Premium interiors with plush leatherette seats
                                
                                </h1>
                                <p>Welcomes you to an immensely premium in-cabin experience.</p>
</div>

    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >

<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/interior/interior-3.jpg" class="d-block w-100" alt="..."/>
</div>

<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <span><b>INTERIOR</b> </span>
  <h1> 
                                    Flat bottom steering wheel
                                
                                </h1>
                                <p>Sporty leatherette steering wheel for better grip.  </p>
</div>
    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >
    <div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/interior/interior-8.jpg" class="d-block w-100" alt="..."/>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
<span><b>INTERIOR</b> </span>
  <h1>
                                    Chrome inner door handle
                                
                                </h1>
                                <p>Add a dash of luxe to the cabin</p>
</div>

    </div>
    </div>
    <div class="carousel-item">
    <div className="row" >
    <div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/interior/interior-6.jpg" class="d-block w-100" alt="..."/>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
<span><b>INTERIOR</b> </span>
  <h1>
                          
  Rear armrest
                                
                                
                                </h1>
                                <p>A sprinkle of comfort to an already homely cabin.  </p>
</div>

    </div>
    </div>
  
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</div>

  </div>
  </section>

  <section>
    <div className="container-fluid mt-5" style={{backgroundColor:'#F4F9F9'}}>
      <h1 className='text-center'><b>Performance</b></h1>
      <h5 className='text-center'>Runs on electricity, laced with adrenaline
</h5>
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/performance/Performance-Main-banner.png" alt="" />
    </div>
  </section>

<section className='img-container'>
  <div className="text-center mt-5">
<h1><b>A new electric experience
</b></h1>
<h5>Tigor.ev now comes with a host of new features
</h5></div>
<div className="container ">
  <div className="row">
    <div className="col-lg-12 col-sm-6 col-xs-6">
    <OwlCarousel
  className="owl-theme mt-5"
  height={100}
  items={3}
  loop
  margin={20}
  nav
  dots={false}
  autoplay
>
  {cards.map((card, index) => (
    <Card key={index} className="">
      <Card.Img variant="top" src={card.image} />
      <Card.Body>
        <Card.Title>{card.title}</Card.Title>
        <Card.Text>{card.description}</Card.Text>
      </Card.Body>
    </Card>
  ))}
</OwlCarousel>
    </div>
  </div>
</div>
</section>

<section className='mt-5'>
  <div className="text-center">
    <h1><b>Range</b></h1>
    <h5>Perfect for all your drives
</h5>
<div className="container-fluid" style={{backgroundColor:'#F4F9F9'}}>
<div className="row">
  <h4 className='mt-3'>Driven by electric power, the Tigor.ev promises an exhilarating drive with an enhanced ARAI certified range of 315# km.
</h4>
  <div className="col-lg-10 col-sm-6 col-xs-6 offset-1">
    <img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/range.png" alt="" />
  </div>
</div>
</div>
  </div>
</section>


<section className='mt-5 img-container2'>
  <div className="text-center">
    <h1><b>Charging</b></h1>
    <h4>Home or away, plug-in your way

</h4>
</div>
<div className="container ">
  <div className="row">
    <div className="col-lg-12 col-sm-6 col-xs-6">
    <OwlCarousel
  className="owl-theme mt-5"
  items={3}
  loop
  margin={20}
  nav
  dots={false}
  autoplay
>
  {cards2.map((card, index) => (
    <Card key={index} className="">
      <Card.Img variant="top" src={card.image} />
      <Card.Body>
        <Card.Title>{card.title}</Card.Title>
        <Card.Text>{card.description}</Card.Text>
      </Card.Body>
    </Card>
  ))}
</OwlCarousel>
    </div>
  </div>
</div>
</section>


{/* <section className='mt-5 '>
  <div className="text-center">
    <h1><b>Technology</b></h1>
    <h4>Stay connected to your car from anywhere
</h4>
</div>
<div className="container-fluid img-container3">
  <div className="col-lg-5">
<div className="text-white mx-5 mt-5">
  <h5>CONNECT SEAMLESSLY
</h5>
<h3>Stay in touch
</h3>
<span>Out of sight, but never out of mind. Stay in touch with your Tigor.ev with smart connected features through the ZConnect app. 

</span>
</div>
</div>
<div className="col-lg-5">

</div>
</div>
</section> */}

<section>
  <div className="text-center mt-5">
    <h1><b>Infotainment</b></h1>
    <h5>Evolve from boring drives to happening vibes
</h5>
  </div>
  <div className="container-fluid">
    <img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/entertainment/infotainment-banner.jpg" alt="" />
  </div>
</section>

<div className="container">
<div className="row">
<div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="3" aria-label="Slide 4"></button>
  </div>
  <div className="carousel-inner">  
    <div className="carousel-item active">
    <div className="row">
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <h1> Infotainment system by HARMAN
                                </h1>
                                <p>Take the party into the cabin with an Infotainment System by HARMAN.</p>

</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/entertainment/infotaintment-1.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
     
    </div>
    <div className="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <h1> 
  8-speaker system
                                
                                </h1>
                                <p>Sounds like a fun driEV!.</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/entertainment/infotaintment-2.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    <div className="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <h1> 
  Android Auto <sup>TM</sup> & Apple CarPlay<sup>TM</sup> connectivity 
                                
                                </h1>
                                <p>Easily connect your smartphone & use its features without taking your eyes off the road. .</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/entertainment/infotaintment-3.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    <div className="carousel-item">
    <div className="row" >
<div className="col-lg-6 col-sm-6 col-xs-6 zigzag-right" style={{backgroundColor:'#F4F9F9'}}>
  <span>EXTERIOR</span>
  <h1> Steering mounted controls
                                </h1>
                                <p>Answer phone calls, control music & do a lot more without taking your hands off the steering wheel..</p>
</div>
<div className="col-lg-6 col-sm-6 col-xs-6">
<img src="https://tigorev.tatamotors.com/wp-content/themes/tata-nexon/images/entertainment/infotaintment-4.jpg" class="d-block w-100" alt="..."/>
</div>
    </div>
    </div>
    
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
</div>
  </>
}
